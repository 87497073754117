<template>
  <div>
    <div class="side-by-side-flex">
      <h4 style="text-align: left">{{ subjectType }} {{ tenantNumber }}</h4>
      <button
        v-if="tenantNumber !== 1"
        class="btn-sm remove-guarantor"
        @click="removeGuarantor(tenantNumber - 1)"
      >
        Remove
      </button>
    </div>
    <TextBoxElement
      :focusItem="true"
      :name="`${tenant.id}.firstName`"
      v-model="tenant.firstName"
      label="First Name"
      :keydown="$emit('change', v.firstName)"
    ></TextBoxElement>
    <TextBoxElement
      :focusItem="true"
      :name="`${tenant.id}.lastName`"
      v-model="tenant.lastName"
      label="Last Name"
      :keydown="$emit('change', v.lastName)"
    ></TextBoxElement>
    <p v-if="!v.firstName">
      {{ subjectType }} {{ tenantNumber }} First Name is Required
    </p>

    <div class="mobile-wrapper">
      <div class="control-area">
        <vue-tel-input
          name="mobile"
          id="mobile"
          v-model="tenant.mobile"
          @validate="$emit('change', v.mobile)"
        ></vue-tel-input>
      </div>
    </div>

    <TextBoxElement
      :focusItem="true"
      :name="`${tenant.id}.email`"
      v-model="tenant.email"
      label="Email"
      :keydown="$emit('change', v.email)"
    ></TextBoxElement>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import TextBoxElement from "../../generalUI/TextBoxElement.vue";
export default {
  mixins: [formMixin],
  components: { TextBoxElement },
  props: {
    removeGuarantor: {
      type: Function,
    },
    tenant: {
      type: Object,
      required: true,
    },
    tenantNumber: {
      type: Number,
    },
    subjectType: {
      type: String,
    },
    v: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="less" scoped>
h4 {
  color: var(--mainHighlight) !important;
}

.field-label {
  text-align: left;
  margin: 0;
  padding-bottom: 10px;
}

.tenant-row input {
  width: 100%;
  margin: 0;
  height: 63px;
}

.tenant-row {
  margin: 0 auto;
}

#mobile {
  margin: 0;
}

.side-by-side {
  display: inline-flex;
  flex-flow: row wrap;
  color: black;
  width: 100%;
  justify-content: space-between;
  margin: 0;
  gap: 10px;

  div {
    margin: 0;
    flex: 1 1 200px;
    margin: 0;
  }
}

.container {
  padding: 20px;
  max-width: 580px;
}

#mobile {
  margin: 0;
  padding: 15px;
}

.control-area {
  margin: 0 20px;
  margin-bottom: 0;
}

.remove-guarantor {
  background: #ed1c24;
  border-color: #ed1c24;
  padding: 10px;
  color: white;
  height: 40px;
  width: 100px;
}
</style>
