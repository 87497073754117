type FlowIds =
  | "additionalDetails"
  | "financial"
  | "identity"
  | "residential"
  | "Landlord"
  | "Employment"
  | "Accountant"

type flowStageId =
  | "proofOfIdentity"
  | "tenantDetails"
  | "selfie"
  | "addressDetails"
  | "forwardingAddress"
  | "incomeDetails"
  | "adverseCredit"
  | "Tenant Information"
  | "landlordDetails"
  | "employerReference"
  | "termsAndConditions"
  | "accountantReference"

const displayNames: Record<FlowIds, string> = {
  additionalDetails: "Additional Details",
  financial: "Financial",
  identity: "Identity",
  residential: "Residential",
  Landlord: "Landlord Reference",
  Accountant: "Accountant Reference",
  Employment: "Employer Reference",
}

const flowIcons: Record<FlowIds, string> = {
  additionalDetails: "fa-info-circle",
  financial: "fa-credit-card",
  identity: "fa-address-card",
  residential: "fa-building",
  Landlord: "fa-address-card",
  Employment: "fa-building",
  Accountant: "fa-address-card",
}

const stageDisplayNames: Record<flowStageId, string> = {
  proofOfIdentity: "Proof of Identity",
  tenantDetails: "Tenant Details",
  selfie: "Selfie",
  addressDetails: "Address Details",
  forwardingAddress: "Forwarding Address",
  incomeDetails: "Income Details",
  adverseCredit: "Adverse Credit",
  "Tenant Information": "Tenant Information",
  landlordDetails: "Landlord Details",
  employerReference: "Employer Details",
  termsAndConditions: "Terms and Conditions",
  accountantReference: "Accountant Details",
}

export const mapFlowIdToDisplayName = (flowId: FlowIds) =>
  displayNames[flowId] !== undefined ? displayNames[flowId] : flowId

export const mapFlowIdToIcon = (flowId: FlowIds) =>
  flowIcons[flowId] !== undefined ? flowIcons[flowId] : ""

export const mapFlowStageToDisplayName = (stageId: flowStageId) =>
  stageDisplayNames[stageId] !== undefined
    ? stageDisplayNames[stageId]
    : stageId
