<template>
  <div>
    <styled-summary-section :isMobile="isMobile">
      <b>Source of Income:</b>
      Pension
    </styled-summary-section>
    <styled-summary-section v-if="income.pensionProvider" :isMobile="isMobile">
      <b>Pension Provider</b>
      {{ nationalInsuranceNumber }}
    </styled-summary-section>
    <styled-summary-section v-if="income.incomeProperties" :isMobile="isMobile">
      <b>Payment Frequency:</b>
      {{ income.incomeProperties.frequency }}
    </styled-summary-section>
    <styled-summary-section :isMobile="isMobile">
      <b>Amount:</b>
      {{ formatIncomeValue(income.incomeProperties.value, income.incomeProperties.currency.symbol) }}
    </styled-summary-section>
    <styled-summary-section v-if="nationalInsuranceNumber" :isMobile="isMobile">
      <b>National Insurance Number</b>
      {{ nationalInsuranceNumber }}
    </styled-summary-section>
  </div>
</template>

<script>
import { margins } from "@/common/spacing"
import styled from "vue-styled-components"
import { formatIncomeValue } from '@/common/utils'

const StyledSummarySection = styled("div", { isMobile: Boolean })`
  display: flex;
  align-items: ${(props) => (props.isMobile ? "flex-start" : "")};
  flex-direction: column;
  line-height: 16px;
  margin-top: ${margins.l};
`

export default {
  components: {
    StyledSummarySection,
  },
  props: {
    income: {
      type: Object,
      required: true,
    },
    nationalInsuranceNumber: String,
  },
  methods: {
    formatIncomeValue,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 800
    },
  },
}
</script>
