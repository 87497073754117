<template>
  <div>
    <div class="select-container" v-if="model.source">
      <select
        v-model="currency"
        id="currency-selector"
        name="currency"
        @change="validate"
      >
        <option selected disabled value="">
          Currency
        </option>
        <option
          v-bind:key="`common-${index}`"
          :value="c"
          v-for="(c, index) in common"
          >{{ c.symbol }} - {{ c.name }}</option
        >
        <option selected disabled>
          -
        </option>
        <option
          v-bind:key="`uncommon-${index}`"
          :value="c"
          v-for="(c, index) in uncommon"
          >{{ c.symbol }} - {{ c.name }}</option
        >
      </select>
    </div>
    <div>
      <TextBoxElement
        v-model="value"
        id="value"
        name="value"
        :label="model.valueLabel ? model.valueLabel : 'Amount'"
        numeric
        :errorMessage="
          `Please enter the ${model.valueLabel ? model.valueLabel : 'amount'}.`
        "
        required
      ></TextBoxElement>
    </div>
    <div v-if="model.incomeType !== 'Savings'">
      <div class="select-container" v-if="model.frequency">
        <select v-model="frequency" id="frequency-selector" name="frequency">
          <option value="" disabled selected>
            {{ model.frequencyLabel ? model.frequencyLabel : "Frequency" }}
          </option>
          <option
            v-bind:key="index"
            :value="f"
            v-for="(f, index) in model.frequency"
            >{{ f }}</option
          >
        </select>
      </div>
      <TextBoxElement
        v-else
        v-model="frequency"
        id="frequency"
        name="frequency"
        :label="model.frequencyLabel ? model.frequencyLabel : 'Frequency'"
        numeric
        :errorMessage="
          `Please enter the ${
            model.frequencyLabel ? model.frequencyLabel : 'frequency'
          }.`
        "
        required
      ></TextBoxElement>
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin"
import TextBoxElement from "@/components/generalUI/TextBoxElement.vue"
import { required } from "vuelidate/lib/validators"

export default {
  mixins: [formMixin],
  components: {
    TextBoxElement,
  },
  props: {
    model: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      value: "",
      currency: "",
      frequency: "",
      COMMON_CURRENCIES: this.model.common || ["GBP"],
    }
  },
  watch: {
    value() {
      this.touch(this.$v.value)
    },
    frequency() {
      this.touch(this.$v.frequency)
    },
  },
  validations: {
    value: { required },
    currency: { required },
    frequency: {
      required: function(value) {
        return (
          (this.model && this.model.incomeType === "Savings") || required(value)
        )
      },
    },
  },
  mounted() {
    if (!this.model.source) {
      this.currency = {
        code: "GBP",
        name: "British Pound Sterling",
        symbol: "£",
        decimalDigits: 0,
        rounding: 0,
      }
    }
  },
  computed: {
    common() {
      return this.model.source.filter((c) =>
        this.COMMON_CURRENCIES.includes(c.code)
      )
    },
    uncommon() {
      return this.model.source.filter(
        (c) => !this.COMMON_CURRENCIES.includes(c.code)
      )
    },
  },
}
</script>

<style lang="less" scoped>
.select-container {
  margin: 5px 20px;
}
select {
  padding: 1rem;
  border-radius: 15px;
  border: none;
  width: 100%;
  color: #55616b;
  border-style: none;
  grid-row: 1;
  grid-column: 1 / span 3;
  padding: 20px;
  border-radius: 5px;
  background-color: #ffffff;
  border-style: solid;
  border-color: rgba(188, 190, 192, 0.5);
  border-width: 1px;
}

#frequencySelector > option,
#frequencySelector,
select-container {
  text-transform: capitalize;
}
</style>
