
const styled = require("vue-styled-components")
import { Component, Prop, Mixins } from "vue-property-decorator"
import AssetMixin from "../../mixins/AssetMixin"
const { SimpleSVG } = require("vue-simple-svg")

const MenuItemWrapper = styled.default.div`
  .prog-bar {
    background: ${(props: any) =>
      props.theme! == undefined && props.theme.default !== undefined
        ? props.theme.default.mainColor
        : "var(--mainHighlight)"} !important;
  }
`

@Component({ components: { MenuItemWrapper, SimpleSVG } })
export default class ProgressMenuItem extends Mixins(AssetMixin) {
  public botIconsUrl: string = process.env.VUE_APP_ASSETS || ""

  @Prop({ type: String })
  public iconName!: string

  @Prop({ type: Boolean })
  public activeTab!: boolean

  @Prop({ type: Number })
  public percent!: number

  public get getPercentage(): string {
    return `${Math.floor(this.percent)}%`
  }
}
