<template>
  <div class="controls-container" ref="modalForm">
    <input type="hidden" value="false" name="isAccepted" ref="isAccepted" />
    <Wrapper>
      <button
        id="accept"
        type="button"
        class="btn btn-primary"
        @click="accept"
        v-if="model.isCancellable"
      >
        Done
      </button>
      <button
        id="accept"
        type="button"
        class="btn btn-primary"
        @click="accept"
        v-else
      >
        Accept
      </button>
    </Wrapper>
  </div>
</template>
<script>
import { track } from '@/common/utils';
import { mapActions } from "vuex";

const styled = require("vue-styled-components");

const Wrapper = styled.default("div")`
  button {
    background: ${(props) => props.theme.default.mainColor} !important;
    border:none;
  }
`;

export default {
  props: {
    submit: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  components: {
    Wrapper: Wrapper,
  },
  data() {
    return {
      isAccepted: false,
    };
  },
  methods: {
    ...mapActions({
      sendMessage: "webchat/sendMessage",
    }),
    accept() {
      this.$refs.modalForm.isAccepted = true;
      // add messaging call from webchat
      if (this.model.isCancellable === false) {
        track("Click - Accepted mandatory terms", {})
        this.sendMessage({
          message: "I have read and accept the declaration  📝",
        });
        this.submit({ eventlessSubmit: true, isAccepted: "true" });
      } else {
        track("Click - Accepted optional terms", {})
        this.sendMessage({
          message: "I have read the referencing declaration  📝",
        });
        this.submit();
      }
    },
    reject() {
      track("Click - Declined optional terms", {})
      this.$refs.modalForm.isAccepted = false;
      this.submit();
    },
  },
};
</script>
<style scoped>
#accept,
#reject,
.controls-container button {
  margin: 0 0.5rem !important;
}
.controls-container {
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: center;
}
</style>
