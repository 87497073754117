interface IFileOption {
  icon: string
  accept: string
  required: boolean
  title: string
  description: string
}

export type SupportedFileGroups =
  | "Payslips"
  | "Documents"
  | "Tax Returns"
  | "Proof of Submission"
  | "Additional Documents"
  | "Tenancy Agreement"
  | "Bank Statements"
  | "Employment Documents"
  | "Employment Contracts"

interface FileGroup {
  group: SupportedFileGroups
  fileTypes: string[]
  individualFileName: string
  description: string
}

export const supportedFileTypes = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "application/pdf",
]

// Schema mappings to user friendly display names

export const fileLabelToDisplayNameConfig = {
  "Front of your driving licence": "the front of your driving licence",
  "Back of your driving licence": "the back of your driving licence",
  "Passport photo page": "your passport photo page",
  "Front of your identity card": "the front of your identity card",
  "Back of your identity card": "the back of your identity card",
}

const payslipGroup: FileGroup = {
  group: "Payslips",
  fileTypes: ["payslip", "payslip 1", "payslip 2", "payslip 3"],
  individualFileName: "Payslip",
  description: "Please upload your most recent payslips",
}

const taxReturnGroup: FileGroup = {
  group: "Tax Returns",
  fileTypes: ["tax return"],
  individualFileName: "Tax Return",
  description: "Please upload a copy of your most recent tax return.",
}

const proofOfSubmissionGroup: FileGroup = {
  group: "Proof of Submission",
  fileTypes: ["proof of submission"],
  individualFileName: "Proof of Submission",
  description: "Please upload a copy of your proof of submission.",
}

const additionalDocumentGroup: FileGroup = {
  group: "Additional Documents",
  fileTypes: ["additional document"],
  individualFileName: "Additional Document",
  description: "Please upload your additional documents",
}

const unspecifiedDocumentGroup: FileGroup = {
  group: "Documents",
  fileTypes: ["document", "document 1", "document 2", "document 3"],
  individualFileName: "Document",
  description: "Please upload your documents",
}

const tenancyAgreementGroup: FileGroup = {
  group: "Tenancy Agreement",
  fileTypes: ["tenancy agreement"],
  individualFileName: "Tenancy Agreement",
  description: "Please upload a copy of your tenancy agreement.",
}

const bankStatementGroup: FileGroup = {
  group: "Bank Statements",
  fileTypes: [
    "bank statement",
    "bank statement 1",
    "bank statement 2",
    "bank statement 3",
  ],
  individualFileName: "Bank Statement",
  description: "Please upload at least 3 months of bank statements",
}

const employmentDocumentGroup: FileGroup = {
  group: "Employment Documents",
  fileTypes: ["employment documents"],
  individualFileName: "Employment Document",
  description:
    "Please upload the relevant employment documents. They must contain: 1. Salary or hourly rate and guaranteed hours 2. Approximate start date 3. position 4. Employment contract type (e.g. permanent, fixed-term, temporary, zero-hour) 5.Details of any bonuses 6. Allowances",
}

const employmentContractGroup: FileGroup = {
  group: "Employment Contracts",
  fileTypes: ["employment contract"],
  individualFileName: "Employment Contract",
  description: "Please upload a copy of your employment contract.",
}

const allFileGroups: FileGroup[] = [
  payslipGroup,
  taxReturnGroup,
  proofOfSubmissionGroup,
  additionalDocumentGroup,
  unspecifiedDocumentGroup,
  tenancyAgreementGroup,
  bankStatementGroup,
  employmentDocumentGroup,
  employmentContractGroup,
]

export const getFileGroup = (
  fileOption: IFileOption
): SupportedFileGroups | string => {
  const fileTitle = fileOption.title ? fileOption.title.toLowerCase() : ""

  if (fileTitle === "") {
    return fileOption.title
  }

  const groupConfig = allFileGroups.find((group) =>
    group.fileTypes.includes(fileTitle)
  )

  return groupConfig !== undefined ? groupConfig.group : fileOption.title
}

export const getFileDescription = (fileGroup: SupportedFileGroups | string) => {
  const groupConfig = allFileGroups.find(
    (groupConfig) => fileGroup === groupConfig.group
  )
  return groupConfig !== undefined
    ? groupConfig.description
    : unspecifiedDocumentGroup.description
}

export const getIndividualFileName = (
  fileGroup: SupportedFileGroups | string
) => {
  const groupConfig = allFileGroups.find(
    (groupConfig) => fileGroup === groupConfig.group
  )
  return groupConfig !== undefined
    ? groupConfig.individualFileName
    : unspecifiedDocumentGroup.individualFileName
}

export const isSupportedFileType = (fileType: string) =>
  supportedFileTypes.includes(fileType)
