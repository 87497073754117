
import { Vue, Component, Prop } from "vue-property-decorator";
@Component({})
export default class DotProgress extends Vue {
  @Prop({ type: Number })
  public numOfStages!: number;

  @Prop({ type: Number })
  public currStage!: number;
}
