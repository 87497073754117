import Vue from "vue"
import VueAxios from "vue-axios"
// @ts-ignore
import VueAuthenticate from "vue-authenticate"
import axios from "../axios/chatbot"
// @ts-ignore

Vue.use(VueAxios, axios(null, null))
Vue.use(VueAuthenticate, {
  baseUrl: process.env.VUE_APP_BASE_URL,

  providers: {
    twitter: {
      redirectUri: `${process.env.VUE_APP_BASE_URL}/auth/twitter/callback`,
    },
    linkedin: {
      clientId: "78az6yd38ykj23",
      redirectUri: `${process.env.VUE_APP_BASE_URL}/auth/linkedin/callback`,
      requiredUrlParams: ["state", "scope"],
      scope: ["r_emailaddress", "r_liteprofile"],
    },
    facebook: {
      clientId: "464187164276446",
      redirectUri: `${process.env.VUE_APP_BASE_URL}/auth/facebook/callback`,
    },
  },
})

export default () => {}
