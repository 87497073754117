import { datadogLogs } from '@datadog/browser-logs'
import store from '@/store/store'
import { UserObj } from '../../store/modules/webchat'
import bowser from 'bowser'
import mixpanel, { Dict } from 'mixpanel-browser'

const dragEvents: string[] = [
  'drag',
  'dragstart',
  'dragend',
  'dragover',
  'dragenter',
  'dragleave',
  'drop',
]

export const addBlockDefaultDragEventBehaviourListeners = () => {
  if (window !== undefined) {
    dragEvents.map(dragEvent => {
      window.addEventListener(dragEvent, e => {
        e.preventDefault()
        e.stopPropagation()
      })
    })
  }
}

export const removeBlockDefaultDragEventBehaviourListeners = () => {
  if (window !== undefined) {
    dragEvents.map(dragEvent => {
      window.removeEventListener(dragEvent, e => {
        e.preventDefault()
        e.stopPropagation()
      })
    })
  }
}

if (process.env.VUE_APP_MIXPANEL_KEY) {
  mixpanel.init(process.env.VUE_APP_MIXPANEL_KEY)
}

interface Context {
  subjectId?: string
  applicationId?: string
  customerId?: string
  errorBody?: string | object
}
export const windowRef = window as any

const message = 'referencing-2.0-bot-fe'

export const getUserDataFromStore = (): Context | object => {
  if (store && store.getters) {
    const userObj: UserObj = store.getters['webchat/userObj']
    return userObj !== undefined
      ? {
          subjectId: userObj.id,
          applicationId: userObj.applicationId,
          customerId: userObj.customerId,
        }
      : {}
  } else {
    return {}
  }
}

export const logErrorToDatadog = (name: string, context: Context) =>
  datadogLogs.logger.error(name, {
    ...getUserDataFromStore(),
    ...context,
    message: `${message} ${name}`,
  })

export const logWarningToDatadog = (name: string, context: Context) =>
  datadogLogs.logger.warn(name, {
    ...getUserDataFromStore(),
    ...context,
    message: `${message} ${name}`,
  })

export const logInfoToDatadog = (name: string, context: Context) =>
  datadogLogs.logger.info(name, {
    ...getUserDataFromStore(),
    ...context,
    message: `${message} ${name}`,
  })

export const track = (eventName: string, properties: Dict) => {
  const message = 'referencing-2.0-bot-fe'
  if (process.env.VUE_APP_ENVIRONMENT === 'prod' && process.env.VUE_APP_MIXPANEL_KEY) {
    mixpanel.track(eventName, {
      ...getUserDataFromStore(),
      ...properties,
      service: message,
    })
  }
}

export const checkUnsupportedBrowser = () => {
  const browser = bowser.getParser(windowRef.navigator.userAgent)
  const isValidBrowser = browser.satisfies({
    safari: '>=14',
  })
  const browserName = browser.getBrowserName()

  if (
    browserName === 'Internet Explorer' ||
    (!isValidBrowser && browserName === 'Safari')
  ) {
    track('Displayed - Unsupported browser page', {})
    // We don't support IE or Safari below version 14
    return true
  }

  // Every other browser and Safari version 14 and over
  return false
}

export const checkHasComplete = (progress: { flows: any; recommendations: any }) => {
  const hasCompletedAllFlows =
    Object.keys(progress.flows).length > 0 &&
    Object.keys(progress.flows).every(flow => progress.flows[flow].completed)

  const hasCompletedRecommendations =
    progress.recommendations.length === 0 ||
    (progress.recommendations.length > 0 &&
      progress.recommendations.every((recommendation: any) => recommendation.completed))

  if (hasCompletedAllFlows && hasCompletedRecommendations) {
    track('Displayed - All done page', {})
  }

  return hasCompletedAllFlows && hasCompletedRecommendations
}

export const formatIncomeValue = (value: string, symbol: string) => {
  const formattedValue = parseFloat(value).toLocaleString('en-GB', {
    minimumFractionDigits: 2,
  })

  return `${symbol}${formattedValue}`
}
