<template>
  <div>
    <h4>Upload a selfie</h4>
    <div class="selfieTips">
      <ul>
        <li>Ensure your document is a PNG, JPEG or PDF</li>
        <li>Take a selfie showing your head and shoulders</li>
        <li>Ensure your face is well lit on a plain background</li>
      </ul>
    </div>
    <!-- <p>Take a selfie showing your head and shoulders against a plain background and 
containing no other people</p> -->
    <div class="drop-zone" @drop="ondrop" @click="$refs.file.click()">
      <input
        type="file"
        ref="file"
        name="selfie"
        id="selfie"
        class="input text-input w-input"
        accept="image/x-png,image/png,image/jpeg,application/pdf"
        @change="setSelfie"
      />
      <styled-drop-zone-indicator>
        <div class="drop-info">
          <FileUploadSummary v-if="!uploadedImage" fileTitle="selfie" />
          <div v-if="uploadedImage" class="image-preview-wrapper">
            <img
              v-bind:key="uploadedImage"
              v-if="uploadedImage !== ''"
              :src="uploadedImage"
              id="selfie-container"
            />
          </div>
          <SupportingText v-if="uploadedImage !== ''"
            ><b>Is your face visible and clear?</b></SupportingText
          >
          <Loader
            v-if="this.uploadedImage !== ''"
            :filename="this.filename"
            disablePercentage
          >
          </Loader>
        </div>
      </styled-drop-zone-indicator>
      <div class="image-preview-controls"></div>
    </div>

    <div class="retake-container">
      <button
        v-if="uploadedImage !== ''"
        @click="$refs.file.click()"
        class="btn btn-outline"
        id="retake"
      >
        Retake
      </button>
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin"
import Loader from "../../progress/Loader"
import {
  addBlockDefaultDragEventBehaviourListeners,
  removeBlockDefaultDragEventBehaviourListeners,
  track,
} from "@/common/utils"
import FileUploadSummary from "@/components/generalUI/FileUploadSummary.vue"
import styled from "vue-styled-components"
import SupportingText from "@/components/design-system/SupportingText.vue"
import { isSupportedFileType } from "@/components/file/utils"

const StyledDropZoneIndicator = styled.div`
  border: 2px dashed #bdbdbd;
  border-radius: 4px;
  width: 100%;
  height: 100%;
`

export default {
  components: {
    Loader,
    FileUploadSummary,
    StyledDropZoneIndicator,
    SupportingText,
  },
  mixins: [formMixin],
  data() {
    return {
      selfie: [],
      uploadedImage: "",
      screenWidth: 801,
      botIconsUrl: "",
      filename: "",
    }
  },
  validations: {
    selfie: {
      isSelected: (v, vm) => {
        return vm.selfie !== null && vm.selfie.length && vm.selfie.length > 0
      },
    },
  },
  methods: {
    setSelfie(e) {
      if (e.target.files.length > 0) {
        this.selfie = e.target.files
        this.uploadedImage = URL.createObjectURL(e.target.files[0])
        this.filename = e.target.files[0].name
      } else {
        this.selfie = null
        this.uploadedImage = ""
        this.filename = null
      }
      this.validate(e)
    },
    clearFileInput(e) {},
    ondrop(e) {
      e.stopPropagation()
      e.preventDefault()
      if (e.dataTransfer.files.length > 0) {
        if (isSupportedFileType(e.dataTransfer.files[0].type)) {
          this.selfie = e.dataTransfer.files
          this.uploadedImage = URL.createObjectURL(e.dataTransfer.files[0])
          this.filename = e.dataTransfer.files[0].name

          const selfieNode = document.getElementById(`selfie`)
          this.validateDrop(selfieNode, e.dataTransfer.files)
        }
      }
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth
    this.botIconsUrl = process.env.VUE_APP_ASSETS || ""
    addBlockDefaultDragEventBehaviourListeners()
  },
  unmounted() {
    removeBlockDefaultDragEventBehaviourListeners()
  },
}
</script>

<style lang="less" scoped>
.image-preview-wrapper {
  img {
    min-height: 200px;
    border-radius: 15px;
    border: 2px dashed var(--mainHighlight);
    object-fit: cover;
    max-width: 300px;
  }
  padding: 10px;
  margin: 10px auto 40px auto;
  max-width: 500px;
}

.image-preview-wrapper.dashed-border {
  border-style: dashed;
  border-width: 2px;
  border-color: #bcbec0;
  border-radius: 15px;
}

.drop-zone {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 14px;
  border: none;
  margin: 20px auto;
  min-height: 200px;
  height: 200px;
  height: fit-content;
  width: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 0;
  }
  & input {
    opacity: 0; /* invisible */
    display: none;
    width: 1px;
    height: 1px;
    cursor: pointer;
  }

  #selfie-container {
    max-height: 200px;
    border-radius: 10px;
    border: 1px dashed var(--mainHighlight);
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;
  }

  p {
    margin: 10px 0;
  }

  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }

  & .drop-info {
    pointer-events: none;
    padding: 10px;
    width: 100%;

    & p {
      text-align: center;
      letter-spacing: 0.15px;
      color: #55616b;
    }

    & img {
      max-width: 100%;
      min-height: 40px;
    }
  }
}

.retake-container {
  text-align: center;
}

.selfieTips {
  ul {
    list-style: none;
    padding: 10px 0;
    text-align: left;
    li {
      margin: 10px 0;
      list-style-position: inside;
    }
  }

  ul li::before {
    content: "\2022";
    color: var(--mainHighlight);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: 1em;
  }
}
</style>
