<template>
  <styled-wrapper><slot /></styled-wrapper>
</template>

<script>
import styled from "vue-styled-components"
import { resetStyles, baseTextStyle, ellipsisStyle } from "./utils"
import { fontSizes } from "@/common/fonts"
import { colors } from "@/common/colors"

const props = {
  ellipsis: {
    type: Boolean,
    default: false,
  },
  align: {
    type: String,
    default: "left",
  },
}

const StyledWrapper = styled("span", props)`
  ${resetStyles()}
  ${baseTextStyle()}
  color: ${colors.textSecondary};
  font-size: ${fontSizes.s};
  text-align: ${(props) => props.align};
  ${(props) => (props.ellipsis ? ellipsisStyle() : "")}
`

export default {
  components: {
    StyledWrapper,
  },
  props,
}
</script>
