<template>
  <div class="chat-and-swipe">
    <EditSendBox :hasSuggestedActions="hasSuggestedActions">
      <div
        id="webchat"
        :class="journeyCompleted ? 'webchat hide-chat' : 'webchat'"
        role="main"
      ></div>
      <div class="nothing-to-do" v-if="journeyCompleted">
        <h3>
          There is currently nothing for you to do! If we need anything further
          from you, we will email you.
        </h3>
      </div>
    </EditSendBox>
  </div>
</template>

<script>
import { colors, glLegacyColorPalette } from "@/common/colors"
import { mapActions, mapGetters } from "vuex"

const styled = require("vue-styled-components")

const EditSendBox = styled.default("div", { hasSuggestedActions: Boolean })`
  height: 100%;
    #webchat {
    font-size: 16px;
    letter-spacing: -0.01em;
    line-height: 20px;
    font-weight: 400;
    margin: ${(props) => (props.theme.default.agentBot ? "0" : "")} !important;
      max-height: ${(props) =>
        props.theme.default.agentBot ? "none" : "95vh"} !important;

    @media (max-width: 800px) {
      padding-top: 53px;
    }
    
    .webchat__send-box__main {
      display: ${(props) => (props.hasSuggestedActions ? "none" : "")};
    }
  }

`
export default {
  computed: {
    ...mapGetters({
      botConnection: "webchat/botConnection",
      store: "webchat/store",
      userId: "webchat/userId",
      jwtToken: "user/jwtToken",
    }),
    getAvatarFullUrl() {
      return `${process.env.VUE_APP_PROD_CORE_URL}/${
        process.env.VUE_APP_PROD_CORE_CONTAINER
      }/${this.userAvatar.toLowerCase()}${process.env.VUE_APP_PROD_CORE_SAS}`
    },
  },
  data() {
    return {
      styleOptions: {
        bubbleFromUserTextColor: "#FFFFFF",
        bubbleTextColor: colors.botChatPrimary,
        accent: this.themeOptions.defaultAccent,
        primaryFont: "Lexend Deca",
        avatarSize: 30,
        botAvatarInitials: "V",
        userAvatarInitials: "A",
        bubbleBorderRadius: "10px 10px 10px 0",
        bubbleFromUserBorderRadius: "10px 10px 0 10px",
        bubbleFromUserBackground: glLegacyColorPalette.legacyGrey6,
        bubbleBorderColor: "#F2F2F2",
        primaryHeaderText: "#f2f2f2",
        suggestedActionTextColor: "#257DA2",
        suggestedActionFontWeight: 400,
        suggestedActionBorderRadius:
          this.themeOptions.suggestedActionBorderRadius || 10,
        suggestedActionLayout: "stacked",
        suggestedActionBackground: this.themeOptions.bubbleBackground,
        suggestedActionHeight: 60,
        suggestedActionBorderColor: "#257DA2",
        hideUploadButton: true,
        timestampColor: this.themeOptions.fontColour,
        timestampFormat: "relative",
        sendBoxBackground: this.themeOptions.sendBoxBackground,
        sendBoxHeight: "85px",
        sendBoxPlaceholderColor: "#bcbec0",
        sendBoxBorderTop: "solid 1px rgba(128, 128, 128, 0.26)",
        bubbleFromUserBorderColor: glLegacyColorPalette.legacyGrey6,
        bubbleBackground: "#F2F2F2",
        userBubbleBackground: glLegacyColorPalette.legacyGrey6,
      },
      hasSuggestedActions: false,
    }
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
    userAvatar: {
      type: String,
      required: true,
    },
    themeOptions: {
      type: Object,
      required: true,
    },
    journeyCompleted: {
      type: Boolean,
      required: true,
    },
  },
  watch: {
    userAvatar: {
      handler: function() {
        this.updateAvatar()
      },
    },
  },
  components: { EditSendBox },
  methods: {
    async updateAvatar() {
      document.documentElement.style.setProperty(
        "--userAvatar",
        `url(${this.getAvatarFullUrl})`
      )
    },
    ...mapActions({
      registerActivityCallback: "webchat/registerActivityCallback",
      sendMessage: "webchat/sendMessage",
    }),
  },
  updated() {
    this.updateAvatar()
  },
  mounted() {
    this.registerActivityCallback((activity) => {
      if (
        activity &&
        activity.suggestedActions &&
        activity.suggestedActions.actions &&
        activity.suggestedActions.actions.length > 0
      ) {
        this.hasSuggestedActions = true
      } else {
        this.hasSuggestedActions = false
      }
    })
    this.updateAvatar()
    const styling = { ...this.styleOptions }

    styling.botAvatarImage = '/assets/img/bot-avatar.png'

    window.WebChat.renderWebChat(
      {
        directLine: this.botConnection,
        store: this.store,
        userID: this.userId,
        username: "Anonymous",
        locale: "en-GB",
        styleOptions: { ...styling },
      },
      document.getElementById("webchat")
    )
  },
}
</script>

<style lang="less" scoped>
.webchat {
  display: block;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(hsla(0, 0%, 100%, 0.25)),
    to(hsla(0, 0%, 100%, 0.25))
  );
  background-image: linear-gradient(
    180deg,
    hsla(0, 0%, 100%, 0.25),
    hsla(0, 0%, 100%, 0.25)
  );
  /* box-shadow: 1px 1px 20px 0 rgba(44, 44, 44, 0.4); */
}

.chat-and-swipe {
  height: 100%;
  overflow-y: hidden;
  display: relative;
}

.hide-chat {
  display: none;
}

.nothing-to-do {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 12px;

  h3 {
    text-align: center;
  }
}

@media (min-width: 800px) {
  .webchat {
    height: 95vh;
    min-height: unset !important;
  }
}

.webchat {
  background: white;
  height: 100%;
  position: relative;
  min-height: 100%;
  border-radius: 10px;
}

.webchat:after {
  content: "";
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  pointer-events: none;
  width: 100%;
  height: 50px;
  border-radius: 10px;
}

@media (min-width: 800px) {
  #webchat {
    box-shadow: 0 0 8px 2px rgba(100, 100, 100, 0.25);
    border-radius: 10px;
    margin: 15px;
    max-height: 95vh;
  }
}

#hide {
  display: none;
}

.main {
  position: relative;
  z-index: 900;
}
</style>
