<template>
  <div id="progressContainer" class="vertical-flex-box">
    <!-- <div id="progressIconContainer" class="progress-bar">
      <svg
        class="progress-icon"
        viewBox="0 0 21 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          stroke-width="1"
          fill="none"
          fill-rule="evenodd"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <g
            id="Custom-Preset"
            transform="translate(-121.000000, -107.000000)"
            stroke-width="2.8"
          >
            <g id="Group" transform="translate(122.500000, 109.000000)">
              <polyline
                id="greyTick"
                stroke-width="2.8"
                stroke="#F1F2F2"
                points="17.3272946 7.72580994 8.69850388 15.4544328 4.79 11.9718471"
              ></polyline>
              <polyline
                id="greyHouse"
                stroke-width="2.8"
                stroke="#F1F2F2"
                points="14.1311844 4.84552879 8.6605591 0 1.21550749e-14 7.72920299 1.21550749e-14 20 17.3269263 20 17.3269263 7.72920299"
              ></polyline>
              <polyline
                id="house"
                stroke="var(--mainHighlight)"
                points="14.1311844 4.84552879 8.6605591 0 1.21550749e-14 7.72920299 1.21550749e-14 20 17.3269263 20 17.3269263 7.72920299"
              ></polyline>
              <polyline
                id="tick"
                stroke="var(--mainHighlight)"
                points="17.3272946 7.72580994 8.69850388 15.4544328 4.79 11.9718471"
              ></polyline>
            </g>
          </g>
        </g>
      </svg>
    </div> -->

    <div style="display: grid; place-items: center">
      <svg
        version="1.1"
        id="L4"
        x="0px"
        y="0px"
        height="50px"
        width="200px"
        fill="white"
        viewBox="-40 25 150 50"
        enableBackground="new 0 0 0 0"
      >
        <circle fill="var(--mainHighlight)" stroke="none" cx="6" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.1"
          />
        </circle>
        <circle fill="var(--mainHighlight)" stroke="none" cx="26" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.2"
          />
        </circle>
        <circle fill="var(--mainHighlight)" stroke="none" cx="46" cy="50" r="6">
          <animate
            attributeName="opacity"
            dur="1s"
            values="0;1;0"
            repeatCount="indefinite"
            begin="0.3"
          />
        </circle>
      </svg>
    </div>
    <div class="progress-label">
      {{ Math.round(normalizedProgress * 100) }}% Uploaded
    </div>
  </div>
</template>

<script>
import ProgressBar from "progressbar.js";

export default {
  props: {
    progress: {
      type: Number,
      required: true,
    },
  },
  data: function() {
    return {
      normalizedProgress: this.progress,
      house: undefined,
      tick: undefined,
      step: 0.01,
    };
  },
  watch: {
    progress() {
      this.normalizedProgress = Math.min(
        1,
        this.progress - (this.progress % this.step) + this.step
      );
      this.house.animate(this.normalizedProgress, {}, () => {
        if (this.house.value() === 1) this.tick.animate(1, {});
      });
    },
  },
  mounted() {
    this.house = new ProgressBar.Path("#house", {
      easing: "linear",
      duration: 100,
    });
    this.tick = new ProgressBar.Path("#tick", {
      easing: "easeOut",
      duration: 800,
    });
    this.tick.set(this.progress);
    this.house.set(this.progress);
  },
};
</script>
