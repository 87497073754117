<template>
  <div class="container">
    <div style="display: flex; column-gap: 50px; place-items: center">
      <div class="svgContainer">
        <svg
          version="1.1"
          id="L4"
          x="0px"
          y="0px"
          height="50px"
          width="50px"
          fill="white"
          viewBox="0 20 50 60"
          enableBackground="new 0 0 0 0"
        >
          <circle
            fill="var(--mainHighlight)"
            stroke="none"
            cx="6"
            cy="50"
            r="6"
          >
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle
            fill="var(--mainHighlight)"
            stroke="none"
            cx="26"
            cy="50"
            r="6"
          >
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle
            fill="var(--mainHighlight)"
            stroke="none"
            cx="46"
            cy="50"
            r="6"
          >
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
      <p v-if="filename">{{ filename }}</p>
    </div>
    <div v-if="filename" class="counter"></div>
    <span v-if="filename" class="percent">%</span>
  </div>
</template>

<script>

// Hiding the loader through css doesn't work well on slower connections and slower requests
// Ideally all loaders would be infinite and then shown/hidden by some logic SEE: VGL-1162

import { formMixin } from "@/mixins/formMixin";
export default {
  mixins: [formMixin],
  props: {
    progress: {
      type: Number,
    },
    filename: {
      type: String,
    },
    disablePercentage: {
      type: Boolean,
      required: false,
    },
  },
  data: function() {
    return {
      normalizedProgress: this.progress,
      step: 0.01,
      uploadedFilename: this.filename,
    };
  },
  watch: {
    progress() {
      this.normalizedProgress = Math.min(
        1,
        this.progress - (this.progress % this.step) + this.step
      );
      if (this.normalizedProgress === "100") {
        this.disablePercentage = true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  min-height: auto;
  margin: 0;
}

p {
  font-weight: 700;
  padding-right: 50px;
  color: var(--primaryHeaderText) !important;
}

.progress-label,
.percent {
  color: var(--mainHighlight);
}

.counter::after {
  color: var(--mainHighlight);
  content: counter(count);
  animation: counter 1s linear forwards;
  counter-reset: count 0;
}

.svgContainer {
  height: 50px;
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  80% {
    counter-increment: count 70;
  }
  90% {
    counter-increment: count 98;
  }
  100% {
    counter-increment: count 100;
  }
}
</style>
