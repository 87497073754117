<template>
  <div id="files">
    <h2>Proof of Address</h2>
    <div
      v-bind:key="docIndex"
      class="form-group"
      v-for="(doc, docIndex) in documents"
      :class="{ 'hide-file-keep-in-dom': docIndex + 1 !== stepped.current }"
    >
      <div class="input-wrapper">
        <div class="label-container">
          <div class="field-label">
            Select a type for document {{ docIndex + 1 }}
          </div>
        </div>
        <div class="identity-options">
          <label
            class="decorated-radio w-radio"
            v-for="(type, index) in model.types"
            :key="index"
          >
            <input
              type="radio"
              :id="`type-${index}`"
              accept="image/x-png,image/png,image/jpeg,application/pdf"
              :name="`type-${docIndex}`"
              :value="type.label"
              class="hidden-radio-button w-radio-input"
              @click="setDocumentType(docIndex, type.label)"
              data-poi-type-radio
            />
            <div class="decorated-radio-border"></div>
            <img :src="type.icons.unchecked" alt class="radio-icon unchecked" />
            <img :src="type.icons.checked" alt class="radio-icon checked" />
            <span class="field-label decorated-radio-label w-form-label">
              {{ type.label }}
            </span>
          </label>
        </div>
      </div>

      <div
        class="drop-zone"
        @click="triggerRef(docIndex)"
        v-if="documents[docIndex].docType !== ''"
      >
        <input
          type="file"
          :key="`file-${docIndex}`"
          :ref="`file-${docIndex}`"
          :name="`file-${docIndex}`"
          :id="`file-${docIndex}`"
          class="input text-input w-input"
          accept="image/*"
          @change="uploadDoc($event, docIndex)"
        />
      
        <div class="drop-info">
          <p>
            {{
              screenWidth < 800
                ? "Tap to upload photo using your device"
                : "Click to upload a file"
            }}
          </p>

          <SimpleSVG
            v-if="documents[docIndex].fileUrl === ''"
            height="40"
            custom-class-name="icon"
            :src="`${botIconsUrl}/user.svg`"
          />
          <img
            v-if="documents[docIndex].fileUrl !== ''"
            :src="documents[docIndex].fileUrl"
          />
          <Loader
            v-if="documents[docIndex].fileUrl !== ''"
            :filename="documents[docIndex].fileName"
            disablePercentage
          >
          </Loader>
        </div>
      </div>
      <div class="retake-container">
        <button
          v-if="documents[docIndex].fileUrl !== ''"
          @click="triggerRef(docIndex)"
          class="btn btn-outline"
          id="retake"
        >
          Retake
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import FileUploadCards from "@/components/file/FileUploadCards";
import Loader from "../../progress/Loader.vue";
const { SimpleSVG } = require("vue-simple-svg");

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
    stepped: {
      type: Object,
    },
  },
  data: function() {
    return {
      fileContainers: [],
      selected: [],
      botIconsUrl: "",
      screenWidth: 801,
      uploadedImage: "",
      documents: [
        { fileUrl: "", docType: "", fileName: "" },
        { fileUrl: "", docType: "", fileName: "" },
      ],
    };
  },
  validations() {
    return {
      documents: {
        $each: {
          isDocuments: (v, vm) => {
            return v.fileUrl !== "" && v.docType !== "" && v.fileName !== "";
          },
        },
      },
    };
  },
  methods: {
    triggerRef(index) {
      let fileInputRef = `file-${index}`;
      this.$refs[fileInputRef][0].click();
    },
    setDocumentType(index, type) {
      let temp = [...this.documents];
      temp[index].docType = type;
      this.documents = [...temp];
      this.touch(this.$v.documents);
    },
    uploadDoc(e, index) {
      let temp = [...this.documents];
      temp[index].fileUrl = URL.createObjectURL(e.target.files[0]);
      temp[index].fileName = e.target.files[0].name;
      this.uploadedImage = e.target.files[0].name;
      this.documents = [...temp];
      this.touch(this.$v.documents);
      this.$emit("enable-stepped-progression", true);
    },
  },

  mounted() {
    this.botIconsUrl = process.env.VUE_APP_ASSETS || "";
    this.screenWidth = window.innerWidth;
    this.$emit("update-stepped", {
      current: 1,
      stages: 2,
    });
  },
  components: {
    FileUploadCards,
    SimpleSVG,
    Loader,
  },
};
</script>

<style lang="less" scoped>
.horizontal-flex-box label {
  width: auto;
  padding-right: 12px;
}

#nextAndBack {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hide-file-keep-in-dom {
  display: none;
}

.identity-options {
  margin: 15px;
  grid-template-columns: 1fr;
  display: grid;
  grid-gap: 10px;
}

#img-arrow {
  width: 18px;
  height: 18px;
  color: #0f4561;
  fill: #0f4561;
}

.field-label.decorated-radio-label {
  color: #808284;
  // font-size: 16px;
  font-weight: 400;
}

.retake-container {
  text-align: center;
}

.drop-zone {
  background-color: #f2f2f2;
  border-radius: 15px;
  padding: 0;
  margin: 40px auto;
  height: fit-content;
  width: 90%;
  cursor: pointer;
  text-align: center;

  & input {
    opacity: 0; /* invisible */
    width: 1px;
    height: 1px;
    cursor: pointer;
    position: absolute;
  }

  & .drop-info {
    pointer-events: none;
    padding: 10px;

    & p {
      text-align: center;
      letter-spacing: 0.15px;
      color: #55616b;
    }

    & img {
      border: 1px dashed var(--mainHighlight);
      max-width: 300px;
      border-radius: 15px;
    }
  }
}

.controls-container > * {
  padding: 0 10px;
}

.controls-container button {
  max-width: 130px;
}

.controls-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
</style>
