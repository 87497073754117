var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(Object.keys(_vm.progress.flows).length != 0)?_c('div',{staticClass:"overall-progress-container"},[_c('div',[(!_vm.shopOpen)?_c('div',{staticClass:"menu-items-container"},_vm._l((_vm.progress.flows),function(flow,index){return _c('ProgressMenuItem',{key:index,attrs:{"iconName":flow.id,"percent":_vm.getPercentComplete(flow.id),"draggable":"true","activeTab":_vm.activeTab === flow.id},on:{"item-clicked":function($event){return _vm.setActive($event)}}})}),1):_vm._e(),(_vm.activeTab !== '' && !_vm.shopOpen)?_c('FlowStages',{directives:[{name:"touch",rawName:"v-touch:swipe",value:(_vm.swipeHandler),expression:"swipeHandler",arg:"swipe"}],key:_vm.activeTab,attrs:{"title":_vm.activeTab,"rounded":_vm.activeTab === Object.keys(_vm.progress.flows)[0]
            ? 'right'
            : _vm.activeTab ===
              Object.keys(_vm.progress.flows)[
                Object.keys(_vm.progress.flows).length - 1
              ]
            ? 'left'
            : 'both',"flows":_vm.progress.flows}}):_vm._e()],1),(!_vm.shopOpen)?_c('Recommendations',{attrs:{"recommendations":_vm.progress.recommendations,"flows":_vm.progress.flows}}):_vm._e(),(_vm.shopOpen)?_c('div',{staticClass:"shop-container"},[_c('iframe',{attrs:{"src":`${_vm.getShopUrl()}`,"frameborder":"0"}})]):_vm._e()],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }