
import { Component, Vue } from "vue-property-decorator";
import ControlElement from "./ControlElement.vue";
import styled from "vue-styled-components";

const StyledPhoneInputWrapper = styled.div`
  #mobile.vue-tel-input {
    display: flex;
    align-items: center;
    border: none;

    &:focus-within {
      box-shadow: none;
    }

    .vti__dropdown {
      padding: 0 10px 0 0;

      .vti__dropdown-list {
        z-index: 99;
      }
    }
  }
`;

const PhoneInputProps = Vue.extend({
  props: {
    id: { type: String, required: true },
    name: { type: String, required: true },
    label: { type: String, required: true },
    required: Boolean,
    disabled: Boolean,
    errorMessage: String,
    centre: Boolean,
    focusItem: Boolean,
    value: { type: String, default: "" },
    validationErrors: { type: Array, default: () => [] }
  }
});

@Component({
  components: {
    ControlElement,
    "styled-phone-input-wrapper": StyledPhoneInputWrapper
  }
})
export default class PhoneInput extends PhoneInputProps {
  inputEvent(e: string) {
    this.$emit("input", e);
    this.$emit("inputEvent", { target: { name, value: e } });
  }
}
