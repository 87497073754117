export const glLegacyColorPalette = {
  legacyGrey1: "#B3B3B3",
  legacyGrey2: "#C8C9C9",
  legacyGrey3: "#E2E6EC",
  legacyGrey4: "#555555",
  legacyGrey5: "#DDDEE0",
  legacyGrey6: "#1D2B2F",
  legacyGrey7: "#686E75",
  legacyGrey8: "#E9ECEF",
  legacyGrey9: "#FAFAFA",
  legacyGrey10: "rgba(255, 255, 255, 0.15)",
  legacyGrey11: "#2C3030",
}

export const greyscaleColorPalette = {
  greyscale1: "#1A1A1A",
  greyscale2: "#333333",
  greyscale3: "#4F4F4F",
  greyscale4: "#828282",
  greyscale5: "#BDBDBD",
  greyscale6: "#E0E0E0",
  greyscale7: "#F2F2F2",
}

export const palette = {
  ...greyscaleColorPalette,
  white: "#FFFFFF",
  primary: "#34D9C3",
  primaryDark: "#479885",
  secondary: "#257DA2",
  secondaryDark: "#0C4861",
  redLight: "#FEE5E6",
  red: "#F5222D",
  redDark: "#79050B",
  orangeLight: "#FEEFDE",
  orange: "#FA8C16",
  orangeDark: "#743D03",
  blueLight: "#EBF3FD",
  blue: "#2F80ED",
  blueDark: "#0A3979",
  greenLight: "#CAF5B5",
  green: "#52C41A",
  greenDark: "#102605",
  greyDark: "#414B4D",
  buttonSecondary: "#2C3030",
  botChatPrimary: "#0E455B",
}

export const linkColors = {
  linkPrimary: palette.primary,
  linkTertiary: palette.secondary,
  linkTertiaryDark: palette.secondaryDark,
}

export const textColors = {
  textPrimary: glLegacyColorPalette.legacyGrey11,
  textSecondary: glLegacyColorPalette.legacyGrey7,
  textDisabled: glLegacyColorPalette.legacyGrey1,
}

export const buttonColors = {
  buttonPrimary: palette.primary,
  buttonSecondary: palette.white,
  buttonSecondaryBorder: palette.greyscale6,
  buttonCancel: palette.greyscale7,
  buttonDanger: palette.red,
  buttonDisabled: glLegacyColorPalette.legacyGrey1,
}

export const colors = {
  ...palette,
  ...greyscaleColorPalette,
  ...textColors,
  ...linkColors,
  ...buttonColors,
}

export type Color = keyof typeof colors
