
const styled = require("vue-styled-components");

import { track } from "@/common/utils";
import { gaps } from "@/common/spacing";
import { Vue, Prop, Component } from "vue-property-decorator";

interface Props {
  theme: any;
}

const SubjectDetailsContainer = styled.default.div`
  position: relative;
`;

const ModalThemeing = styled.default.div<Props>`
  display: flex !important;
  justify-content: flex-end;
  gap: ${gaps.s};
  align-items: center;
     
  .btn {
    margin: 0;
  }

  @media only screen and (max-width: 800px) {
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;

    .error-div {
      width: 100%;
    }
    
    .btn {
      width: calc(50% - ${gaps.s});
      margin-left: auto;
    }
  }

  .btn-alternate {
    background: ${(props: Props) =>
      props.theme.default.secondaryBtnColor || ""} !important;
  }

  .btn-primary {
    background: ${(props: Props) =>
      props.theme.default.mainBtnColor || ""} !important;
      border:none;
  }

  .error-div {
    text-align: left;
    letter-spacing: 0.15px;
    color: #ed1c24;
  }
`;

@Component({ components: { ModalThemeing, SubjectDetailsContainer } })
export default class ProofOfIdentityFooter extends Vue {
  @Prop({ type: Object })
  public model!: any;

  @Prop({ type: Object })
  public v!: object;

  @Prop({ type: Function })
  public close!: Function;

  @Prop({ type: Function })
  public submit!: Function;

  @Prop({ type: Object })
  public stepped!: { current: number | null; stages: number | null };

  @Prop({ type: Boolean })
  public disableSteppedProgression!: boolean;

  @Prop({ type: Boolean })
  public errorText!: string | undefined;

  @Prop({ type: Boolean })
  public isLoading!: boolean

  public clicked(e: any) {
    const { name } = e.target;

    switch (name) {
      case "back":
        if (this.stepped.current !== 1 || 0) {
          track("Click - Proof of identity modal back", {});
          this.$emit("update-stepped-footer", {
            current: this.stepped.current !== null ? this.stepped.current-- : 0,
            stages: this.stepped.stages,
          });
        }
        break;
      case "nextSubmit":
        if (this.stepped.current !== this.stepped.stages) {
          track("Click - Proof of identity modal next", {});
          this.$emit("update-stepped-footer", {
            current: this.stepped.current !== null ? this.stepped.current++ : 0,
            stages: this.stepped.stages,
          });
        } else {
          track("Click - Proof of identity modal submit", {});
          this.submit();
        }
        break;
      default:
        break;
    }
  }
}
