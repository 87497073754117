var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"item-task-container",class:{
    roundedLeft: _vm.rounded === 'left',
    roundedRight: _vm.rounded === 'right',
    roundedFull: _vm.rounded === 'both',
  }},[_c('h5',{attrs:{"id":"flow-name"}},[_vm._v(_vm._s(_vm.convertToTitle(_vm.title)))]),(_vm.findFlow !== undefined && Array.isArray(_vm.findFlow.actions) && _vm.findFlow.actions.length > 0)?_c('ul',{staticClass:"tasks-list"},[_c('FlowActionWrapper',_vm._l((_vm.findFlow.actions),function(flow,index){return _c('li',{key:index,staticClass:"task-container"},[_c('p',{attrs:{"id":"flow-action-title"}},[_vm._v(" "+_vm._s(flow.displayName || _vm.convertToTitle(flow.id))+" ")]),_c('span',{staticClass:"flow-status",class:{
            complete: flow.completed === true,
            awaiting: flow.completed === false,
          }},[_vm._v(_vm._s(flow.completed === true ? "Completed" : "Awaiting"))])])}),0)],1):_c('h5',[_vm._v("All "+_vm._s(_vm.title)+" actions have been completed.")])])
}
var staticRenderFns = []

export { render, staticRenderFns }