<template>
  <styled-summary-wrapper>
    <styled-heading-wrapper>
      <H2
        >Great! You have submitted all the details we need for this income.</H2
      >
      <SupportingText
        >Please scroll down and review your details. If you notice a mistake,
        click the ‘I need to make a change’ button. Otherwise, please proceed.
      </SupportingText>
    </styled-heading-wrapper>
    <UnemployedSummary v-if="currentIncome.type === `Unemployment`" />
    <EmployedSummary
      :income="currentIncome"
      :employerReference="employerReference"
      :accountantDetails="accountantDetails"
      v-if="currentIncome.type === `employment`"
    />
    <BenefitsSummary
      :income="currentIncome"
      :nationalInsuranceNumber="nationalInsuranceNumber"
      v-if="currentIncome.type === 'Benefits'"
    />
    <PensionSummary
      :income="currentIncome"
      :nationalInsuranceNumber="nationalInsuranceNumber"
      v-if="currentIncome.type === 'Pension'"
    />
    <SavingsSummary
      :income="currentIncome"
      v-if="currentIncome.type === 'Savings'"
    />
    <InvestmentsSummary
      :income="currentIncome"
      v-if="currentIncome.type === 'Investments'"
    />
    <StudentSummary
      :income="currentIncome"
      v-if="currentIncome.type === 'Student'"
    />
  </styled-summary-wrapper>
</template>

<script>
import { formMixin } from "@/mixins/formMixin"
import {
  track
} from "@/common/utils"
import styled from "vue-styled-components"
import { paddings } from "@/common/spacing"
import SupportingText from "@/components/design-system/SupportingText.vue"
import H2 from "@/components/design-system/H2.vue"
import UnemployedSummary from "@/components/step-summary/income-details/UnemployedSummary.vue"
import EmployedSummary from "@/components/step-summary/income-details/EmployedSummary.vue"
import BenefitsSummary from "@/components/step-summary/income-details/BenefitsSummary.vue"
import PensionSummary from "@/components/step-summary/income-details/PensionSummary.vue"
import SavingsSummary from "@/components/step-summary/income-details/SavingsSummary.vue"
import InvestmentsSummary from "@/components/step-summary/income-details/InvestmentsSummary.vue"
import StudentSummary from "@/components/step-summary/income-details/StudentSummary.vue"

const StyledSummaryWrapper = styled.div`
  padding 24px 0;
`

const StyledHeadingWrapper = styled.div`
  text-align: left;
  padding-top: ${paddings.m};
`

export default {
  components: {
    H2,
    UnemployedSummary,
    EmployedSummary,
    PensionSummary,
    BenefitsSummary,
    InvestmentsSummary,
    StudentSummary,
    SavingsSummary,
    StyledSummaryWrapper,
    SupportingText,
    StyledHeadingWrapper,
  },
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      default: undefined,
    },
    stepped: {
      type: Object,
    },
    overallProgress: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      screenWidth: 801,
      botIconsUrl: "",
      incomes: this.model.incomes,
      employerReference: this.model.employerReference,
      accountantDetails: this.model.accountantDetails,
      nationalInsuranceNumber: this.model.nationalInsuranceNumber,
    }
  },
  computed: {
    currentIncome() {
      return this.incomes[this.incomes.length - 1]
    },
  },
  mounted() {
    track("Displayed - Income details summary", { income: this.currentIncome })
    this.screenWidth = window.innerWidth
    this.botIconsUrl = process.env.VUE_APP_ASSETS || ""
  },
}
</script>
