<template>
  <styled-wrapper><slot /></styled-wrapper>
</template>

<script>
import styled from "vue-styled-components"
import { resetStyles, baseTextStyle, ellipsisStyle } from "./utils"
import { fontSizes } from "@/common/fonts"
import { colors } from "@/common/colors"

const props = {
  ellipsis: {
    type: Boolean,
    default: false,
  },
  align: {
    type: String,
    default: "left",
  },
  noWrap: {
    type: Boolean,
    default: false,
  },
}

const StyledWrapper = styled("div", props)`
  ${resetStyles()};
  ${baseTextStyle()};
  color: ${colors.textPrimary};
  font-size: ${fontSizes.m};
  text-align: ${(props) => props.align};
  ${(props) => (props.ellipsis ? ellipsisStyle() : "")};
  white-space: ${(props) => (props.noWrap ? "nowrap" : "normal")};
`

export default {
  components: {
    StyledWrapper,
  },
  props,
}
</script>
