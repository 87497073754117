<template>
  <div>
    <DesignSystemModal
      v-if="isSummaryModalVisible"
      title="Are you sure you want to redo this section?"
      :handleOnClose="handleCloseSummaryModal"
    >
      <div>
        <StandardText
          >You will have to redo this whole section from the beginning, and we will erase
          the current information provided.</StandardText
        >
      </div>
      <summary-modal-actions-wrapper :isMobile="isMobile">
        <SecondaryAction :onClick="handleCloseSummaryModal">Cancel</SecondaryAction>
        <PrimaryAction :onClick="handleStepSummaryRedo"
          >Yes, I want to redo this section</PrimaryAction
        >
      </summary-modal-actions-wrapper>
    </DesignSystemModal>
    <!-- Mobile View -->
    <styled-modal-toggle
      v-if="isMobile && toggleModal === false"
      data-test-id="modal-prompt"
    >
      <PrimaryAction :onClick="() => handleToggleModalForm(true)">{{
        model.isFileUpload ? 'Upload' : 'Continue'
      }}</PrimaryAction>
    </styled-modal-toggle>
    <div id="modal" class="modal-container" v-if="isMobile && toggleModal === true">
      <div class="modal-body">
        <div class="modal-header">
          <h5 class="modal-title" id="modalTitle" v-if="model.title">
            {{ model.title }}
          </h5>
          <p v-if="model.message">
            {{ model.message }}
          </p>

          <styled-modal-form-close @click="handleToggleModalForm(false)">
            <font-awesome-icon icon="fa-solid fa-xmark" size="xl"></font-awesome-icon>
          </styled-modal-form-close>
        </div>
        <form
          id="modal-form"
          class="modal-content"
          @submit="$event.preventDefault()"
          ref="modalFileForm"
        >
          <div class="modal-content-body">
            <transition name="fade">
              <component
                ref="modalForm"
                class="modal-content-component"
                :is="modalBody"
                :model="model"
                :progress="progress"
                :submit="submit"
                :close="close"
                :hasPassport="hasPassport"
                @changed="onChanged"
                @touched="onTouched"
                @update-form-files="handleUpdateFormFiles"
                @disable-footer-back-button="handleDisableBackButton"
                :overallProgress="overallProgress"
                @update-stepped="updateSteppedProgress"
                :stepped="stepped"
                @enable-stepped-progression="enableProgression"
              ></component>
            </transition>
          </div>
          <div slot="footer" class="modal-content-footer">
            <component
              :model="model"
              :errorText="errorText"
              :is="footerBody"
              :submit="submit"
              :close="close"
              :v="v"
              :stepped="stepped"
              :isLoading="isLoading"
              @update-stepped="updateSteppedProgress"
              @display-summary-modal="handleDisplaySummaryModal"
              :backButtonDisabled="backButtonDisabled"
              :disableSteppedProgression="disableSteppedProgression"
            ></component>
          </div>
        </form>
      </div>
    </div>
    <!-- Desktop & Tablet View || also contains the joruney progress-->
    <div class="sidebar-container" v-if="!isMobile">
      <div>
        <div>
          <h5 id="modalTitle" v-if="model.title">{{ model.title }}</h5>
          <p v-if="model.message">{{ model.message }}</p>
        </div>

        <form @submit="$event.preventDefault()" ref="modalFileForm" id="modal-form">
          <div class="sidebar-body">
            <component
              :is="modalBody"
              class="sidebar-body-content"
              ref="modalForm"
              :model="model"
              :progress="progress"
              :overallProgress="overallProgress"
              :hasPassport="hasPassport"
              :submit="submit"
              :close="close"
              @changed="onChanged"
              @touched="onTouched"
              @update-stepped="updateSteppedProgress"
              @update-form-files="handleUpdateFormFiles"
              @disable-footer-back-button="handleDisableBackButton"
              @enable-stepped-progression="enableProgression"
              :stepped="stepped"
            ></component>
          </div>
          <div slot="footer" class="sidebar-footer">
            <component
              :model="model"
              :errorText="errorText"
              :is="footerBody"
              :isLoading="isLoading"
              :submit="submit"
              :close="close"
              :v="v"
              :stepped="stepped"
              :backButtonDisabled="backButtonDisabled"
              @update-stepped="updateSteppedProgress"
              @display-summary-modal="handleDisplaySummaryModal"
              :disableSteppedProgression="disableSteppedProgression"
            ></component>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
import { mapActions } from 'vuex'
import HouseProgressBar from '@/components/progress/HouseProgressBar'
import { track } from '@/common/utils'

// Modals
import AddressCapture from './AddressCapture'
import BankDetails from './BankDetails'
import BenefitDetails from './BenefitDetails'
import ClassifyTransactions from './ClassifyTransactions'
import FileUpload from './FileUpload'
import MultiFileUpload from './MultiFileUpload'
import JourneyProgress from './JourneyProgress'
import OpenBankingOAuth from './OpenBankingOAuth'
import ProofOfAddress from './ProofOfAddress'
import ProofOfIdentity from './ProofOfIdentity'
import ProofOfIncome from './ProofOfIncome'
import SelectAddress from './SelectAddress'
import SelectBankAccounts from './SelectBankAccounts'
import Selfie from './Selfie'
import SocialMediaOAuth from './SocialMediaOAuth'
import TenantsCapture from './TenantsCapture'
import GuarantorsCapture from './GuarantorsCapture'
import SubjectDetails from './SubjectDetails'
import TermsAndConditions from './TermsAndConditions'
import ConfirmGuarantor from './ConfirmGuarantor'
import CurrencyDetails from './CurrencyDetails'
import RightToRent from './RightToRent'
import Calendar from './Calendar'

// Footers
import DefaultFooter from './Default.Footer'
import OpenBankingOAuthFooter from './OpenBankingOAuth.Footer'
import SocialMediaOAuthFooter from './SocialMediaOAuth.Footer'
import TermsAndConditionsFooter from './TermsAndConditions.Footer'
import ProofOfIdentityFooter from './ProofOfIdentity.Footer'
import ProofOfAddressFooter from './ProofOfAddress.Footer'
import ConfirmGuarantorFooter from './ConfirmGuarantor.Footer'

// extra
import OverallProgress from '../../progress/OverallProgress'
import Loader from '../../progress/Loader'
import IndefiniteLoader from '../../progress/IndefiniteLoader'
import IncomeDetailsSummary from './IncomeDetailsSummary'
import SummaryFooter from './SummaryFooter'
import Modal from '@/components/design-system/Modal'
import StandardText from '@/components/design-system/StandardText'
import styled from 'vue-styled-components'
import { margins } from '@/common/spacing'
import PrimaryAction from '@/components/design-system/PrimaryAction'
import SecondaryAction from '@/components/design-system/SecondaryAction'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import { logErrorToDatadog } from '@/common/utils'

const SummaryModalActionsWrapper = styled('div', { isMobile: Boolean })`
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: center;
  justify-content: flex-end;
  margin-top: ${margins.xl};
  gap: ${margins.m};
`

const StyledModalToggle = styled.div`
  box-sizing: border-box;
  position: fixed;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 85px;
  padding: 20px;
  background: white;
  z-index: 1001;
  animation: button-slide-in 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  border-top: 1px solid rgba(128, 128, 128, 0.26);

  @keyframes button-slide-in {
    0% {
      transform: translateY(85px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
`

const StyledModalFormClose = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
`

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
    overallProgress: {
      type: Object,
      required: true,
    },
  },
  components: {
    DesignSystemModal: Modal,
    IncomeDetailsSummary,
    IncomeDetailsSummaryFooter: SummaryFooter,
    SummaryModalActionsWrapper,
    PrimaryAction,
    SecondaryAction,
    StandardText,
    AddressCapture,
    BankDetails,
    BenefitDetails,
    ClassifyTransactions,
    DefaultFooter,
    FileUpload,
    MultiFileUpload,
    JourneyProgress,
    OpenBankingOAuth,
    OpenBankingOAuthFooter,
    ProofOfAddress,
    ProofOfAddressFooter,
    ProofOfIdentity,
    ProofOfIdentityFooter,
    ProofOfIncome,
    SelectAddress,
    SelectBankAccounts,
    ConfirmGuarantor,
    Selfie,
    SocialMediaOAuth,
    SocialMediaOAuthFooter,
    TenantsCapture,
    GuarantorsCapture,
    SubjectDetails,
    TermsAndConditions,
    TermsAndConditionsFooter,
    HouseProgressBar,
    OverallProgress,
    Loader,
    ConfirmGuarantorFooter,
    CurrencyDetails,
    Calendar,
    RightToRent,
    IndefiniteLoader,
    StyledModalToggle,
    StyledModalFormClose,
  },
  data: function () {
    return {
      defaultFooter: 'default-footer',
      footerBody: this.defaultFooter,
      modalBody: this.model.modal,
      hasPassport: this.model.hasPassport,
      shopOpen: false,
      screenSize: window.innerWidth,
      progress: 0,
      formData: {},
      isLoading: false,
      backButtonDisabled: false,
      fileData: undefined,
      v: { error: false, invalid: false },
      stepped: { current: null, stages: null },
      disableSteppedProgression: true,
      errorText: undefined,
      toggleModal: this.model.modal === 'income-details-summary',
      isSummaryModalVisible: false,
    }
  },
  watch: {
    progress() {
      if (this.progress === 1) this.close()
    },
  },
  computed: {
    isMobile() {
      return this.screenSize < 800
    },
  },
  methods: {
    ...mapActions({
      sendEvent: 'webchat/sendEvent',
    }),
    handleToggleModalForm(toggle) {
      this.toggleModal = toggle
    },
    handleCloseSummaryModal() {
      this.isSummaryModalVisible = false
      track('Click - Summary confirmation modal closed', {
        ...this.model.incomes[this.model.incomes.length - 1].type,
      })
    },
    handleStepSummaryRedo() {
      this.isSummaryModalVisible = false
      track('Click - Summary confirmation modal redo accepted', {
        ...this.model.incomes[this.model.incomes.length - 1].type,
      })
      this.sendEvent({ name: 'step-summary-cancel' })
      this.close()
    },
    handleDisplaySummaryModal() {
      this.isSummaryModalVisible = true
    },
    handleDisableBackButton(shouldDisableBackButton) {
      this.backButtonDisabled = shouldDisableBackButton
    },
    handleUpdateFormFiles(files) {
      /*TODO:
        Refactor all modal submissions to use this file state, $refs are deprecated and may cause update issues with
        the new dynamic list style of uploading multiple documents
      */
      if (files !== undefined && files.length > 0) {
        const data = new FormData()
        files.map(file => {
          data.append('input', file.file, file.name)
        })
        this.fileData = data
      } else {
        this.fileData = undefined
      }
    },
    enableProgression() {
      this.disableSteppedProgression = false
    },
    updateSteppedProgress(data) {
      this.stepped = data
    },
    close() {
      this.$emit('closed', true)
    },
    getWindowWidth(event) {
      this.screenSize = window.innerWidth
    },

    async submit(event) {
      if (event && !event.eventlessSubmit && event.preventDefault) event.preventDefault()

      let serialized = undefined

      if (this.model.isFileUpload) {
        const form = this.$refs.modalFileForm

        const stateFiles =
          this.fileData !== undefined ? this.fileData.getAll('input') : undefined

        const vm = this
        vm.isLoading = true
        vm.errorText = undefined
        const handleError = e => {
          vm.disableSteppedProgression = true
          vm.errorText =
            'Sorry we could not upload the file(s) please refresh the page and try again'
          vm.isLoading = false

          logErrorToDatadog('Document upload error', {
            errorBody: e,
            modal: this.model.modal,
            modalView: this.model.view,
            fileOptions: this.model.fileOptions,
          })
        }

        const handleUploadProgress = progress => {
          if (progress === 1) {
            vm.isLoading = false
          }
          vm.progress = progress
        }
        const modalName = this.model.modal

        // The modal body is only changed after the form is serialized
        serialized = await this.serializeFormWithFiles(
          form,
          handleUploadProgress,
          modalName,
          handleError,
          stateFiles,
        )
        // send data obj instead of above
      } else if (event && event.eventlessSubmit === true) {
        // remove flag which toggles eventlessSubmit and send
        // raw data to bot brain
        delete event.eventlessSubmit
        this.progress = 1
        serialized = event
      } else {
        serialized = this.$refs.modalForm.$data
        this.progress = 1
      }

      this.formData = {}
      if (this.modalBody === 'income-details-summary') {
        if (Object.keys(serialized).length > 0) {
          // TODO refactor this empty state, refactor use of "serialized" as a working variable
          this.sendEvent({ name: 'step-summary-confirm' })
        }
      } else {
        if (Object.keys(serialized).length > 0) {
          this.sendEvent({ name: 'form', value: serialized })
        }
      }
    },
    onTouched(v) {
      this.v = v
    },
    onChanged(formData) {
      this.formData = formData
    },
    kebabToPascal(str) {
      if (str.indexOf('-') > -1) {
        str += ''
        str = str.split('-')
        for (var i = 0; i < str.length; i++) {
          str[i] = str[i].slice(0, 1).toUpperCase() + str[i].slice(1, str[i].length)
        }
        return str.join('')
      } else {
        return str
      }
    },
  },
  mounted() {
    const footerName = this.kebabToPascal(this.modalBody) + 'Footer'
    const footer = this.$options.components[footerName]
    this.footerBody = footer ? footerName : this.defaultFooter
    window.addEventListener('resize', this.getWindowWidth)
  },
  destroyed() {
    window.removeEventListener('resize', this.getWindowWidth)
  },
}
</script>

<style scoped lang="less">
.modal-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  animation: fadeIn 0.35s ease-in-out 0s forwards 1;
}

@media only screen and (max-width: 800px) {
  .modal-content {
    max-height: 75vh;
    padding: 0;
  }
}

.modal-message {
  padding: 0 15px;
}
.modal-body {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: translate(-50%, -50%);
  background: white;
  margin: 0 auto;
  border-radius: 15px 15px 0 0;
  -webkit-animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  max-width: 650px;
  width: 100%;
  max-width: 650px;
  background-color: #ffffff;
  text-align: center;
  color: #55616b;
  overflow-y: scroll;
}

#modal-form {
  display: grid;
  grid-template-rows: 1fr min-content;
  height: 100%;
  grid-template-columns: 1fr;
}

.modal-content-component {
  height: 100%;
  max-height: 85vh;
  padding: 14px;
  overflow-y: auto;
}

.modal-content-footer {
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 15px;
}

.modal-body h2 {
  /* color: white; */
  color: #55616b;
}

@keyframes fadeIn {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.45);
  }
}

@-webkit-keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(-1000px);
    transform: translateY(-1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-top {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

.modal-body h2 {
  color: white;
}

@keyframes fadeIn {
  from {
    background: rgba(0, 0, 0, 0);
  }
  to {
    background: rgba(0, 0, 0, 0.45);
  }
}

.sidebar-container {
  display: grid;
  z-index: -1000;
  animation: unset;
  position: block;
  padding: 1rem;
  margin: 1rem;
  background: white;
  height: 95vh;
  border-radius: 10px;
  -webkit-animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-in-right 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  box-shadow: 0 0 8px 2px rgba(100, 100, 100, 0.25);

  > div:nth-child(1) {
    display: grid;
    grid-template-rows: min-content 1fr;
  }
}

.sidebar-body {
  max-height: 75vh;
}

.sidebar-body-content {
  overflow: auto;
  max-height: 75vh;
  &::-webkit-scrollbar-thumb {
    background: lightgrey;
    border-radius: 20px;
  }
  &::-webkit-scrollbar {
    height: 10px;
    width: 12px;
  }
}

.sidebar-footer {
  padding: 1rem;
  bottom: 0;
  right: 0;
  align-self: flex-end;
  margin: 0;
}

@-webkit-keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes slide-in-right {
  0% {
    -webkit-transform: translateX(1000px);
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
}

.modal-header {
  padding: 0 15px;
}
</style>
