<template>
    <div class="input-wrapper">
        <label for="type">Please select an address</label>
        <select 
            name="id"
            id="addresses"
            class="input w-select"
            v-model="id"
            @change="validate"
        >
            <option value="" disabled>Select an option...</option>
            <option 
                v-for="address in model.addresses" 
                :key="address.id"
                :value="address.id"
            >
                {{ getCommaSeparatedAddress(address) }}
            </option>
        </select>
        <p v-if="$v.id.$error">This field is required!</p>
    </div>
</template>

<script>
    import { formMixin } from '@/mixins/formMixin';
    import { required } from 'vuelidate/lib/validators';

    export default {
        mixins: [formMixin],
        props: {
            model: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                id: ''
            };
        },
        validations: {
            id: { required }
        },
        methods: {
            getCommaSeparatedAddress(address) {
                return [
                    address.nameOrNumber,
                    this.getAddressPart(address.line1, ' '),
                    this.getAddressPart(address.line2),
                    this.getAddressPart(address.city),
                    this.getAddressPart(address.postcode)
                ].join('');
            },
            getAddressPart(part, prefix = ', ') {
                return part !== undefined && part !== '' ? `${prefix}${part}` : '';
            },
        }
    }
</script>
<style lang="less" scoped>

.w-input, .w-select  {
    margin: 0;
    padding: 0 10px;
    border-radius:10px;
}

.w-input:focus, .w-select:focus {
    border-color: var(--mainHighlight);
    outline: 0;
}

label {
    padding: 20px 0;
    font-weight:normal;
    font-family: 'Lato',sans-serif;
    // font-size: 14px;
    color: #56616c
}
</style>