import Vue from 'vue';
import Vuex from 'vuex';

import storage from './modules/storage';
import user from './modules/user';
import webchat from './modules/webchat';

Vue.use(Vuex);

export default new Vuex.Store({
    modules: { storage, user, webchat }
});