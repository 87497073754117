
import { Vue, Component } from "vue-property-decorator";
import Chat from "./Chat.vue";
import Modal from "./modals/Modal.vue";
const styled = require("vue-styled-components");
const { ThemeProvider } = require("vue-styled-components");
import EventLogic, { EventBus } from "../../events/EventLogic.vue";
import { checkHasComplete, logInfoToDatadog } from "@/common/utils";
import IndefiniteLoaderVue from "../progress/IndefiniteLoader.vue";
import MobileProgressBar from "../progress/MobileProgressBar/ProgressBar.vue"

const { SimpleSVG } = require("vue-simple-svg");

const WebChatWrapper = styled.default.div`
  height: 100%;
  width: 100%;
  #help-icon{
    display: ${(props: any) =>
      props.theme.default.agentBot ? "none" : ""} !important;
  }
  #overall-progress {
    display: ${(props: any) =>
      props.theme.default.agentBot ? "none" : ""} !important;
  }
  .chat-and-sidebar{
    display: ${(props: any) =>
      props.theme.default.agentBot ? "block" : ""} !important;
      height: 100%;
    grid-template-columns: ${(props: any) =>
      props.theme.default.agentBot ? "1fr" : "1fr 1fr"};

      .chat-and-swipe{
        height: 100%;
      }
  }
`;

@Component({
  components: {
    Chat,
    Modal,
    EventLogic,
    WebChatWrapper,
    ThemeProvider,
    SimpleSVG,
    IndefiniteLoaderVue,
    MobileProgressBar
  },
})
export default class WebChat extends Vue {
  public iconUrl: string = process.env.VUE_APP_ASSETS || "";
  public showModal: boolean = false;
  public screenSize: number = window.innerWidth;
  public open: boolean = false;
  public hasCompletedJourneyChecks: boolean = false;
  public journeyCompleted: boolean = false;
  public userAvatar: string = "bot/user-avatar.svg";

  public model: object = {};

  public sendBox: any = document.getElementsByClassName(
        "webchat__send-box-text-box__input"
      )[0];

  public themeOptions: any = {
    default: {
      mainColor: "",
      secondaryColor: "",
      mainBtnColor: "",
      secondaryBtnColor: "",
      defaultAccent: "",
      fontColor: "",
      suggestedActionHighlight: "",
      bubbleFromUserTextColor: "",
      avatarSize: "",
      suggestedActionBorderRadius: "",
      suggestedActionFontWeight: "",
      sendBoxBackground: "",
      botAvatarImage: "",
      userAvatarImage: "",
      sendMessageColour: "",
      agentBot: false,
    },
  };

  public progress: { recommendations: any[]; flows: object } = {
    recommendations: [],
    flows: {},
  };

  public triggerModal() {
    this.open = !this.open;

    const sendBox: any = document.getElementsByClassName(
      "webchat__send-box-text-box__input"
    )[0];
    if (sendBox !== undefined) {
      sendBox.disabled = true;
    }
  }
  public closed() {
    this.model = {};
    this.showModal = false;
    if (this.sendBox !== undefined) {
      this.sendBox.disabled = false;
    }
  }
  created() {
    const themeCopy = { ...this.themeOptions };
    if (this.$route.query.src) {
      themeCopy.default.agentBot = true;
    }
    // upgrade this to use a cooler bind() method
    //const self = this;
    function updateModel(this: WebChat, payload: any) {
      (this as WebChat).model = { ...payload };
      const ALLOW_MESSAGING_MODALS = ["open-banking-o-auth"];
      this.showModal = true;
      

      if (!ALLOW_MESSAGING_MODALS.includes(payload.modal) && this.sendBox !== undefined) {
        this.sendBox.disabled = true;
      }
    }
    function updateScreen(this: WebChat, payload: number) {
      this.screenSize = payload;
    }

    function themeUpdate(this: WebChat, payload: any) {
      const {
        primaryActive,
        botAvatarImage,
        secondaryActive,
        mainBodyText,
        primaryHeaderText,
        secondaryHeaderText,
        mainHighlight,
        secondaryHighlight,
        warningHighlight,
      } = payload.color;

      document.documentElement.style.setProperty(
        "--botAvatar",
        `url(${botAvatarImage})`
      );

      document.documentElement.style.setProperty(
        "--mainHighlight",
        `${primaryActive}`
      );

      document.documentElement.style.setProperty(
        "--mainSecondary",
        `${secondaryActive}`
      );

      document.documentElement.style.setProperty(
        "--primaryHeaderText",
        `${primaryHeaderText}`
      );

      document.documentElement.style.setProperty(
        "--secondaryHeaderText",
        `${secondaryHeaderText}`
      );

      document.documentElement.style.setProperty(
        "--secondaryHighlight",
        `${secondaryHighlight}`
      );

      document.documentElement.style.setProperty(
        "--warningHighlight",
        `${warningHighlight}`
      );

      const favicon: any = document.getElementById("favicon");

      if (favicon) {
        favicon.href = botAvatarImage;
      }

      const newTheme = {
        default: {
          mainColor: primaryActive,
          mainBtnColor: primaryActive,
          defaultAccent: mainHighlight,
          botAvatarImage: botAvatarImage,
          secondaryColor: secondaryActive,
          secondaryBtnColor: secondaryActive,
          fontColor: mainBodyText,
          primaryHeaderText: primaryHeaderText,
          secondaryHeaderText: mainBodyText,
          bubbleFromUserTextColor: "white",
          sendMessageColour: secondaryActive,
          bubbleBackground: mainHighlight,
          userBubbleBackground: secondaryHighlight,
        },
      };

      this.themeOptions = Object.assign(
        { ...this.themeOptions },
        { ...themeCopy },
        { ...newTheme }
      );
    }

    function updateProgress(
      this: WebChat,
      payload: { flows: object; recommendations: any[]; userAvatar: string }
    ) {
      if (!this.hasCompletedJourneyChecks) {
        this.hasCompletedJourneyChecks = true
        const progress = JSON.parse(JSON.stringify(payload))
        const completed = checkHasComplete(progress)
        this.journeyCompleted = completed
        if (completed) {
          logInfoToDatadog("User visited bot in completed state", progress)
        }
      }
      this.progress.flows = payload.flows;
      this.progress.recommendations = payload.recommendations;
      this.userAvatar = payload.userAvatar;
    }

    function hideModal(this: WebChat) {
      this.closed();
    }

    function configUpdate(this: WebChat, payload: any) {
      delete payload.themes;
      const copy = { ...this.themeOptions };
      copy.config = { ...payload };
      this.themeOptions = { ...copy };
    }

    EventBus.$on("MODEL_UPDATE", updateModel.bind(this));
    EventBus.$on("SCREEN_SIZE_CHANGE", updateScreen.bind(this));
    EventBus.$on("PROGRESS_UPDATE", updateProgress.bind(this));
    EventBus.$on("THEME_UPDATE", themeUpdate.bind(this));
    EventBus.$on("HIDE_MODAL", hideModal.bind(this));
    EventBus.$on("CONFIG_UPDATE", configUpdate.bind(this));
  }
}
