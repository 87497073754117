const shadowDepths = {
  1: "0px 1px 3px rgba(0,0,0,0.15)",
  2: "0px 3px 20px 0 rgba(0,0,0,0.2)",
  3: "0px 1px 12px rgba(0, 0, 0, 0.15)",
}

export const shadows = {
  topBar: "0px 0px 10px 0px rgba(0,0,0,0.15)",
  button: "3px 3px 10px 0 rgba(0, 0, 0, 0.1)",
  actionHover: "0px 4px 10px rgba(0, 0, 0, 0.1)",
  actionActive: "inset 0px 3px 3px rgba(0, 0, 0, 0.08)",
  card: shadowDepths[1],
  cardHover: shadowDepths[2],
  selectOptions: shadowDepths[3],
}
