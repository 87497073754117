
import { Vue, Component, Prop } from "vue-property-decorator"

const styled = require("vue-styled-components")

const FlowActionWrapper = styled.default("div")`
  .complete {    
background: ${(props: any) =>
  props && props.theme && props.theme.default && props.theme.default.mainColor
    ? props.theme.default.mainColor
    : "#34bebd"} !important;
  }

.awaiting {
  background: var(--warningHighlight);
}
`

@Component({
  components: {
    FlowActionWrapper,
  },
})
export default class FlowStages extends Vue {
  @Prop({ type: String })
  public rounded!: string

  @Prop({ type: Object })
  public flows!: any

  @Prop({ type: String, default: "" })
  public title!: string

  get findFlow() {
    return this.flows[this.title]
  }

  public convertToTitle(unformatted: string = "") {
    return unformatted
      .replace(/([A-Z])/g, " $1")
      .split("")
      .join("")
  }
}
