<template>
  <styled-wrapper>
    <styled-icon-wrapper>
      <font-awesome-icon icon="fa-solid fa-info-circle" color="#2F80ED" />
    </styled-icon-wrapper>
    <styled-content-wrapper>
      <H6 v-if="title">{{ title }}</H6>
      <slot />
    </styled-content-wrapper>
  </styled-wrapper>
</template>

<script>
import styled from "vue-styled-components"
import { baseAlertStyle, resetStyles } from "./utils"
import { colors } from "@/common/colors"
import { margins } from "@/common/spacing"
import H6 from "./H6.vue"
import { flexColumn, flexRow } from "@/common/flex"

const StyledWrapper = styled.div`
${resetStyles()}
${baseAlertStyle()}
${flexRow({})}
border-color: ${colors.blue};
`

const StyledIconWrapper = styled.div`
  margin-right: ${margins.s};
`

const StyledContentWrapper = styled.div`
  ${flexColumn()}
`

export default {
  props: {
    title: String,
  },
  components: {
    H6,
    StyledIconWrapper,
    StyledWrapper,
    StyledContentWrapper,
  },
}
</script>
