<template>
    <div>
        <div class="input-wrapper">
            <label class="field-label" for="account">Account number</label>
            <input 
                type="text" 
                name="account" 
                id="account" 
                class="input text-input w-input" 
                v-model="account"
                @keyup="validate"
            >
            <p v-if="$v.account.$error">This field is required!</p>
        </div>
        <div class="input-wrapper">
            <label class="field-label" for="sortCode">Sort code</label>
            <input 
                type="text" 
                name="sortCode" 
                id="sortCode" 
                class="input text-input w-input" 
                v-model="sortCode"
                @keyup="validate"
            >
            <p v-if="$v.sortCode.$error">This field is required!</p>
        </div>
        <div class="input-wrapper">
            <label class="field-label" for="iban">IBAN</label>
            <input 
                type="text" 
                name="iban" 
                id="iban" 
                class="input text-input w-input" 
                v-model="iban"
                @keyup="validate"
            >
            <p v-if="$v.iban.$error">This field is required!</p>
        </div>
    </div>
</template>

<script>
    import { formMixin } from '@/mixins/formMixin';
    import { required } from 'vuelidate/lib/validators';

    export default {
        mixins: [formMixin],
        props: {
            model: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                account: this.model.account,
                iban: this.model.iban,
                sortCode: this.model.sortCode
            };
        },
        validations: {
            account: { required },
            iban: { required },
            sortCode: { required }
        }
    }
</script>