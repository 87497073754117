
/*
EventBus asks as the component completely disjoined from the DOM. it is a dummy component to purely
store and retrieve data.

EventLogic, stores the logic and helps to mutate and retrieve from EventBus
*/
import Vue from "vue";
export const EventBus = new Vue();
// packages
import { mapGetters, mapActions } from "vuex";
import { Component } from "vue-property-decorator";
// types
import { Recommendation, FlowItem } from "../types/types";

@Component({
  computed: {
    ...mapGetters({
      botConnection: "webchat/botConnection",
      store: "webchat/store",
      token: "webchat/token",
    }),
  },
  methods: {
    ...mapActions({
      registerActivityCallback: "webchat/registerActivityCallback",
      sendEvent: "webchat/sendEvent",
      sendMessage: "webchat/sendMessage",
      expireToken: "user/expireToken",
    }),
  },
})
export default class EventLogic extends Vue {
  // getters
  botConnection!: any;
  store!: any;
  token!: any;
  // methods
  registerActivityCallback!: any;
  sendEvent!: any;
  sendMessage!: any;
  expireToken!: any;

  public messageTimeout: any;
  public model: object = {};
  public theme: any = {};
  public config: object = {};
  public showModal: boolean = false;
  public screenSize: number = window.innerWidth;
  public open: boolean = false;
  public progress: {
    recommendations: Recommendation[];
    flows: FlowItem[];
  } = { recommendations: [], flows: [] };

  public getProgress(): void {
    //const progress = JSON.parse(JSON.stringify(this.progress));
    //console.log(progress);
    EventBus.$emit("PROGRESS_UPDATE", this.progress);
  }
  public getModel(): void {
    EventBus.$emit("MODEL_UPDATE", this.model);
  }
  public getTheme(): void {
    EventBus.$emit("THEME_UPDATE", this.theme);
  }
  public getConfig(): void {
    EventBus.$emit("CONFIG_UPDATE", this.config);
  }
  public getScreenSize(): void {
    EventBus.$emit("SCREEN_SIZE_CHANGE", this.screenSize);
  }
  public getWindowWidth(): void {
    this.screenSize = window.innerWidth;
    this.getScreenSize();
  }
  created() {
    //var vm = this;
    const modalActivityCallback = async (activity: any) => {
      const { name, type, value } = activity;
      if (type === "event") {
        if (name === "conversation.update") {
          this.sendEvent({ name: "client.connected" });
        } else if (name === "conversation.stop") {
          await this.expireToken();
          this.$router.replace({ name: "login" });
        }else if (name === "conversation.subject.does.not.exist") {
          await this.expireToken();
          this.$router.replace({ name: "complete" });
        } else if (name === "input.mode") {
          document.querySelectorAll(
            "[data-id=webchat-sendbox-input]"
            // @ts-ignore
          )[0].inputMode = value;
        } else if (name && name.search(/inactivity.message/gim) === 0) {
          if (name.search(/schedule/gim) > 0) {
            this.messageTimeout = setTimeout(
              () => this.sendEvent({ name: "inactivity.message.show" }),
              30000
            );
          } else if (name.search(/cancel/gim) > 0) {
            clearTimeout(this.messageTimeout);
          }
        } else if (name && name.search(/oauth/gim) === 0) {
          if (name.search(/cancel/gim) > 0) {
            // EventBus.$emit("HIDE_MODAL"); TODO: ADD BACK IN WHEN CANCELLABLE ACTIONS ARE WORKING
          }
        } else if (value && value.type && value.type === "ShowModal") {
          this.model = { ...value };
          this.showModal = true;
          this.getModel();
        } else if (name === "progressUpdate") {
          const { recommendations, flows } = value;
          if (!recommendations || !flows) {
            throw new Error("Invalid progress object! Cannot update UI.");
          } else {
            this.progress = { ...value };
            this.getProgress();
          }
        } else if (name === "themeAndConfigUpdate") {
          this.theme = { ...(value.themes[0] || {}) };
          this.config = { ...value };
          this.getConfig();
          this.getTheme();
        }
      }
    };
    this.registerActivityCallback(modalActivityCallback);
    window.addEventListener("resize", this.getWindowWidth);
  }
  render() {
    // gets rid of console error, needs to be defined even if there is nothing to render
    return null
  }
  destroyed() {
    window.removeEventListener("resize", this.getWindowWidth);
  }
}
