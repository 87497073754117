import { Component, Vue } from "vue-property-decorator";

@Component({})
export default class AssetMixin extends Vue {
  public flowToIcon(flow: string): string {
    switch (flow.toLowerCase()) {
      case "identity":
        return "identity.svg";
      case "financial":
      case "income":
        return "financial.svg";
      case "employment":
        return "employment.svg";
      case "residential":
        return "residential.svg";
      case "adversecredit":
        return "credit_search.svg";
      case "guarantorconsent":
        return "guarantor.svg";
      case "additionaldetails":
        return "information.svg";
      default:
        return `${flow.toLowerCase()}.svg`;
    }
  }
}
