
import { Component, Vue, Prop } from "vue-property-decorator";

import ControlElement from "./ControlElement.vue";

@Component({ components: { ControlElement } })
export default class TextBoxElement extends Vue {
  @Prop({ type: String, default: "" })
  public value!: string;
  public manualAddressModal: Boolean = false;

  public focusedPostCode: Boolean = false;

  toggleFocusedPostCode() {
    this.focusedPostCode = !this.focusedPostCode;
  }

  emitInput(event: Event) {
    this.$emit("inputEvent", event);
    const element = event.target as HTMLInputElement;
    this.$emit("input", element.value);
  }

  public get inputType(): string {
    if (this.numeric) {
      return "number";
    } else if (this.url) {
      return "url";
    } else if (this.email) {
      return "email";
    } else if (this.phone) {
      return "tel";
    } else {
      return "text";
    }
  }

  @Prop({ type: String, default: "" })
  public id!: string;

  @Prop({ type: String, default: "" })
  public name!: string;

  @Prop({ type: String, default: "" })
  public label!: string;

  @Prop({ type: Object, default: undefined })
  public validateRules!: any;

  @Prop({ type: Boolean })
  public required!: boolean;

  @Prop({ type: Boolean })
  public disabled!: boolean;

  @Prop({ type: Boolean })
  public email!: boolean;

  @Prop({ type: Boolean })
  public phone!: boolean;

  @Prop({ type: Boolean })
  public url!: boolean;

  @Prop({ type: Boolean })
  public numeric!: boolean;

  @Prop({ type: String })
  public regex!: string;

  @Prop({ type: Boolean })
  public multiline!: boolean;

  @Prop({ type: Boolean })
  public search!: boolean;

  @Prop({ type: Function })
  public searchCallback!: Array<any>;

  @Prop({ type: Array })
  public addressesFound?: Array<any>;

  @Prop({ type: String })
  public errorMessage!: string;

  @Prop({ type: Boolean })
  public centre!: boolean;

  @Prop({ type: Function })
  public triggerModal!: void;

  @Prop({ type: Boolean, default: false })
  public focusItem!: boolean;

  @Prop({ type: Function })
  public findAddress!: void;

  @Prop({ type: Boolean, default: false })
  public autocomplete!: boolean;

  @Prop({ type: Boolean })
  public addressFilled!: boolean;
}
