// Components
import Webchat from '@/components/webchat/Webchat.vue'
import GenerateToken from '@/components/auth/GenerateToken.vue'
import VerifyToken from '@/components/auth/VerifyToken.vue'
import UnsupportedBrowser from '@/components/UnsupportedBrowser.vue'
import SubjectDoesNotExist from '@/components/SubjectDoesNotExist.vue'

export default [
  {
    path: '/',
    component: Webchat,
    name: 'chat',
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    component: GenerateToken,
    name: 'login',
  },
  {
    path: '/verify',
    component: VerifyToken,
  },
  {
    path: '/unsupported-browser',
    component: UnsupportedBrowser,
    meta: { requiresAuth: false },
  },
  {
    path: '/complete',
    component: SubjectDoesNotExist,
    meta: { requiresAuth: false },
    name: 'complete',
  },
]
