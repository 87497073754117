const spaces = {
  xxs: "5px",
  xs: "10px",
  s: "15px",
  m: "20px",
  l: "30px",
  xl: "40px",
};

export const margins = spaces;

export type Margin = keyof typeof margins;

export const paddings = spaces;

export type Padding = keyof typeof paddings;

export const gaps = spaces;

export type Gap = keyof typeof gaps;
