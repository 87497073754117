
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

import ProgressMenuItem from "./ProgressMenuItem.vue";
import FlowStages from "./FlowStages.vue";
import Recommendations from "./Recommendations.vue";
import ShopSwitcher from "../ShopSwitcher.vue";
const styled = require("vue-styled-components");

const StoreSwitchWrapper = styled.default.div`
  .menu{
    display: ${(props: any) =>
      props.theme.config.dashboardSettings.hideStore === true
        ? "none"
        : ""} !important;
  }
`;

@Component({
  components: {
    ProgressMenuItem,
    FlowStages,
    Recommendations,
    ShopSwitcher,
    StoreSwitchWrapper,
  },
})
export default class OverallProgress extends Vue {
  @Prop({ type: Object })
  public progress: any;

  public shopOpen: boolean = false;

  @Prop({ type: Object })
  public themeOptions: any;

  public activeTab: string = window.innerWidth > 800 ? "identity" : "";

  public getShopUrl(): string {
    if (this.themeOptions.config.dashboardSettings.customStoreUrl) {
      return this.themeOptions.config.dashboardSettings.customStoreUrl;
    }

    return "https://store.vouch.co.uk?src=bot";
  }

  public showShopOrProgress(view: string) {
    if (view === "shop") {
      this.shopOpen = true;
    } else {
      this.shopOpen = false;
    }
  }

  public swipeHandler(direction: string, e: any) {
    const MENU_ITEMS = ["identity", "residential", "employment", "financial"];
    const index = MENU_ITEMS.indexOf(this.activeTab);
    if (direction === "left") {
      if (index === MENU_ITEMS.length - 1) {
        this.activeTab = MENU_ITEMS[0];
      } else {
        this.activeTab = MENU_ITEMS[index + 1];
      }
    } else if (direction === "right") {
      if (index === 0) {
        this.activeTab = MENU_ITEMS[MENU_ITEMS.length - 1];
      } else {
        this.activeTab = MENU_ITEMS[index - 1];
      }
    }
  }

  // uses alt tag to navigate the elements
  public setActive(item: string) {
    if (item === undefined || !item) return;
    if (item === this.activeTab) {
      this.activeTab = "";
      return;
    }
    this.activeTab = item;
  }

  @Watch("progress.flows", { deep: true })
  flowsChanged() {
    this.getTab();
  }
  public getTab() {
    const MENU_ITEMS = Object.keys(this.progress.flows);
    const index = MENU_ITEMS.indexOf(this.activeTab);
    if (
      this.progress.flows[this.activeTab] !== undefined &&
      this.progress.flows[this.activeTab].completed === true &&
      index !== MENU_ITEMS.length - 1
    ) {
      this.activeTab = MENU_ITEMS[index + 1];
    }
  }
  public getPercentComplete(key: string) {
    let currFlow = this.progress.flows[key];
    if (!currFlow) {
      return 0;
    }
    const actionsToComplete = currFlow.actions.filter(
      ({ completed }: any) => completed === false
    );
    if (actionsToComplete.length === 0) {
      return 100;
    }

    return 100 - (actionsToComplete.length / currFlow.actions.length) * 100;
  }

  mounted() {
    this.getTab();
  }
}
