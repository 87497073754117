

import { Vue, Prop } from "vue-property-decorator";

export default class ShopSwitcher extends Vue {
  @Prop({ type: Boolean })
  public shopOpen!: boolean;

  @Prop({ type: Object })
  public themeOptions: any;

  @Prop({ type: Function })
  public showShopOrProgress!: Function;
}
