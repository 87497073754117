<template>
  <div class="open-banking">
    <h5>{{ this.model.text }}</h5>
    <img :src="`/assets/img/${model.providerLogo}`" />
    <br />
    <a
      class="btn btn-primary"
      style="width:240px;"
      :href="`${model.baseUrl}${model.endpoint}`"
      target="_blank"
      @click="close"
      id="openBank"
    >
      Connect with Open Banking
    </a>
    <br />
    <p>
      {{ this.model.description }}
    </p>
    <p>
      <img
        src="/assets/img/greentick.png"
        style="vertical-align:middle;width:20px;height:20px;"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ this.model.bullet1 }}
    </p>
    <p>
      <img
        src="/assets/img/greentick.png"
        style="vertical-align:middle;width:20px;height:20px;"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ this.model.bullet2 }}
    </p>
    <p>
      <img
        src="/assets/img/greentick.png"
        style="vertical-align:middle;width:20px;height:20px;"
      />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ this.model.bullet3 }}
    </p>
    <div class="ob-help-container" @click="handleHelpClicked">
      <em><p>Having trouble? Click here</p></em>
    </div>
  </div>
</template>

<script>
import { track } from '@/common/utils';
import { mapActions } from "vuex";

export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  mounted(){
    this.registerActivityCallback((activity) => {
      if (activity.text === "help") {
        this.close()
      }
    })
  },
  methods: {
    ...mapActions({
      registerActivityCallback: "webchat/registerActivityCallback",
      sendMessage: "webchat/sendMessage",
    }),
    handleHelpClicked() {
      track("Click - Open banking help", {})
      this.sendMessage({
        message: "help",
      });
      this.close();
    },
  },
};
</script>

<style lang="less">
#openBank {
  display: block;
  margin: 0 auto;
}

.ob-help-container {
  text-decoration: underline;
}

.ob-help-container:hover {
  cursor: pointer;
}

.open-banking {
  margin: 0 auto;
  text-align: center;
}

.open-banking img {
  max-width: 250px;
  margin: 0 auto;
}
h5 {
  text-align: center;
}
#modalTitle {
  display: none !important;
}
</style>
