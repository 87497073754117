interface FlexboxOptions {
  direction: "row" | "row-reverse" | "column" | "column-reverse"
  justifyContent?:
    | "flex-start"
    | "flex-end"
    | "start"
    | "end"
    | "left"
    | "right"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
  alignItems?: "stretch" | "flex-start" | "flex-end" | "center" | "baseline"
  alignContent?:
    | "flex-start"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
    | "stretch"
  wrap?: "nowrap" | "wrap" | "wrap-reverse"
  grow?: number
}

export const flexBox = (options: FlexboxOptions) => `
  display: flex;
  ${
    options
      ? `
      ${options.direction ? `flex-direction: ${options.direction};` : ""}
      ${
        options.justifyContent
          ? `justify-content: ${options.justifyContent};`
          : ""
      }
      ${options.alignItems ? `align-items: ${options.alignItems};` : ""}
      ${options.alignContent ? `align-content: ${options.alignContent};` : ""}
      ${options.wrap ? `flex-wrap: ${options.wrap};` : ""}
      ${options.grow ? `flex-grow: ${options.grow};` : ""}
    `
      : ""
  }
`

export const flexRow = (options?: Omit<FlexboxOptions, "direction">) =>
  flexBox({
    ...options,
    direction: "row",
  })

export const flexColumn = (options?: Omit<FlexboxOptions, "direction">) =>
  flexBox({
    ...options,
    direction: "column",
  })
