import axios from '@/axios/chatbot'
import { generateToken } from './user/actions'
import { logErrorToDatadog, logWarningToDatadog, windowRef } from '@/common/utils'
import { AxiosError } from 'axios'

interface GettersAndPayload {
  getters: any
  payload: any
}

export interface CommitAndDispatch {
  commit: any
  dispatch: any
}

interface User {
  id: string
  applicationId: string
  customerId: string
}

interface TokensData {
  api: {
    token: string
  }
  blob: {
    container: string
    uri: string
    sas: string
  }
  directLine: {
    conversationId: string
    token: string
    expires_in: number
    user: string
  }
  user: User
}

interface State {
  tokenGenerationStatus: number
  user: any
  tokenVerificationStatus: number
  isAuthenticated: boolean
  tempToken?: string
  tempRedirect?: string
  jwtToken?: string
}

const state: State = {
  user: {},
  tokenGenerationStatus: 0,
  tokenVerificationStatus: 0,
  isAuthenticated: false,
  tempToken: undefined,
  tempRedirect: undefined,
  jwtToken: undefined,
}

const mutations = {
  email(state: State, payload: GettersAndPayload) {
    state.user = { ...state.user, email: payload }
  },
  tokenGenerationStatus(state: State, payload: number) {
    state.tokenGenerationStatus = payload
  },
  tokenVerificationStatus(state: State, payload: number) {
    state.tokenVerificationStatus = payload
  },
  isAuthenticated(state: State, payload: boolean) {
    state.isAuthenticated = payload
  },
  tempToken(state: State, payload: string) {
    state.tempToken = payload
  },
  jwtToken(state: State, payload: string) {
    state.jwtToken = payload
  },
  tempRedirect(state: State, payload: string) {
    state.tempRedirect = payload
  },
  user(state: State, payload: User) {
    state.user = payload
  },
}

const linkSubjectDetailsToHotJar = ({
  subjectId,
  applicationId,
  customerId,
}: {
  subjectId: string
  applicationId: string
  customerId: string
}) => {
  if (windowRef.hj) {
    windowRef.hj('identify', subjectId, {
      customerId,
      applicationId,
    })
  }
}

const actions = {
  async expireToken({ commit, dispatch }: CommitAndDispatch) {
    dispatch('webchat/unregisterWebchat', undefined, { root: true })
    dispatch('storage/unregisterStorage', undefined, { root: true })
    await axios().get('/tokens/expire')
    commit('isAuthenticated', false)
    commit('user', {})
  },
  generateToken,
  async verifyToken(
    { commit, dispatch }: CommitAndDispatch,
    { token, cId }: { token: string; cId?: string },
  ) {
    let response = undefined
    /* 
      If agent is connecting to the bot, post to the agent route
    */
    commit('jwtToken', token)

    /* dispatch("jwtToken", token); */
    if (cId && cId.trim() !== '') {
      try {
        response = await axios(undefined, {
          authorization: `Bearer ${token}`,
        }).post(`/tokens/agent`, {
          customerId: cId,
        })
        commit('tempToken', undefined)
        dispatch('setTokens', response.data)
      } catch (err) {
        const error = err as AxiosError | Error
        if ('response' in error) {
          logErrorToDatadog('Failed to verify token with cId', {
            errorBody: error.response,
          })
          response = error.response
        } else {
          logErrorToDatadog('Unexpected error trying to verify token with cId', {
            errorBody: error,
          })
        }
      }
    } else {
      try {
        response = await axios(token).get(`/tokens/verify`)

        const { id: subjectId, customerId, applicationId } = response.data.user

        linkSubjectDetailsToHotJar({ applicationId, customerId, subjectId })

        commit('tempToken', undefined)
        dispatch('setTokens', response.data)
      } catch (err) {
        const error = err as AxiosError | Error
        if ('response' in error) {
          logErrorToDatadog('Failed to verify token', { errorBody: error.response })
          response = error.response
        } else {
          logErrorToDatadog('Unexpected error trying to verify token', {
            errorBody: error,
          })
        }
      }
    }
  },
  setTokens({ commit, dispatch }: CommitAndDispatch, tokens: TokensData) {
    dispatch('storage/registerStorage', tokens.blob, { root: true })
    dispatch(
      'webchat/registerWebchat',
      { directLine: tokens.directLine, api: tokens.api, user: tokens.user },
      { root: true },
    )
    commit('isAuthenticated', true)
  },
  async tryAuthenticate({ getters, dispatch }: any) {
    if (!getters.isAuthenticated) {
      try {
        const response = await axios().get('/tokens')
        dispatch('setTokens', response.data)
      } catch (err) {
        /** 401 throws an exception. Just catch it to allow the application to continue. */
        // eslint-disable-next-line no-console
        console.warn('TOKEN_FROM_COOKIE_INVALID ' + err)
        logWarningToDatadog('Invalid token from cookie', { errorBody: err as Error })
      }
    }
  },
}

const getters = {
  tokenGenerationStatus(state: State) {
    return state.tokenGenerationStatus
  },
  tokenVerificationStatus(state: State) {
    return state.tokenVerificationStatus
  },
  tempToken(state: State) {
    return state.tempToken
  },
  tempRedirect(state: State) {
    return state.tempRedirect
  },
  jwtToken(state: State) {
    return state.jwtToken
  },
  isAuthenticated(state: State, getters: any, rootState: any) {
    const webchat = rootState.webchat
    const storage = rootState.storage
    return (
      state.isAuthenticated &&
      webchat.conversation !== undefined &&
      webchat.userId !== undefined &&
      webchat.token !== undefined &&
      storage.uri !== undefined &&
      storage.container !== undefined &&
      storage.sas !== undefined
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
