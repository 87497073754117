<template>
  <span v-if="currentFlowName">
    <styled-wrapper>
      <styled-flow-details-wrapper>
        <FlowDetails
          :currentFlowName="currentFlowName"
          :currentFlowStage="currentFlowStage"
          :currentFlowIcon="currentFlowIcon"
        />
        <IconsWrapper>
          <styled-icon-wrapper
            v-if="currentTask !== undefined"
            @click="toggleCurrentTask"
          >
            <font-awesome-icon
              icon="fa-solid fa-list-ol"
              size="xl"
              color="#fa8c16"
            />
          </styled-icon-wrapper>
          <styled-icon-wrapper
            @click="handleExpandProgress"
            :isProgressExpanded="isProgressExpanded"
            id="expand-button"
          >
            <font-awesome-icon
              icon="fa-solid fa-chevron-down"
              size="xl"
              color="#FFFFFF"
            />
          </styled-icon-wrapper>
        </IconsWrapper>
      </styled-flow-details-wrapper>

      <current-task-wrapper
        v-if="
          currentTask !== undefined &&
            !isProgressExpanded &&
            isCurrentTaskExpanded
        "
      >
        <current-task-title-wrapper
          ><span>Open tasks</span>
          <font-awesome-icon
            icon="fa-solid fa-times-circle"
            size="xl"
            color="#0E455B"
            @click="handleCloseCurrentTask"
          />
        </current-task-title-wrapper>
        <CurrentTaskName>{{ currentTask.displayName }} </CurrentTaskName>
        <CurrentTaskDescription>{{
          currentTask.description
        }}</CurrentTaskDescription>
      </current-task-wrapper>
      <ProgressDetailsVue
        :progress="progress"
        :isExpanded="isProgressExpanded"
      />
    </styled-wrapper>
    <expanded-overlay @click="handleExpandProgress" v-if="isProgressExpanded" />
  </span>
</template>

<script>
import StandardText from "@/components/design-system/StandardText.vue"
import styled from "vue-styled-components"
import { margins, paddings } from "@/common/spacing"
import FlowDetails from "./FlowDetails.vue"
import {
  mapFlowIdToDisplayName,
  mapFlowIdToIcon,
  mapFlowStageToDisplayName,
} from "./utils"
import ProgressDetailsVue from "./ProgressDetails.vue"
import { checkHasComplete } from "@/common/utils"
import { glLegacyColorPalette, colors } from "@/common/colors"

const barZIndex = 999

const StyledFlowDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background ${glLegacyColorPalette.legacyGrey6};
  width: 100%;
  height: 53px
  padding: ${paddings.s};
`
const IconsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${margins.s};
`

const CurrentTaskTitleWrapper = styled.div`
  position: relative;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  text-transform: initial;
  line-height: 20px;
  letter-spacing: -0.01em;
  margin-bottom: ${margins.s};
  display: flex;
  align-items: center;
  justify-content: space-between;
  &::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -5px;
    width: 90px;
    height: 2px;
    background-color: ${colors.botChatPrimary};
  }
`

const CurrentTaskName = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: -0.01em;
  width: 100%;
  margin-bottom: 5px;
`

const CurrentTaskDescription = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-transform: initial;
`

const CurrentTaskWrapper = styled.div`
  width: 90vw;
  background: #feefde;
  border: 1px solid #fa8c16;
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  margin-top: ${margins.s};
  color: ${colors.botChatPrimary};
  padding: ${paddings.s};
`

const StyledWrapper = styled.div`
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${barZIndex};
  font-family: Lexend Deca;
  color: #ffffff;
  text-transform: capitalize;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledIconWrapper = styled("div", { isProgressExpanded: Boolean })`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
  margin-right: 14px;
  rotate: ${(props) => (props.isProgressExpanded ? "180deg" : "0deg")};
`

const ExpandedOverlay = styled.div`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-color: #000000;
  opacity: 0.5;
  top: 0;
  left: 0;
  z-index: ${barZIndex - 1};
`

export default {
  components: {
    StyledWrapper,
    FlowDetails,
    ExpandedOverlay,
    StyledFlowDetailsWrapper,
    ProgressDetailsVue,
    CurrentTaskWrapper,
    CurrentTaskTitleWrapper,
    CurrentTaskName,
    CurrentTaskDescription,
    IconsWrapper,
    StyledIconWrapper,
  },
  props: {
    progress: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentFlowName: "",
      currentFlowStage: "",
      currentFlowIcon: "",
      isProgressExpanded: false,
      isCurrentTaskExpanded: true,
      currentTask: undefined,
    }
  },
  methods: {
    handleExpandProgress() {
      this.isProgressExpanded = !this.isProgressExpanded
    },
    handleCloseCurrentTask() {
      this.isCurrentTaskExpanded = false
    },
    toggleCurrentTask() {
      this.isCurrentTaskExpanded = !this.isCurrentTaskExpanded
    },
    setCurrentFlowData() {
      const flowKeys = Object.keys(this.progress.flows)

      const currentFlow = !checkHasComplete(this.progress)
        ? flowKeys.find((flow) => this.progress.flows[flow].completed === false)
        : this.progress.flows[flowKeys.length]

      if (!currentFlow) {
        return
      }

      const currentStage = !checkHasComplete(this.progress)
        ? this.progress.flows[currentFlow].actions.find(
            (action) => action.completed === false
          )
        : this.progress.flows[currentFlow].actions.find(
            (action) => action.completed === true
          )

      if (!currentStage) {
        return
      }

      this.currentFlowName = mapFlowIdToDisplayName(currentFlow)
      this.currentFlowIcon = mapFlowIdToIcon(currentFlow)
      this.currentFlowStage = mapFlowStageToDisplayName(currentStage.id)
    },
    setTasksFromRecommendations() {
      const recommendations = this.progress.recommendations
        .filter(({ completed }) => {
          return !completed
        })
        .sort(function(a, b) {
          return a.priority - b.priority
        })

      if (recommendations.length > 0) {
        this.currentTask = recommendations[recommendations.length - 1]
      } else {
        // TODO: Completed tasks view for mobile
        this.currentTask = undefined
      }
    },
  },
  computed: {},
  watch: {
    progress: {
      deep: true,
      handler() {
        this.setTasksFromRecommendations()
        this.setCurrentFlowData()
      },
    },
  },
  mounted() {
    this.setTasksFromRecommendations()
    this.setCurrentFlowData()
  },
}
</script>

<style></style>
