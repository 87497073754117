<template>
  <ModalThemeing :isMobile="isMobile">
    <div class="error-div">
      <span v-if="errorText !== undefined">{{ errorText }}</span>
    </div>
    <StyledSecondaryAction
      v-if="!backButtonDisabled"
      id="btn-cancel"
      type="button"
      :onClick="cancel"
    >
      Cancel
    </StyledSecondaryAction>

    <StyledSecondaryAction
      v-if="stepped.current > 1 && !backButtonDisabled"
      type="button"
      :onClick="back"
      :disabled="isLoading"
      :isMobile="isMobile"
    >
      Back
    </StyledSecondaryAction>

    <StyledPrimaryAction
      id="btn-primary"
      :onClick="clicked"
      type="button"
      :disabled="v.error || v.invalid || isLoading"
      :loading="isLoading"
      :isMobile="isMobile"
    >
      {{ stepped.current === stepped.stages ? closeButtonText : "Continue" }}
    </StyledPrimaryAction>
  </ModalThemeing>
</template>

<script>
import { track } from "@/common/utils"
import { mapActions } from "vuex"
import PrimaryAction from "@/components/design-system/PrimaryAction.vue"
import styled from "vue-styled-components"
import SecondaryAction from "@/components/design-system/SecondaryAction.vue"
import { margins, paddings } from "@/common/spacing"

const StyledPrimaryAction = styled(PrimaryAction)`
  min-width: 125px;
`
const props = {
  isMobile: Boolean,
}

const StyledSecondaryAction = styled(SecondaryAction, props)`
  min-width: 125px;
  margin-right: ${(props) => (props.isMobile ? "" : margins.s)};
`

const ModalThemeing = styled("div", props)`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) =>
    props.errorText !== undefined ? "space-between;" : "flex-end"}
  align-items: center;

gap: ${(props) => (props.isMobile ? margins.s : "")};
padding: 0 ${(props) => (props.isMobile ? paddings.s : "")};
  @media only screen and (max-width: 800px) {
    align-items: center;
    justify-content: center;

    flex-direction: column;

    .btn {
       width: 100%;
     }
  }

  #btn-cancel{
    display: ${(props) =>
      props.theme.config &&
      props.theme.config.dashboardSettings.disableBotFlowCancellability ===
        true &&
      "none"} !important;
  }

  div {
    text-align:right;
  }

  .error-div {
    font-family: @font-family-lato;
    text-align: left;
    letter-spacing: 0.15px;
    color: #ed1c24;
    align-items: center;
  }
`

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    errorText: {
      type: String,
    },
    stepped: {
      type: Object,
      default: () => {
        return {}
      },
    },
    backButtonDisabled: Boolean,
    disableSteppedProgression: Boolean,
    isLoading: Boolean,
  },
  components: {
    ModalThemeing,
    StyledPrimaryAction,
    StyledSecondaryAction,
  },
  data: function() {
    return {
      closeButtonText: "",
    }
  },
  methods: {
    ...mapActions({
      sendMessage: "webchat/sendMessage",
    }),
    clicked() {
      const modalName = this.model.title ? this.model.title : this.model.modal
      if (
        this.stepped &&
        this.stepped.current &&
        this.stepped.current !== this.stepped.stages
      ) {
        track(`Click - ${modalName} next`, {})
        this.$emit("update-stepped-footer", {
          current: this.stepped.current !== null ? this.stepped.current++ : 0,
          stages: this.stepped.stages,
        })
      } else {
        if (this.model.submitForm || this.model.submitForm === undefined) {
          track(`Click - ${modalName} - Modal data submitted`, {})
          this.submit()
        } else {
          track(`Click - ${modalName} - Modal closed`, {})
          this.close()
        }
      }
    },
    back() {
      const modalName = this.model.title ? this.model.title : this.model.modal

      if (this.stepped.current !== 1 || 0) {
        track(`Click - ${modalName} back`, {})
        this.$emit("update-stepped-footer", {
          current: this.stepped.current !== null ? this.stepped.current-- : 0,
          stages: this.stepped.stages,
        })
      }
    },
    cancel() {
      this.sendMessage({ message: "cancel" })
      this.close()
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 800
    },
  },
  mounted() {
    const modalName = this.model.title ? this.model.title : this.model.modal
    track(`Click - ${modalName} - Modal displayed`, {})
    const isCloseButtonTextSet =
      this.model.closeButtonText !== "" &&
      this.model.closeButtonText !== undefined

    this.closeButtonText = isCloseButtonTextSet
      ? this.model.closeButtonText
      : "Done"
  },
}
</script>
