<template>
  <div>
    <div v-if="stepped.current === 1">
      <H2> Document Selection </H2>
      <SupportingText>Choose a document type to upload </SupportingText>

      <styled-horizontal-divider />
      <template v-for="group in fileGroups">
        <div @click="handleDocumentSelection(group)" :key="group">
          <StyledDocumentOption>
            <font-awesome-icon icon="fa-solid fa-file-text" size="xl" color="black" />
            <SupportingText>{{ group }}</SupportingText>
          </StyledDocumentOption>
        </div>
      </template>
    </div>
    <div v-if="stepped.current > 1">
      <H2>{{
        stepped.current !== stepped.stages ? 'Upload documents' : 'Confirm documents'
      }}</H2>
      <styled-horizontal-divider />

      <ChildCard>
        <ProgressBar :steps="progressBarSteps" :activeStep="activeFileGroup" />
      </ChildCard>

      <div class="title-wrapper">
        <h5 class="uploader-title">
          {{
            stepped.current !== stepped.stages
              ? activeFileGroup
              : 'Your uploaded documents'
          }}
        </h5>

        <Pill
          v-if="requiredDocuments > 0 && stepped.current !== stepped.stages"
          color="blue"
          >{{ requiredDocuments }}
          {{ requiredDocuments === 1 ? individualFileName : activeFileGroup }}
          Required</Pill
        >
      </div>
      <file-description-wrapper>
        <SupportingText v-if="stepped.current !== stepped.stages">
          {{ fileDescription }}
        </SupportingText>
      </file-description-wrapper>
      <div
        data-test-id="dropzone"
        :key="index"
        v-for="(file, index) in files"
        @click="$refs[`files-${index}`][0].click()"
      >
        <div
          v-show="
            (stepped.current !== stepped.stages && index === currentFileIndex) ||
            (stepped.current !== stepped.stages && currentFileIndex === -1 && index === 0)
          "
          class="drop-zone"
          @drop="e => ondrop(e, index)"
        >
          <styled-drop-zone-indicator>
            <div class="drop-info">
              <div v-if="!file.url || currentFileIndex === -1">
                <FileUploadSummary :fileTitle="activeFileGroup" />
              </div>

              <input
                type="file"
                multiple
                :name="`files-${index}`"
                :ref="`files-${index}`"
                :id="`files-${index}`"
                class="input text-input w-input"
                :accept="file.accept || '*/*'"
                :data-index="index"
                @click="e => (e.target.value = '')"
                @change="setFile"
              />
            </div>
          </styled-drop-zone-indicator>
        </div>
      </div>
      <styled-uploaded-file-summary-wrapper>
        <div v-if="uploadedFiles.length > 0 || stepped.current === stepped.stages">
          <template v-for="(file, index) in files">
            <styled-uploaded-file-summary-card
              v-if="
                (file.group === activeFileGroup && file.file !== undefined) ||
                (stepped.current === stepped.stages && file.file !== undefined)
              "
              :key="file.group + index"
            >
              <styled-file-details-wrapper>
                <styled-file-icon-wrapper>
                  <font-awesome-icon
                    size="2xl"
                    color="#2F80ED"
                    icon="fa-solid fa-file-text"
                  />
                  <styled-tick-icon>
                    <font-awesome-icon color="#6ABD40" icon="fa-solid fa-circle-check" />
                  </styled-tick-icon>
                </styled-file-icon-wrapper>
                <styled-file-details>
                  <StandardText>{{ file.name }} </StandardText>
                  <Loader disablePercentage />
                </styled-file-details>
              </styled-file-details-wrapper>
              <styled-remove-icon-wrapper
                v-if="stepped.current !== stepped.stages"
                @click="() => handleRemoveFile(index, $refs[`files-${index}`][0])"
              >
                <font-awesome-icon
                  size="2xl"
                  color="#BDBDBD"
                  icon="fa-solid fa-xmark"
                /> </styled-remove-icon-wrapper></styled-uploaded-file-summary-card
          ></template>
        </div>
        <styled-no-files-wrapper v-else>
          <img height="40" :src="`${botIconsUrl}/empty-file.png`" />
          <SupportingText>No documents have been uploaded yet</SupportingText>
        </styled-no-files-wrapper>
      </styled-uploaded-file-summary-wrapper>
    </div>
  </div>
</template>

<script>
import { formMixin } from '@/mixins/formMixin'
import {
  addBlockDefaultDragEventBehaviourListeners,
  removeBlockDefaultDragEventBehaviourListeners,
  track,
} from '@/common/utils'
import Pill from '../../design-system/Pill'
import styled from 'vue-styled-components'
import FileUploadSummary from '@/components/generalUI/FileUploadSummary.vue'
import {
  getFileDescription,
  getFileGroup,
  getIndividualFileName,
  isSupportedFileType,
} from '@/components/file/utils'
import ProgressBar from '@/components/design-system/ProgressBar.vue'
import ChildCard from '@/components/design-system/ChildCard.vue'
import HorizontalDivider from '@/components/design-system/HorizontalDivider.vue'
import { margins, paddings } from '@/common/spacing'
import SupportingText from '@/components/design-system/SupportingText.vue'
import { colors, greyscaleColorPalette } from '@/common/colors'
import { flexColumn, flexRow } from '@/common/flex'
import StandardText from '@/components/design-system/StandardText.vue'
import Loader from '@/components/progress/Loader.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import H2 from '@/components/design-system/H2.vue'
import AlertInfoComponent from '@/components/design-system/AlertInfoComponent.vue'

const StyledFileDetailsWrapper = styled.div`
  ${flexRow({ alignItems: 'center', justifyContent: 'flex-start' })}
  column-gap: ${margins.m};
  width: 280px;
  white-space: wrap;
  word-wrap: break-word;
  word-break: break-word;
`

const StyledFileDetails = styled.div`
  ${flexColumn({ alignItems: 'flex-start', justifyContent: 'center' })}
`

const StyledFileIconWrapper = styled.div`
  position: relative;
`

const StyledTickIcon = styled.div`
  position: absolute;
  bottom: -13px;
  right: -11px;
`

const StyledRemoveIconWrapper = styled.div`
  cursor: pointer;
`

const StyledUploadedFileSummaryWrapper = styled.div`
  width: 100%;
  margin-top: ${margins.s};
`

const StyledUploadedFileSummaryCard = styled.div`
  ${flexRow({ alignItems: 'center', justifyContent: 'space-between' })}
  border: 1px solid ${greyscaleColorPalette.greyscale4};
  padding: ${paddings.s} ${paddings.s};
  margin-top: ${margins.s};
  gap: ${margins.m};
  border-radius: 4px;
`

const StyledNoFilesWrapper = styled.div`
  ${flexColumn({ alignItems: 'center', justifyContent: 'center' })}
  border: 1px solid ${greyscaleColorPalette.greyscale6};
  border-radius: 4px;
  min-height: 135px;
  row-gap: ${margins.m};
`

const StyledHorizontalDivider = styled(HorizontalDivider)`
  margin: ${margins.m} 0;
`

const StyledDropZoneIndicator = styled.div`
  border: 2px dashed #bdbdbd;
  border-radius: 4px;
  width: 100%;
  height: 100%;
`

const StyledDocumentOption = styled(ChildCard)`
  cursor: pointer;
  padding-top: ${paddings.l};
  padding-bottom: ${paddings.l};
  ${flexRow({ alignItems: 'center', justifyContent: 'flex-start' })};
  gap: ${margins.m};
  margin-top: ${margins.xs};
`

const StyledAlertInfoText = styled(AlertInfoComponent)`
  width: 100%;
  overflow-wrap: anywhere;
  margin: ${margins.m} 0;
`

const FileDescriptionWrapper = styled('div')`
  display: block;
  position: relative;
  text-align: left;
  margin-bottom: ${margins.s};
`

export default {
  components: {
    Pill,
    StyledDropZoneIndicator,
    FileUploadSummary,
    ProgressBar,
    ChildCard,
    StyledHorizontalDivider,
    SupportingText,
    StyledUploadedFileSummaryWrapper,
    StyledUploadedFileSummaryCard,
    StyledNoFilesWrapper,
    StandardText,
    Loader,
    StyledFileIconWrapper,
    StyledTickIcon,
    StyledFileDetails,
    StyledFileDetailsWrapper,
    StyledRemoveIconWrapper,
    H2,
    StyledDocumentOption,
    StyledAlertInfoText,
    FileDescriptionWrapper,
  },
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      default: undefined,
    },
    stepped: {
      type: Object,
    },
  },
  data() {
    return {
      fileOptions: this.model.fileOptions.map((fileOption, index) => {
        return {
          ...fileOption,
          group: getFileGroup(fileOption),
        }
      }),
      fileGroups: [],
      activeFileGroup: '',
      fileDescription: '',
      files: [],
      screenWidth: 801,
      botIconsUrl: '',
      selectedFileGroup: undefined,
      hasSelectableDocuments: false,
    }
  },
  validations() {
    return {
      files: {
        $each: {
          isValidDoc: (value, files) => {
            if (this.selectedFileGroup === undefined && this.hasSelectableDocuments) {
              return false
            }
            if (value.group !== this.activeFileGroup) {
              return true
            }
            const uploadedFiles = files.filter(
              file => file.file !== undefined && file.group === this.activeFileGroup,
            )

            if (this.model.requiredDocuments <= uploadedFiles.length) {
              const wasRequiredFileUploaded = uploadedFiles.find(
                uploadedFile => uploadedFile.title === value.title,
              )

              if (wasRequiredFileUploaded === undefined) {
                value.required = false
              }

              return true
            }

            const uploadedFilesLength = uploadedFiles.length
            const initialRequiredFilesLength = this.model.fileOptions.filter(
              fileOption =>
                fileOption.required === true &&
                getFileGroup(fileOption) === this.activeFileGroup,
            ).length

            if (
              initialRequiredFilesLength > 0 &&
              uploadedFilesLength >= initialRequiredFilesLength
            ) {
              // override each inidvidual files requirement from the schema if enough files have been uploaded for that group
              return true
            }

            if (value.required && !value.file) {
              return false
            }

            return true
          },
        },
      },
    }
  },
  watch: {
    files: {
      handler: function () {
        this.touch(this.$v.files)
        const uploadedFiles = this.files.filter(
          file => file !== undefined && file.file !== undefined,
        )
        this.$emit('update-form-files', [...uploadedFiles])
      },
      deep: true,
    },
    stepped: {
      handler: function () {
        // revalidate required files on step change
        const copy = [...this.files]
        this.files = [...copy]

        if (this.stepped.current === 2 && !this.hasSelectableDocuments) {
          this.$emit('disable-footer-back-button', true)
        } else {
          this.$emit('disable-footer-back-button', false)
        }

        if (this.stepped.current === this.stepped.stages) {
          this.activeFileGroup = 'Confirm'
        } else {
          if (this.selectedFileGroup === undefined) {
            this.activeFileGroup = this.fileGroups[this.stepped.current - 2]
          }
        }
      },
      deep: true,
    },
    activeFileGroup: {
      handler: function () {
        this.fileDescription = getFileDescription(this.activeFileGroup)
        this.individualFileName = getIndividualFileName(this.activeFileGroup)
      },
      deep: true,
    },
  },
  computed: {
    allowAdditionalFiles() {
      return this.model.options && this.model.options.allowAdditionalFileUploads === true
    },
    currentFileIndex() {
      return this.files.findIndex(file => file.file === undefined)
    },
    uploadedFiles() {
      const uploadedFiles = this.files.filter(fileOption => {
        return fileOption.group === this.activeFileGroup && fileOption.file !== undefined
      })
      return uploadedFiles
    },
    requiredDocuments() {
      let requiredDocumentsInFileGroup = 0

      this.fileOptions.map(option => {
        const fileGroup = getFileGroup(option)
        if (this.activeFileGroup === fileGroup && option.required) {
          requiredDocumentsInFileGroup += 1
        }
      })

      return requiredDocumentsInFileGroup
    },
    progressBarSteps() {
      if (this.selectedFileGroup !== undefined) {
        return [
          {
            name: this.selectedFileGroup,
            stepState: this.activeFileGroup === 'Confirm' ? 'completed' : 'inProgress',
          },
          {
            name: 'Confirm',
            stepState: this.activeFileGroup === 'Confirm' ? 'inProgress' : 'nextStep',
          },
        ]
      } else {
        return [
          ...this.fileGroups.map((group, index) => ({
            name: group,
            stepState:
              this.activeFileGroup === group
                ? 'inProgress'
                : this.activeFileGroup === 'Confirm'
                ? 'completed'
                : this.fileGroups.findIndex(group => this.activeFileGroup === group) >
                  index
                ? 'completed'
                : 'nextStep',
          })),
          {
            name: 'Confirm',
            stepState: this.activeFileGroup === 'Confirm' ? 'inProgress' : 'nextStep',
          },
        ]
      }
    },
  },
  methods: {
    handleDocumentSelection(selectedGroup) {
      this.activeFileGroup = selectedGroup
      this.fileDescription = getFileDescription(selectedGroup)
      this.individualFileName = getIndividualFileName(selectedGroup)
      this.selectedFileGroup = selectedGroup
      this.$emit('update-stepped', {
        current: 2,
        stages: this.fileGroups.length + 2,
      })
    },
    handleRemoveFile(fileIndex, inputRef) {
      inputRef.file = undefined
      if (
        this.files.filter(file => file !== undefined && file.file !== undefined).length >
        this.model.fileOptions.length
      ) {
        const updatedFiles = [...this.files].filter(
          file => file !== undefined && file.file !== undefined,
        )

        const initialFileOption = {
          ...this.model.fileOptions[0],
          group: this.activeFileGroup,
          required: false,
        }

        updatedFiles[fileIndex] = initialFileOption

        this.files = [...updatedFiles]
      } else {
        if (
          this.files.filter(file => file !== undefined && file.file !== undefined)
            .length === 1
        ) {
          this.files = this.model.fileOptions.map((fileOption, index) => {
            return {
              ...fileOption,
              group: getFileGroup(fileOption),
            }
          })
        } else {
          const initialFileOption = this.model.fileOptions[0]
          const currentFiles = [...this.files]
          currentFiles[fileIndex] = initialFileOption
          this.files = [...currentFiles]
        }
      }
    },
    setFile(e) {
      const copy = [...this.files]
      const index = +e.target.dataset.index || 0

      const copyFile = copy.find(file => file.group === this.activeFileGroup)

      if (e.target.files.length > 0) {
        track(`Uploaded - file type ${this.files[index].title}`, {
          ...this.files[index],
        })

        copy[index] = {
          icon: copyFile.icon,
          accept: copyFile.accept,
          title: copyFile.title,
          description: copyFile.description,
          group: copyFile.group,
          url: URL.createObjectURL(e.target.files[0]),
          name: e.target.files[0].name,
          file: e.target.files[0],
        }
      }

      const hasUploadedAllFiles = copy
        .filter(file => file.group === this.activeFileGroup)
        .every(file => file !== undefined && file.file !== undefined)

      if (hasUploadedAllFiles) {
        copy.push({
          icon: copyFile.icon,
          accept: copyFile.accept,
          title: copyFile.title,
          description: copyFile.description,
          group: copyFile.group,
          required: false,
        })
      }

      this.files = [...copy]
    },
    ondrop(e, index) {
      const copy = [...this.files]
      track(`Uploaded - file type ${this.files[index].title}`, {
        ...this.files[index],
      })
      e.stopPropagation()
      e.preventDefault()

      const copyFile = copy.find(file => file.group === this.activeFileGroup)

      if (e.dataTransfer.files.length > 0) {
        [...e.dataTransfer.files].map((uploadedFile, uploadedIndex) => {
          const hasUploadedAllFiles = copy
            .filter(file => file.group === this.activeFileGroup)
            .every(file => file !== undefined && file.file !== undefined)

          if (isSupportedFileType(uploadedFile.type)) {
            copy[index + uploadedIndex] = {
              icon: copyFile.icon,
              accept: copyFile.accept,
              title: copyFile.title,
              description: copyFile.description,
              group: copyFile.group,
              url: URL.createObjectURL(uploadedFile),
              name: uploadedFile.name,
              file: uploadedFile,
              required: hasUploadedAllFiles,
            }
          }
        })

        const hasUploadedAllFiles = copy
          .filter(file => file.group === this.activeFileGroup)
          .every(file => file !== undefined && file.file !== undefined)

        if (hasUploadedAllFiles) {
          copy.push({
            icon: copyFile.icon,
            accept: copyFile.accept,
            title: copyFile.title,
            description: copyFile.description,
            group: copyFile.group,
            required: false,
          })
        }
      }

      const fileInputName = `files-${index}`
      const fileInputNode = document.getElementById(fileInputName)
      fileInputNode.files = e.dataTransfer.files
      this.files = [...copy]
    },
  },
  beforeMount() {
    if (this.model.fileOptions) {
      this.model.fileOptions.map(fileOption => {
        if (!this.fileGroups.includes(getFileGroup(fileOption))) {
          this.fileGroups.push(getFileGroup(fileOption))
        }
        fileOption.group = getFileGroup(fileOption)
        return fileOption
      })
    }

    if (this.model.fileOptions.length > 0) {
      this.activeFileGroup = this.fileGroups[this.stepped.current - 2]
      this.fileDescription = getFileDescription(this.activeFileGroup)
      this.individualFileName = getIndividualFileName(this.activeFileGroup)
    }

    this.hasSelectableDocuments =
      this.model.requiredDocuments === 1 || this.model.hasSelectableDocuments

    if (this.hasSelectableDocuments) {
      this.$emit('update-stepped', {
        current: 1,
        stages: this.fileGroups.length + 2,
      })
    } else {
      this.$emit('update-stepped', {
        current: 2,
        stages: this.fileGroups.length + 2,
      })
    }
  },
  mounted() {
    track('Displayed - Multi file upload', {})
    this.screenWidth = window.innerWidth
    this.botIconsUrl = process.env.VUE_APP_ASSETS || ''
    addBlockDefaultDragEventBehaviourListeners()

    this.files = this.model.fileOptions.map((fileOption, index) => {
      return {
        ...fileOption,
        group: getFileGroup(fileOption),
      }
    })
  },
  unmounted() {
    removeBlockDefaultDragEventBehaviourListeners()
  },
}
</script>

<style lang="less" scoped>
.retake-container {
  text-align: center;
}

#files-container {
  max-height: 200px;
  border-radius: 10px;
  border: 1px dashed var(--mainHighlight);
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.drop-zone {
  background-color: #f2f2f2;
  border-radius: 10px;
  border: none;
  padding: 14px;
  margin: 20px auto;
  min-height: 200px;
  height: 200px;
  height: fit-content;
  width: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 0;
  }
  & input {
    opacity: 0; /* invisible */
    display: none;
    width: 1px;
    height: 1px;
    cursor: pointer;
  }

  p {
    margin: 10px 0;
  }

  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }

  & .drop-info {
    pointer-events: none;
    padding: 10px;

    & p {
      text-align: center;
      // font-size: 12px;
      letter-spacing: 0.2px;
      color: #55616b;
    }

    & img {
      max-width: 100%;
      min-height: 40px;
    }
  }
}

.title-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 12px;
  margin-top: 25px;
}

.uploader-description,
.uploader-title {
  margin: 0;
}

#custom-icon {
  max-width: 40px;
}

#addDoc {
  text-decoration: underline;
  font-weight: bold;
  margin-top: 14px;
  font-size: 18px;
  color: #34bebd;
}

#addDoc:hover {
  cursor: pointer;
}
</style>
