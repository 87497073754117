<template>
  <styled-wrapper>
    <slot />
  </styled-wrapper>
</template>

<script>
import { margins, paddings } from "@/common/spacing"
import styled from "vue-styled-components"
import { cardLikeStyle, resetStyles } from "./utils"
import HorizontalDivider from "./HorizontalDivider.vue"
import H2 from "./H2"
import { colors } from "@/common/colors"

const StyledWrapper = styled.div`
  ${resetStyles()}
  ${cardLikeStyle()}
  border-radius: 4px;
  border: 1px solid ${colors.greyscale6};
  padding: ${paddings.m};
`

const StyledDivider = styled(HorizontalDivider)`
  margin-bottom: ${margins.m};
`

export default {
  components: {
    StyledWrapper,
  },
  props: {
    title: String,
  },
}
</script>
