<template>
  <styled-wrapper><slot /></styled-wrapper>
</template>

<script>
import { colors } from "@/common/colors"
import styled from "vue-styled-components"
import { baseTextStyle, resetStyles } from "./utils"
import { fontSizes, fontWeights } from "@/common/fonts"

const StyledWrapper = styled.h6` 
  ${resetStyles()}
  ${baseTextStyle()}
  color: ${colors.textPrimary};
  font-size: ${fontSizes.m};
  font-weight: ${fontWeights.bold};
  `

export default {
  components: {
    StyledWrapper,
  },
}
</script>
