import axios from "@/axios/chatbot"
import { BlobServiceClient } from "@azure/storage-blob"
import { AbortController } from "@azure/abort-controller"
import { mapGetters, mapActions } from "vuex"
import { logErrorToDatadog } from "@/common/utils"

export const formMixin = {
  computed: {
    ...mapGetters({
      uri: "storage/uri",
      container: "storage/container",
      sas: "storage/sas",
    }),
  },
  methods: {
    ...mapActions({
      postActivity: "webchat/postActivity",
    }),
    // @ts-ignore
    serialize(form, serialized = {}) {
      // Loop through each field in the form
      for (let i = 0; i < form.elements.length; i++) {
        const field = form.elements[i]

        // Don't serialize fields without a name, submits, buttons, file and reset inputs, disabled fields and fields already serialized
        if (
          !field.name ||
          field.disabled ||
          field.type === "file" ||
          field.type === "reset" ||
          field.type === "submit" ||
          field.type === "button" ||
          // @ts-ignore
          serialized[field.name]
        )
          continue

        // If a multi-select, get all selections
        if (field.type === "select-multiple") {
          for (let n = 0; n < field.options.length; n++) {
            if (!field.options[n].selected) continue
            // @ts-ignore
            if (serialized[field.name] === undefined)
              // @ts-ignore
              serialized[field.name] = []
            // @ts-ignore
            serialized[field.name].push(field.options[n].value)
          }
        }

        // Convert field data to a query string
        else if (
          (field.type !== "checkbox" && field.type !== "radio") ||
          field.checked
        ) {
          // @ts-ignore
          serialized[field.name] = field.value
        }
      }

      return serialized
    },
    // @ts-ignore
    submitSerializedForm(form, action, serialized) {
      const formAction = action || form.getAttribute("action")
      axios().post(formAction, serialized)
    },
    // @ts-ignore
    serializeForm(form, data) {
      // Submit the form asynchronously to prevent the webchat page from refreshing.
      return { ...this.serialize(form), ...data }
    },
    async serializeFormWithFiles(
      // @ts-ignore
      form,
      cb: (progress: any) => void,
      modalName: string,
      handleFileUploadError: (err: any) => void,
      // @ts-ignore
      stateFiles
    ) {
      const serialized = this.serialize(form)
      const files = form.querySelectorAll("input[type=file]")
      const data = {}
      if (files.length > 0) {
        let blobnames = await this.uploadBlobs(
          files,
          cb,
          modalName,
          handleFileUploadError,
          stateFiles
        )
        // @ts-ignore
        files.forEach((v, i) => {
          if (blobnames !== undefined) {
            // @ts-ignore
            data[v.getAttribute ? v.getAttribute("name") : v.name] =
              blobnames[i]
          }
        })
      }

      return { ...serialized, ...data }
    },
    async uploadBlobs(
      // @ts-ignore
      fileInputs,
      // @ts-ignore
      handleUploadProgress,
      modalName = "file_upload",
      // @ts-ignore
      handleError,
      // @ts-ignore
      stateFiles
    ) {
      try {
        // 256 KB
        let blockSize = 256 * 1024
        let blobnames = []
        let totalBytes = 0
        // @ts-ignore
        let totalBytesUploaded = []
        let uploadPromises = []

        let files = []
        const safeName =
          modalName.replaceAll !== undefined
            ? modalName.replaceAll("-", "_")
            : "file_upload"

        if (stateFiles === undefined) {
          for (let i = 0; i < fileInputs.length; i++) {
            for (let j = 0; j < fileInputs[i].files.length; j++) {
              files.push(fileInputs[i].files[j])
            }
          }
        } else {
          files = stateFiles
        }

        for (let i = 0; i < files.length; i++) {
          let file = files[i]
          let ext = file.name
            .substring(file.name.lastIndexOf("."))
            .toLowerCase()
          let blobname =
            `${safeName}_${i + 1}`.toLowerCase() +
            "_" +
            Math.random()
              .toString(16)
              .slice(2) +
            ext

          blobnames.push(blobname)
          totalBytes += file.size
          totalBytesUploaded[i] = 0

          const timeoutMs = 60000

          const abortSignal = new AbortController().signal
          const blobServiceClient = new BlobServiceClient(
            // @ts-ignore
            `${this.uri}?${this.sas}`
          )
          const containerClient = blobServiceClient.getContainerClient(
            // @ts-ignore
            this.container
          )

          const blockBlobClient = containerClient.getBlockBlobClient(blobname)

          uploadPromises.push(
            blockBlobClient.uploadData(file, {
              blockSize: blockSize,
              // @ts-ignore
              timeout: timeoutMs,
              onProgress: (ev) => {
                totalBytesUploaded[i] = ev.loadedBytes
                handleUploadProgress(
                  // @ts-ignore
                  totalBytesUploaded.reduce((a, b) => a + b, 0) / totalBytes
                )
              },
              abortSignal,
            })
          )
        }

        const results = await Promise.allSettled(uploadPromises)

        const rejectedReasons = results
          .filter(({ status }) => status === "rejected")
          .map((error, i) => `${i}: ${error}`)
          .join(" ")

        if (rejectedReasons.length > 0) {
          logErrorToDatadog(
            "Document upload error - uploadBrowserDataToBlockBlob error",
            { errorBody: rejectedReasons }
          )
          throw new Error(rejectedReasons)
        }

        return blobnames
      } catch (e) {
        handleError(e)
        //eslint-disable-next-line no-console
        console.error("FailedToUploadFilesToIngest Full Error " + e)
      }
    },
    // @ts-ignore
    async validate(event) {
      // @ts-ignore
      await this.$v[event.target.name].$touch()
      // @ts-ignore
      this.emitTouched()
    },
    // @ts-ignore
    async validateDrop(node, files) {
      node.files = files
      // @ts-ignore
      await this.$v[node.name].$touch()
      // @ts-ignore
      this.emitTouched()
    },
    // @ts-ignore
    touch(property) {
      property.$touch()
      // @ts-ignore
      this.emitTouched()
    },
    // @ts-ignore
    emitTouched(payload) {
      // @ts-ignore
      if (this.$v) {
        if (!payload) payload = this.getTouchedPayload()
        // @ts-ignore
        this.$emit("touched", payload)
      }
    },
    getTouchedPayload() {
      // @ts-ignore
      return { error: this.$v.$anyError, invalid: this.$v.$invalid }
    },
  },
  mounted() {
    // @ts-ignore
    this.emitTouched()
  },
}
