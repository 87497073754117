var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ControlElement',{attrs:{"id":_vm.id,"name":_vm.name,"label":_vm.label,"numeric":_vm.numeric,"required":_vm.required,"disabled":_vm.disabled,"email":_vm.email,"phone":_vm.phone,"url":_vm.url,"regex":_vm.regex,"errorMessage":_vm.errorMessage,"centre":_vm.centre,"focusItem":_vm.focusItem},scopedSlots:_vm._u([{key:"control",fn:function({ setFocus, setContent }){return [(_vm.search && !_vm.focusedPostCode && _vm.addressFilled)?_c('br'):_vm._e(),(_vm.search)?_c('span',{staticClass:"side-by-side-flex",attrs:{"id":"postcode-search-container"}},[_c('img',{attrs:{"src":"/assets/search.svg","id":"search-svg","alt":"Magnifying Glass"}}),_c('input',{staticClass:"postcode-search",attrs:{"placeholder":"Find by postcode","autocomplete":_vm.autocomplete ? '' : 'none'},on:{"input":function($event){return _vm.searchCallback($event)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return _vm.findAddress($event.target.value)},"focus":function($event){setFocus(true);
          _vm.toggleFocusedPostCode();},"blur":function($event){setFocus(false);
          setContent(_vm.value);
          _vm.toggleFocusedPostCode();}}})]):_vm._e(),(_vm.multiline)?_c('textarea',{attrs:{"id":_vm.id,"name":_vm.name,"placeholder":"Enter Address Manually","autocomplete":_vm.autocomplete ? '' : 'none'},domProps:{"value":_vm.value},on:{"focus":function($event){setFocus(true);
        _vm.toggleFocusedPostCode();},"blur":function($event){setFocus(false);
        setContent(_vm.value);
        _vm.toggleFocusedPostCode();},"input":function($event){return _vm.emitInput($event)},"change":function($event){return _vm.$emit('change', $event)},"click":function($event){return _vm.$emit('manual-address')}}}):_c('input',{attrs:{"id":_vm.id,"name":_vm.name,"disabled":_vm.disabled,"type":_vm.inputType},domProps:{"value":_vm.value},on:{"focus":function($event){return setFocus(true)},"blur":function($event){setFocus(false);
        setContent(_vm.value);},"input":function($event){return _vm.emitInput($event)},"change":function($event){return _vm.$emit('change', $event)}}})]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }