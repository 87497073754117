<template>
  <styled-wrapper>
    <styled-file-icon v-if="isMobile === false">
      <font-awesome-icon color="#2F80ED" icon="fa-solid fa-file-text" size="xl"
    /></styled-file-icon>
    <H6 v-if="fileTitle">
      Click <span v-if="isMobile === false">or drag here</span><span v-else>here</span> to
      upload {{ fileTitle }}
    </H6>
    <H6 v-else>Click here to upload a document</H6>
    <SupportingText>Please upload all documents as PNG, JPEG or PDF</SupportingText>
    <styled-button-wrapper> <PrimaryAction>Upload</PrimaryAction></styled-button-wrapper>
  </styled-wrapper>
</template>

<script>
import { colors } from '@/common/colors'
import styled from 'vue-styled-components'
import { margins } from '@/common/spacing'
import { cardLikeStyle } from '../design-system/utils'
import PrimaryAction from '../design-system/PrimaryAction.vue'
import H6 from '../design-system/H6.vue'
import { flexColumn } from '@/common/flex'
import SupportingText from '../design-system/SupportingText.vue'
import useDevice from '@/mixins/useDevice'

const props = { fileTitle: String }

const StyledWrapper = styled('div', props)`
  ${flexColumn({ alignItems: 'center', justifyContent: 'center' })}
  column-gap: ${margins.s};
  margin: ${margins.s} 0;

  h6,
  span {
    text-align: center;
  }
`

const StyledFileIcon = styled.div`
  ${cardLikeStyle()};
  background-color: ${colors.white};
  border-radius: 100px;
  height: 52px;
  width: 52px;
  margin-bottom: ${margins.s};
  ${flexColumn({ alignItems: 'center', justifyContent: 'center' })}
`

const StyledButtonWrapper = styled.div`
  margin-top: ${margins.m};
  min-width: 151px;
`

export default {
  setup() {
    const { isMobile } = useDevice()
    return {
      isMobile,
    }
  },
  components: {
    StyledWrapper,
    PrimaryAction,
    H6,
    StyledFileIcon,
    StyledButtonWrapper,
    SupportingText,
  },
  props,
}
</script>
