import axios from "axios";

export default (token?: string, customHeaders: any = undefined) => {
  let headers = { ...customHeaders };
  if (token !== undefined && token !== null)
    headers["Authorization"] = `Bearer ${token}`;

  axios.defaults.withCredentials = true;
  return axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    headers: headers,
    withCredentials: true,
  });
};
