<template>
  <div class="share-code-inputs">
    <input
      class="sharecode-section-input"
      type="text"
      maxlength="3"
      v-model="sectionOne"
      @input="handleInput"
      pattern="^[a-zA-Z0-9]+$"
      onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
    />
    <input
      class="sharecode-section-input"
      type="text"
      maxlength="3"
      v-model="sectionTwo"
      @input="handleInput"
      pattern="^[a-zA-Z0-9]+$"
      onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
    />
    <input
      class="sharecode-section-input"
      type="text"
      maxlength="3"
      v-model="sectionThree"
      @input="handleInput"
      pattern="^[a-zA-Z0-9]+$"
      onkeypress="return /[0-9a-zA-Z]/i.test(event.key)"
    />
    <input type="text" hidden v-model="rightToRentCode" />
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";

const SECTION_LENGTH = 3;

export default {
  mixins: [formMixin],
  data() {
    return {
      sectionOne: "",
      sectionTwo: "",
      sectionThree: "",
      rightToRentCode: "",
    };
  },
  validations: {},
  methods: {
    handleInput(e) {
      const length = e.target.value.length;

      if (length === SECTION_LENGTH) {
        e.target.nextElementSibling.focus();
      }

      if (length === 0) {
        e.target.previousElementSibling.focus();
      }
    },
    combineSections() {
      this.rightToRentCode = [
        this.sectionOne.toUpperCase(),
        this.sectionTwo.toUpperCase(),
        this.sectionThree.toUpperCase(),
      ].join(" ");
      this.$emit("onChange", this.rightToRentCode);
    },
  },
  watch: {
    sectionOne() {
      this.combineSections();
    },
    sectionTwo() {
      this.combineSections();
    },
    sectionThree() {
      this.combineSections();
    },
  },
};
</script>

<style lang="less" scoped>
.sharecode-section-input {
  padding: 15px;
  border-radius: 10px;
  outline: none;
  text-transform: uppercase;
  width: fit-content;
  text-align: center;
  width: 100%;
}

.share-code-inputs {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
  grid-gap: 10px;
}
</style>
