<template>
  <div>
    <div>
      <TenantRow
        v-for="(v, index) in $v.tenants.$each.$iter"
        :key="index"
        subjectType="Tenant"
        :tenantNumber="+index + 1"
        :id="tenants[index].id"
        :tenant="tenants[index]"
        :v="v"
        @change="touch"
      />
      <div class="validationMessage" v-if="!$v.tenants.minOne">
        You must enter the name and e-mail for each tenant.
      </div>
    </div>
    <button class="btn btn-primary" @click="addTenant">Add Tenant</button>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import { email } from "vuelidate/lib/validators";
import { v4 as uuid } from "uuid";

import TenantRow from "./TenantRow";
// e-mail field:
// valid: this is filled in and name is filled
// valie: this is blank and name is blank
// invalid: this is blank and name is filled

const isValid = (linkedField) => (target, tenantInput) => {
  return (target && tenantInput[linkedField]) || !tenantInput[linkedField];
};

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tenants: this.model.tenants,
    };
  },
  validations: {
    //ToDo: change property to subjects
    tenants: {
      minOne: (target, tenantInput) => {
        return tenantInput.tenants.some((t) => t.name && t.email);
      },
      $each: {
        name: { isValid: isValid("name") },
        email: { email, isValid: isValid("email") },
      },
    },
  },
  components: {
    TenantRow,
  },
  methods: {
    addTenant() {
      const tenant = { id: uuid(), name: "", email: "" };
      this.tenants.push(tenant);
    },
  },
  mounted() {
    // There should be at least 3 tenant fields on the form
    for (let i = this.model.tenants.length; i < 3; i += 1) this.addTenant();
    this.emitTouched();
  },
};
</script>
<style scoped>
.validationMessage {
  padding: 0 20px;
}
</style>
