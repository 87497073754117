<template>
  <div class="container">
    <div style="display: flex; column-gap: 50px; place-items: center">
      <div class="svgContainer">
        <svg
          version="1.1"
          id="L4"
          x="0px"
          y="0px"
          height="50px"
          width="50px"
          fill="white"
          viewBox="0 20 50 60"
          enableBackground="new 0 0 0 0"
        >
          <circle
            fill="var(--mainHighlight)"
            stroke="none"
            cx="6"
            cy="50"
            r="6"
          >
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.1"
            />
          </circle>
          <circle
            fill="var(--mainHighlight)"
            stroke="none"
            cx="26"
            cy="50"
            r="6"
          >
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.2"
            />
          </circle>
          <circle
            fill="var(--mainHighlight)"
            stroke="none"
            cx="46"
            cy="50"
            r="6"
          >
            <animate
              attributeName="opacity"
              dur="1s"
              values="0;1;0"
              repeatCount="indefinite"
              begin="0.3"
            />
          </circle>
        </svg>
      </div>
      <StyledLabel v-if="filename">{{ filename }}</StyledLabel>
      <StyledLabel v-else>Uploaded:</StyledLabel>
    </div>
    <div class="counter"></div>
    <span class="percent">%</span>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin"
import SupportingText from "../design-system/SupportingText.vue"
import { margins } from "@/common/spacing"
import styled from "vue-styled-components"

const StyledLabel = styled(SupportingText)`
  margin-right: ${margins.xxs};
`

export default {
  mixins: [formMixin],
  props: {
    progress: {
      type: Number,
    },
    filename: {
      type: String,
    },
    disablePercentage: {
      type: Boolean,
      required: false,
    },
  },
  data: function() {
    return {
      normalizedProgress: this.progress,
      step: 0.01,
      uploadedFilename: this.filename,
    }
  },
  components: {
    StyledLabel,
  },
  watch: {
    progress() {
      this.normalizedProgress = Math.min(
        1,
        this.progress - (this.progress % this.step) + this.step
      )
      if (this.normalizedProgress === "100") {
        this.disablePercentage = true
      }
    },
  },
}
</script>

<style scoped lang="less">
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: auto;
  margin: 0;
}

p {
  font-weight: 700;
  padding-right: 50px;
  color: var(--primaryHeaderText) !important;
}

.progress-label,
.percent {
  color: var(--mainHighlight);
}

.counter::after {
  color: var(--mainHighlight);
  content: counter(count);
  animation: counter 1s linear forwards;
  counter-reset: count 0;
}

.svgContainer {
  height: 50px;
  animation: hideloader 1.5s linear forwards;
}

@keyframes hideloader {
  0% {
    position: relative;
    transform: translateX(0);
  }
  100% {
    position: absolute;
    transform: translateX(5000);
    bottom: 100000px;
  }
}

@keyframes counter {
  0% {
    counter-increment: count 0;
  }
  80% {
    counter-increment: count 70;
  }
  90% {
    counter-increment: count 98;
  }
  100% {
    counter-increment: count 100;
  }
}
</style>
