
import { Component, Vue, Prop } from "vue-property-decorator";
import styled from "vue-styled-components";

const StyledErrorWrapper = styled.span`
  font-size: 12px;
  line-height: 16px;
  font-family: "Lato", sans-serif;
  text-align: left;
  letter-spacing: 0.15px;
  color: #ed1c24;
  display: inline-flex;
  align-items: center;
  margin-top: -10px;

  .div-error {
    font-size: 12px;
    line-height: 14px;
    padding-top: 1px;
  }
`;

const StyledInputControlGroup = styled.div`
  position: relative;

  &.active {
    label:not(.radio-label) {
      font-size: 16px;

      .label-req {
        color: #ed1c24;
      }
    }

    &.select-control {
      label:not(.radio-label) {
        height: 41px;
        padding-bottom: 0px;
        margin-bottom: 0px;
      }
    }
  }

  &.focused {
    .control-area {
      border-color: #55616b;
    }
  }

  &.valid {
    .control-area {
      border-color: #34bebd;
    }
  }

  &.disabled {
    label:not(.radio-label) {
      .label-bg-container {
        background: #f2f2f2;
      }
    }
  }

  &.invalid {
    .control-area {
      border-color: #f5222d;
    }

    label:not(.radio-label) {
      color: #f5222d;
    }
  }

  &.select-control {
    .control-area {
      padding-top: 10px;
      padding-bottom: 10px;
    }
  }

  .control-area {
    margin-bottom: 0;
  }
`;

@Component({
  components: {
    "styled-input-control-group": StyledInputControlGroup,
    "styled-error-wrapper": StyledErrorWrapper,
  },
})
export default class ControlElement extends Vue {
  public get isActive(): boolean {
    return this.isFocused || this.content !== "" || this.alwaysActive;
  }

  public get hasValue(): boolean {
    return this.content !== "";
  }

  public isFocused: boolean = false;

  public setFocus(isFocused: boolean) {
    this.isFocused = isFocused;
  }

  public setContent(content: string) {
    this.content = content;
  }

  @Prop({ type: String, default: "" })
  public id!: string;

  @Prop({ type: String, default: "" })
  public name!: string;

  @Prop({ type: String, default: "" })
  public label!: string;

  public content: string = "";

  public get activeRules(): any {
    const rules: any = {};

    if (this.required) {
      rules.required = true;
    }
    if (this.email) {
      rules.email = true;
    }

    if (this.regex != undefined) {
      rules.regex = this.regex;
    }

    return rules;
  }

  @Prop({ type: Boolean })
  public alwaysActive!: boolean;

  @Prop({ type: Object, default: undefined })
  public validateRules!: any;

  @Prop({ type: Boolean })
  public required!: boolean;

  @Prop({ type: Boolean })
  public disabled!: boolean;

  @Prop({ type: String, default: "input" })
  public controlType!: String;

  @Prop({ type: Boolean })
  public email!: boolean;

  @Prop({ type: Boolean })
  public phone!: boolean;

  @Prop({ type: Boolean })
  public url!: boolean;

  @Prop({ type: Boolean })
  public numeric!: boolean;

  @Prop({ type: Boolean })
  public centre!: boolean;

  @Prop({ type: String })
  public regex!: string;

  @Prop({ type: Array, default: (): unknown[] => [] })
  public validationErrors!: string[];

  @Prop({ type: String })
  public errorMessage!: string;

  @Prop({ type: Boolean, default: false })
  public focusItem!: boolean;
}
