
import { Vue, Prop, Component } from "vue-property-decorator";
const { SimpleSVG } = require("vue-simple-svg");

// components
import CheckBoxState from "../generalUI/CheckBoxState.vue";
// types
import { Recommendation } from "../../types/types";
@Component({ components: { CheckBoxState, SimpleSVG } })
export default class RecommendationItem extends Vue {
  public iconUrl: string = process.env.VUE_APP_ASSETS || "";

  @Prop({ type: Object })
  public rec!: Recommendation;
}
