import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store/store";
import { checkUnsupportedBrowser } from '@/common/utils';

Vue.use(VueRouter);
const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach(async (to, from, next) => {
  const isAuthenticated = store.getters["user/isAuthenticated"];
  const isRequiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  let isLogin = to.fullPath === "/login";
  const isVerify = to.fullPath === "/verify";

  if (checkUnsupportedBrowser() && to.fullPath !== '/unsupported-browser'){
    next({
      path: "/unsupported-browser",
    });
  }

  // if authenticated by token present in url
  if (to.query.token && isAuthenticated) {
    await store.dispatch("user/expireToken").then(() => {
      next({
        path: "/verify",
        query: {
          token: to.query.token,
          cId: to.query.cId,
          src: to.query.cId ? "agent" : undefined,
          redirect: "/",
        },
      });
    });
  } else if (isRequiresAuth && !isAuthenticated) {
    if (to.query.token && !isVerify) {
      next({
        path: "/verify",
        query: {
          token: to.query.token,
          cId: to.query.cId,
          src: to.query.cId ? "agent" : undefined,
          redirect: "/",
        },
      });
    } else if (!to.query.token) {
      next({
        path: "/login",
        query: {
          redirect: isLogin ? "/" : to.fullPath,
        },
      });
    }
  } else {
    next();
  }
  /*else if (isRequiresAuth && !isAuthenticated) {
        // Redirect to the authentication page if the user is not authenticated
        next({
            name: 'login',
            query: { redirect: isLogin ? '/' : to.fullPath }
        });
    } else if (isLogin) {
        // If the user is authenticated, redirect away from the login page
        next({ path: '/' });
    } else if (isAuthenticated && to.query.redirect !== undefined) {
        next({ path: to.query.redirect });
    } else {
        next();
    }*/
});

export default router;
