var render = function render(){var _vm=this,_c=_vm._self._c;return _c('styled-wrapper',{attrs:{"isExpanded":_vm.isExpanded}},[(_vm.isExpanded)?[_vm._l((_vm.flowData),function(flow,index){return _c('styled-flow-status-wrapper',{key:flow.name},[_c('styled-flow-status',[_c('styled-flow-heading-wrapper',{attrs:{"isNextStage":!flow.completed && _vm.activeFlow.id !== flow.id}},[_c('styled-icon-wrapper',[(flow.completed)?_c('StyledIcon',{attrs:{"icon":"fa-solid fa-check-circle","color":"#6ABD40"}}):_vm._e(),(!flow.completed)?_c('flow-number',{attrs:{"isNextStage":!flow.completed && _vm.activeFlow.id !== flow.id}},[_vm._v(_vm._s(index + 1))]):_vm._e()],1),_vm._v(" "+_vm._s(flow.name)+" ")],1),_c('styled-chevron-wrapper',{attrs:{"isExpanded":_vm.expandedFlow === flow.id ||
              (_vm.expandedFlow === undefined &&
                flow.id === _vm.activeFlow.id &&
                !flow.completed)}},[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-chevron-down","size":"xl","color":"#114F62"},on:{"click":function($event){return _vm.expandFlowDetails(flow)}}})],1)],1),_c('styled-flow-content',{attrs:{"contentHeight":flow.actions.length * 45,"isExpanded":_vm.expandedFlow === flow.id ||
            (_vm.expandedFlow === undefined &&
              flow.id === _vm.activeFlow.id &&
              !flow.completed)}},[_c('styled-flow-divider'),(_vm.expandedFlow === flow.id)?_c('styled-flow-stages-wrapper',_vm._l((flow.actions),function(action){return _c('div',{key:action.name},[_c('styled-flow-status',[_c('styled-flow-name-wrapper',{attrs:{"isNextStage":!flow.completed && _vm.activeFlow.id !== flow.id}},[_vm._v(" "+_vm._s(_vm.mapFlowStageToDisplayName(action.id))+" "),(_vm.activeFlowStage && _vm.activeFlowStage.id === action.id)?_c('Pill',{attrs:{"color":"orange"}},[_vm._v("In progress")]):_vm._e(),(
                    _vm.activeFlowStage &&
                      _vm.activeFlowStage.id !== action.id &&
                      !flow.completed &&
                      !action.completed
                  )?_c('Pill',{attrs:{"color":"grey"}},[_vm._v("Not started")]):_vm._e(),(action.completed)?_c('Pill',{attrs:{"color":"green"}},[_vm._v("Completed")]):_vm._e()],1)],1)],1)}),0):_vm._e()],1)],1)}),_c('styled-flow-status-wrapper',[_c('styled-flow-status',[_c('styled-flow-heading-wrapper',{attrs:{"isNextStage":!_vm.hasCompletedApplication}},[_c('styled-icon-wrapper',[(_vm.hasCompletedApplication)?_c('StyledIcon',{attrs:{"icon":"fa-solid fa-check-circle","color":"#6ABD40"}}):_vm._e(),(!_vm.hasCompletedApplication)?_c('flow-number',{attrs:{"isNextStage":!_vm.hasCompletedApplication}},[_vm._v(_vm._s(_vm.flowData.length + 1))]):_vm._e()],1),_vm._v(" Done ")],1)],1)],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }