<template>
  <div>
    <div>
      <TenantRow
        v-for="(v, index) in $v.guarantors.$each.$iter"
        :key="index"
        subjectType="Guarantor"
        :tenantNumber="+index + 1"
        :id="guarantors[index].id"
        :tenant="guarantors[index]"
        :v="v"
        @change="validate"
        :removeGuarantor="removeGuarantor"
      />
      <div class="validationMessage" v-if="!$v.guarantors.minOne">
        You must enter at least the first name and e-mail for each guarantor.
      </div>
    </div>
    <button class="btn add-guarantor" @click="addGuarantor">
      Add Guarantor
    </button>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import { email, required, minLength } from "vuelidate/lib/validators";
import { v4 as uuid } from "uuid";

import TenantRow from "./TenantRow";
// e-mail field:
// valid: this is filled in and name is filled
// valie: this is blank and name is blank
// invalid: this is blank and name is filled

const isValid = (linkedField) => (target, tenantInput) => {
  return (target && tenantInput[linkedField]) || !tenantInput[linkedField];
};

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      guarantors: [{ id: uuid(), firstName: "", lastName: "", email: "" }],
    };
  },
  validations: {
    //ToDo: change property to subjects
    guarantors: {
      minOne: (target, tenantInput) => {
        return tenantInput.guarantors.some((t) => t.firstName && t.email);
      },
      $each: {
        firstName: { required },
        email: { email, required },
      },
    },
  },
  components: {
    TenantRow,
  },
  methods: {
    removeGuarantor(index) {
      this.guarantors.splice(index, 1);
    },
    validate() {
      this.emitTouched();
    },
    addGuarantor() {
      const tenant = { id: uuid(), firstName: "", lastName: "", email: "" };
      this.guarantors.push(tenant);
    },
  },
  mounted() {
    this.emitTouched();
  },
};
</script>
<style scoped>
.validationMessage {
  padding: 0 20px;
}

.add-guarantor {
  background: #f2f2f2;
  color: black;
  border-color: #f2f2f2;
  padding: 20px;
  width: 150px;
}
</style>
