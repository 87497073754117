<template>
  <styled-wrapper>
    <styled-step-name-wrapper>
      <StandardText v-if="isInProgress">
        <B>
          {{ name }}
        </B>
      </StandardText>
      <StandardText v-else-if="onClick !== undefined">
        <InternalLink :onClick="() => onClick(name)">
          {{ name }}
        </InternalLink>
      </StandardText>
      <StandardText v-else>
        {{ name }}
      </StandardText>
    </styled-step-name-wrapper>
    <styled-icon-wrapper v-if="!isNextStep">
      <font-awesome-icon
        v-if="isCompleted"
        icon="fa-solid fa-check-circle"
        color="#6ABD40"
      />
      <font-awesome-icon v-if="isInProgress" icon="fa-solid fa-clock" />
      <SupportingText>{{ displayState }}</SupportingText>
    </styled-icon-wrapper>
  </styled-wrapper>
</template>

<script>
import { flexColumn, flexRow } from "@/common/flex"
import styled from "vue-styled-components"
import { margins } from "@/common/spacing"
import StandardText from "./StandardText"
import InternalLink from "./InternalLink"
import SupportingText from "./SupportingText"

const stepStatuses = {
  completed: "Done",
  nextStep: "Next step",
  inProgress: "In progress",
}

const props = {
  name: String,
  stepState: String,
  onClick: Function,
}

const StyledWrapper = styled("span", props)`
  ${flexColumn({ justifyContent: "center" })}
`
const StyledStepNameWrapper = styled("span", props)`
  margin-bottom: ${margins.xxs};
`
const StyledIconWrapper = styled("span", props)`
  ${flexRow({ alignItems: "center" })}
  gap: ${margins.xxs};
`

export default {
  components: {
    StyledWrapper,
    StyledStepNameWrapper,
    StandardText,
    InternalLink,
    StyledIconWrapper,
    SupportingText,
  },
  computed: {
    isInProgress() {
      return this.stepState === "inProgress"
    },
    isCompleted() {
      return this.stepState === "completed"
    },
    isNextStep() {
      return this.stepState === "nextStep"
    },
    displayState() {
      return stepStatuses[this.stepState]
    },
  },
  props,
}
</script>
