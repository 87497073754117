<template>
  <styled-wrapper />
</template>

<script>
import { greyscaleColorPalette } from "@/common/colors"
import styled from "vue-styled-components"

const StyledWrapper = styled.hr`
  width: 100%;
  border: 0;
  border-bottom: 1px solid ${greyscaleColorPalette.greyscale6};
`
export default {
  components: {
    StyledWrapper,
  },
}
</script>
