
import { Component, Vue } from "vue-property-decorator";

import Modal from "./components/webchat/modals/Modal.vue";
import UnsupportedBrowser from './components/UnsupportedBrowser.vue';

const { ThemeProvider } = require("vue-styled-components");

@Component({
  components: { Modal, ThemeProvider, UnsupportedBrowser },
})
export default class App extends Vue {}
