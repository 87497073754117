var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ValidationProvider',{staticClass:"input-control-validator",attrs:{"tag":"div","rules":_vm.activeRules,"name":_vm.label},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('styled-input-control-group',{staticClass:"input-control-group",class:{
      'active focused': _vm.focusItem === true,
      'select-control': _vm.controlType === 'select',
      centre: _vm.centre,
      active: _vm.isActive,
      focused: _vm.isFocused,
      disabled: _vm.disabled == true,
      valid: _vm.hasValue,
      invalid: errors.length > 0 || _vm.validationErrors.length > 0,
    }},[_c('div',{staticClass:"prefix-slot"},[_vm._t("prefix")],2),_c('div',{staticClass:"control-area"},[_vm._t("control",null,{"setContent":_vm.setContent,"setFocus":_vm.setFocus})],2),_c('label',{attrs:{"for":_vm.id}},[_c('span',{staticClass:"label-bg-container"},[_c('span',{staticClass:"label-bg"},[_vm._v(" "+_vm._s(_vm.label)+" "),(_vm.required)?_c('span',{staticClass:"label-req"},[_vm._v("*")]):_vm._e()])])])]),(_vm.validationErrors.length > 0 || errors.length > 0)?_c('styled-error-wrapper',[_c('span',{staticClass:"div-error"},[_vm._v("i")]),_vm._v(" "+_vm._s(_vm.errorMessage || errors[0] || _vm.validationErrors[0])+" ")]):_vm._e()]}}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }