var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"section"},[_c('div',{staticClass:"container w-container"},[(
        _vm.tokenVerificationStatus === 0 ||
          (_vm.tokenVerificationStatus >= 200 && _vm.tokenVerificationStatus < 300)
      )?_c('div',{staticClass:"modal absolute",attrs:{"id":"modal"}},[_c('div',{staticClass:"verifying-container"},[_vm._m(0),_c('div',{staticClass:"modal-content"},[_c('pulse-loader',{attrs:{"loading":true,"color":"#34bebd"}})],1)])]):(_vm.tokenVerificationStatus === 401)?_c('div',{staticClass:"modal absolute",attrs:{"id":"modal"}},[_vm._m(1),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-content-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('router-link',{staticClass:"button w-button",attrs:{"to":{ name: 'login' }}},[_vm._v("Send me another")])],1)])]):(_vm.tokenVerificationStatus === 403)?_c('div',{staticClass:"modal absolute",attrs:{"id":"modal"}},[_vm._m(2),_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-content-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('router-link',{staticClass:"button w-button",attrs:{"to":{ name: 'login' }}},[_vm._v("Send me another")])],1)])]):_vm._e()])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"modalTitle"}},[_vm._v(" Verifying your magic link... ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"modalTitle"}},[_vm._v("Oops!")]),_c('p',{staticClass:"modal-message"},[_vm._v(" The link we sent to your e-mail has expired. ")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"modal-header"},[_c('h5',{staticClass:"modal-title",attrs:{"id":"modalTitle"}},[_vm._v("Oops!")]),_c('p',{staticClass:"modal-message"},[_vm._v(" The link we sent to your e-mail must be used in the same browser in which it was generated. ")])])
}]

export { render, staticRenderFns }