<template>
  <form class="subject-details-container" ref="modalForm" @submit.prevent="">
    <p>Please enter your full legal name</p>
    <TextBoxElement
      :focusItem="firstName !== ''"
      v-model="firstName"
      id="firstName"
      label="First name(s)"
      name="firstName"
      @inputEvent="validate"
      required
    ></TextBoxElement>

    <TextBoxElement
      :focusItem="middleName !== ''"
      v-model="middleName"
      id="middleName"
      name="middleName"
      label="Middle name(s)"
    ></TextBoxElement>

    <TextBoxElement
      :focusItem="lastName !== ''"
      v-model="lastName"
      id="lastName"
      name="lastName"
      label="Last name(s)"
      @inputEvent="validate"
      required
    ></TextBoxElement>

    <DateInput
      name="dob"
      id="dob"
      :currDOB="dob"
      v-model="dob"
      @date-formatted="updateDOB"
      @date-invalid="invalidDOB"
      required
    />

    <PhoneInput
      :focusItem="mobile !== ''"
      v-model="mobile"
      id="mobile"
      name="mobile"
      label="Contact number"
      @validate="validateMobile"
      @country-changed="mobileDialCodeChanged"
      :validationErrors="[...(!isMobileValid ? ['Invalid phone number'] : [])]"
      required
    />

    <ControlElement
      id="nationality"
      name="nationality"
      label="Nationality"
      controlType="select"
      :required="true"
      errorMessage="Please select your nationality."
      :focusItem="nationality !== ''"
      :alwaysActive="true"
    >
      <template slot="control" slot-scope="{ setFocus }">
        <select
          name="nationality"
          id="nationality"
          v-model="nationality"
          @change="validate"
          v-on:focus="setFocus(true)"
          v-on:blur="setFocus(false)"
          required
          class="dropdown-element"
        >
          <option value selected disabled>Select Nationality</option>
          <option
            v-for="country in model.countries.filter(
              ({ id }) => id === '583100b7-8302-4ceb-86f9-fea3abb047f7'
            )"
            :key="country.id"
            :selected="model.nationality && model.nationality.id === country.id"
            :value="country"
          >
            {{ country.name }}
          </option>
          <option disabled>---</option>
          <option
            v-for="country in model.countries.filter(
              ({ id }) => id !== '583100b7-8302-4ceb-86f9-fea3abb047f7' // GB ID
            )"
            :key="country.id"
            :selected="model.nationality && model.nationality.id === country.id"
            :value="country"
          >
            {{ country.name }}
          </option>
        </select>
      </template>
    </ControlElement>
  </form>
</template>
<script>
import { formMixin } from "@/mixins/formMixin";
import { required } from "vuelidate/lib/validators";
import TextBoxElement from "@/components/generalUI/TextBoxElement.vue";
import DateInput from "@/components/generalUI/DateInput.vue";
import PhoneInput from "@/components/generalUI/PhoneInput.vue";
import ControlElement from "@/components/generalUI/ControlElement.vue";
import { logErrorToDatadog } from "@/common/utils";

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  components: {
    TextBoxElement,
    DateInput,
    PhoneInput,
    ControlElement
  },
  data() {
    return {
      dob: this.model.dob,
      isMobileValid: true,
      isDOBValid: false,
      firstName: this.model.firstName,
      lastName: this.model.lastName,
      middleName: this.model.middleName,
      mobile: this.model.mobile,
      nationality: null,
      mobileMeta: this.model.mobileMeta,
      iconUrl: process.env.VUE_APP_ASSETS
    };
  },
  validations: {
    dob: {
      required,
      isValid: (v, vm) => vm.isDOBValid
    },
    firstName: { required },
    lastName: { required },
    mobile: { required, isValid: (v, vm) => v === "" || vm.isMobileValid },
    nationality: { required }
  },
  methods: {
    mobileDialCodeChanged(metaData) {
      this.mobileMeta = { ...metaData };
    },
    validateMobile(e) {
      this.isMobileValid = e.isValid;
      this.emitTouched();
    },
    updateDOB(e) {
      this.dob = e;
      this.isDOBValid = true;
      this.validate({ target: { name: "dob" } });
    },
    invalidDOB(e) {
      this.isDOBValid = false;
      this.validate({ target: { name: "dob" } });
    },
    findNationality(nationalityCode) {
      return this.model.countries.filter(({ identifiers }) => {
        const lowerIdentifiers = identifiers.map(val => {
          return val.toLowerCase();
        });
        return lowerIdentifiers.includes(nationalityCode.toLowerCase());
      });
    }
  },
  mounted() {
    if (this.model.metaData === undefined) {
      this.metaData = {
        areaCodes: null,
        dialCode: "44",
        iso2: "GB",
        name: "United Kingdom",
        priority: 0
      };
    }

    if (this.model.nationality) {
      let nationalityCode = this.model.nationality;
      let foundNationality = this.findNationality(nationalityCode);

      if (foundNationality.length > 0) {
        this.nationality = foundNationality[0];
      } else {
        logErrorToDatadog(
          "Unable to map nationality code " + nationalityCode + " to a country",
          { nationalityCode }
        );
        // eslint-disable-next-line no-console
        console.log(
          "Unable to map nationality code " + nationalityCode + " to a country"
        );
      }
    }

    this.emitTouched();
  }
};
</script>

<style lang="less" scoped>
.subject-details-container {
  overflow-y: auto;
}

.nameContainer {
  display: flex;
  flex-flow: row wrap;
  div {
    flex: 1 1 30%;
  }
}

.dropdown-element {
  height: 50px;
  color: #55616b;
  grid-row: 1;
  grid-column: 1 / span 3;
  padding: 0px;
  background-color: #ffffff;
  border: none;
  outline: none;
}

.nationality-container {
  margin: 18px 20px;
}

.nationality-content {
  min-height: 28px;
}

#dob {
  border-radius: 5px;
  background-color: #fff;
}

#nationality {
  width: 100%;
}

#mobile {
  margin: 0;
  padding: 22px 20px;
}

.control-area {
  margin: 0 20px;
  margin-bottom: 0;
}

.day-header,
.day,
.day__month_btn {
  color: var(--mainHighlight);
}

p {
  margin: 20px;
}

.error-container {
  padding: 15px;

  li {
    margin: 5px 0;
    display: flex;
    align-items: center;
    column-gap: 5px;
  }

  img {
    width: 14px;
    height: 14px;
  }
}
</style>
