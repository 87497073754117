<template>
  <styled-wrapper>
    <styled-overlay-wrapper />
    <styled-content-wrapper :isMobile="isMobile">
      <styled-heading-wrapper v-if="title">
        <div>
          <H2>
            {{ title }}
          </H2>
        </div>
        <styled-close-button-wrapper id="close-button" @click="handleOnClose">
          <font-awesome-icon
            icon="fa-solid fa-times-circle"
            :color="closeIconColor"
          />
        </styled-close-button-wrapper>
      </styled-heading-wrapper>
      <styled-scrollable-wrapper>
        <slot />
      </styled-scrollable-wrapper>
    </styled-content-wrapper>
  </styled-wrapper>
</template>

<script>
import { flexColumn, flexRow } from "@/common/flex"
import styled from "vue-styled-components"
import { cardLikeStyle } from "./utils"
import { paddings, margins } from "@/common/spacing"
import H2 from "./H2"
import { colors } from "@/common/colors"

export const overlayZindex = 99999

const StyledOverlayWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: #000;
  opacity: 0.5;
  z-index: ${overlayZindex};
`

const StyledWrapper = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: ${overlayZindex + 1};
  ${flexColumn({ alignItems: "center", justifyContent: "center" })}
`

const getMobileWrapperStyles = () => `
  width: 550px;
  height: auto;
  ${flexColumn({ justifyContent: "flex-start" })}
`

const StyledContentWrapper = styled("div", { isMobile: Boolean })`
  ${cardLikeStyle()}
  z-index: 99999;
  padding: ${paddings.m} ${paddings.s};
  width: 100vw;
  max-height: 90%;
  ${flexColumn({ justifyContent: "space-between" })}
  ${(props) => (!props.isMobile ? getMobileWrapperStyles() : "")}
`

const StyledScrollableWrapper = styled.div`
  overflow-y: auto;
`

const StyledHeadingWrapper = styled.div`
  ${flexRow({ justifyContent: "space-between", alignItems: "flex-start" })};
  margin-bottom: ${margins.m};
`

const StyledCloseButtonWrapper = styled.div`
  cursor: pointer;
`

export default {
  props: {
    handleOnClose: {
      type: Function,
      required: true,
    },
    title: String,
  },
  components: {
    StyledWrapper,
    StyledContentWrapper,
    StyledScrollableWrapper,
    StyledHeadingWrapper,
    StyledCloseButtonWrapper,
    StyledOverlayWrapper,
    H2,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 800
    },
    closeIconColor() {
      return colors.textSecondary
    },
  },
}
</script>
