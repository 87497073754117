<template>
  <div>
    <h4>Upload a document</h4>

    <div class="drop-zone" @dragstart="ondrop" @click="$refs.file.click()">
      <div class="drop-info">
        <p>
          {{
            screenWidth < 800
              ? "Tap to take a photo using your device"
              : "Click to upload a file"
          }}
        </p>
        <input
          type="file"
          name="file"
          ref="file"
          id="file"
          class="input text-input w-input"
          :accept="model.accept"
          @change="setFile"
        />

        <SimpleSVG
          v-if="!uploadedImage"
          height="40"
          custom-class-name="icon"
          :src="`${this.botIconsUrl}/user.svg`"
        />

        <img
          v-bind:key="uploadedImage"
          v-if="uploadedImage !== ''"
          :src="uploadedImage"
          id="file-container"
        />
        <p v-if="uploadedImage !== ''">
          Is the whole document visible and in colour?
        </p>
        {{this.fileName}}
         <Loader
      v-if="this.uploadedImage !== ''"
      :filename="this.filename"
      disablePercentage
    >
    </Loader>
      </div>
    </div>
    <div class="retake-container">
      <button
        v-if="uploadedImage !== ''"
        @click="$refs.file.click()"
        class="btn btn-outline"
        id="retake"
      >
        Retake
      </button>
    </div>

  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import Loader from "../../progress/Loader.vue";
import { track } from '@/common/utils';
const { SimpleSVG } = require("vue-simple-svg");

export default {
  components: {
    SimpleSVG,
    Loader
  },
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      file: [],
      uploadedImage: "",
      screenWidth: 801,
      botIconsUrl: "",
      filename:""
    };
  },
  validations: {
    file: { isSelected: (v, vm) => vm.file.length > 0 },
  },
  methods: {
    setFile(e) {
      track("Uploaded- Individual file", {})
      if (e.target.files.length > 0) {
        this.file = e.target.files;
        this.validate(e);
        this.uploadedImage = URL.createObjectURL(e.target.files[0]);
        this.filename = e.target.files[0].name;
      }
    },
    ondrop(e) {
      e.stopPropagation();
      e.preventDefault();
      this.filesDropped(e.dataTransfer.files[0]);
    },
  },
  mounted() {
    track("Displayed - File upload modal", {})
    this.screenWidth = window.innerWidth;
    this.botIconsUrl = process.env.VUE_APP_ASSETS || "";
  },
};
</script>

<style lang="less" scoped>

  .retake-container {
    text-align: center;
  }

#file-container {
  max-height: 200px;
  border-radius: 10px;
  border: 1px dashed var(--mainHighlight);
  animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275) both;
}

.drop-zone {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0;
  margin: 20px auto;
  min-height: 200px;
  height: 200px;
  height: fit-content;
  width: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 0;
  }
  & input {
    opacity: 0; /* invisible */
    display: none;
    width: 1px;
    height: 1px;
    cursor: pointer;
  }

  p {
    margin: 10px 0;
  }



  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }

  & .drop-info {
    pointer-events: none;
    padding: 10px;

    & p {
      text-align: center;
      // font-size: 12px;
      letter-spacing: 0.15px;
      color: #55616b;
    }

    & img {
      max-width: 100%;
      min-height: 40px;
    }
  }
}
</style>