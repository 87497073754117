
const styled = require("vue-styled-components");

import { track } from "@/common/utils";
import { Vue, Prop, Component } from "vue-property-decorator";

interface Props {
  theme: any;
}

const ModalThemeing = styled.default.div<Props>`
  display: flex !important;
  justify-content: flex-end !important;
  align-items: center;

  .btn-alternate {
    background: ${(props: Props) =>
      props.theme.default.secondaryBtnColor || ""} !important;
  }

  .btn-primary {
    background: ${(props: Props) =>
      props.theme.default.mainBtnColor || ""} !important;
      border:none;
  }
`;

@Component({ components: { ModalThemeing } })
export default class ProofOfAddressFooter extends Vue {
  @Prop({ type: Object })
  public model!: any;

  @Prop({ type: Object })
  public v!: object;

  @Prop({ type: Function })
  public close!: Function;

  @Prop({ type: Function })
  public submit!: Function;

  @Prop({ type: Object })
  public stepped!: { current: number | null; stages: number | null };

  @Prop({ type: Boolean })
  public disableSteppedProgression!: boolean;

  public clicked(e: any) {
    const { name } = e.target;

    switch (name) {
      case "back":
        if (this.stepped.current !== 1 || 0) {
          track("Click - Proof of address back", {})
          this.$emit("update-stepped-footer", {
            current: this.stepped.current !== null ? this.stepped.current-- : 0,
            stages: this.stepped.stages,
          });
        }
        break;
      case "nextSubmit":
        if (this.stepped.current !== this.stepped.stages) {
          track("Click - Proof of address next", {})
          this.$emit("update-stepped-footer", {
            current: this.stepped.current !== null ? this.stepped.current++ : 0,
            stages: this.stepped.stages,
          });
        } else {
          if (this.model.submitForm || this.model.submitForm === undefined) {
            track("Click - Proof of address submit", {})
            this.submit()
          } else {
            track("Click - Proof of address close", {})
            this.close()
          }
        }
        break;
      default:
        break;
    }
  }
}
