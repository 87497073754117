
import { Vue, Component, Prop } from "vue-property-decorator";
// types
import { Recommendation } from "../../types/types";
const styled = require("vue-styled-components");
// components
import CheckBoxState from "../generalUI/CheckBoxState.vue";
import DotProgress from "./DotProgress.vue";
import RecommendationItem from "./RecommendationItem.vue";

const CompletedLabel = styled.default.div`
  .hr-text, .hr-text:after{
    color: ${(props: any) =>
      props.theme.default.mainColor || "var(--mainHighlight)"} !important};
  }
  font-weight: 700;
`;
@Component({
  components: {
    DotProgress,
    CheckBoxState,
    RecommendationItem,
    CompletedLabel,
  },
})
export default class Recommendations extends Vue {
  @Prop({ type: Array })
  public recommendations!: Array<Recommendation>;
  @Prop({ type: Object })
  public flows!: object;

  public recIndex: number = 1;

  public get uncompleteRecommendation(): Array<Recommendation> {
    const uncompleteRecs = this.recommendations
      .filter(({ completed }) => {
        return !completed;
      })
      .sort(function(a, b) {
        return a.priority - b.priority;
      })
      .reverse();

    if (uncompleteRecs.length > 0) {
      document.title = `Bot (${uncompleteRecs.length})`;
    } else {
      document.title = "Bot";
    }

    return uncompleteRecs;
  }

  public get completeRecommendation(): Array<Recommendation> {
    return this.recommendations.filter(({ completed }) => {
      return completed;
    });
  }

  public get progressLabel() {
    if (this.completeRecommendation.length === this.recommendations.length) {
      return "";
    }
    return `${this.completeRecommendation.length} out of ${this.recommendations.length} complete`;
  }

  public swipeHandler(direction: string, e: any): void {
    //console.log(direction, e)
    const { innerHTML } = e.target;

    const indexOfRec = this.recommendations.findIndex(
      ({ description }) => description === innerHTML
    );
    if (indexOfRec + 1 === 0) return;
    this.recIndex = indexOfRec + 1;
  }
}
