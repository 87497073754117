<template>
  <div>
    <h4>Identity Document</h4>
    <div
      class="input-wrapper"
      :class="{ hidden: stepped.current !== 1 || hasPassport }"
    >
      <div class="label-container">
        <p>Choose a document to use to verify your identity</p>
      </div>
      <div class="identity-options">
        <label
          class="decorated-radio w-radio"
          id="document-option"
          v-for="(type, index) in model.types"
          :key="`key-${index}`"
        >
          <input
            type="radio"
            :id="`type-${index}`"
            name="type"
            :checked="type.label === 'UK passport' && hasPassport"
            :value="type.label"
            accept="image/x-png,image/png,image/jpeg,application/pdf"
            class="hidden-radio-button w-radio-input"
            @click="showFileContainers(JSON.stringify(type.files), type.label)"
            data-poi-type-radio
          />
          <div class="decorated-radio-border"></div>
          <SimpleSVG
            :src="type.icons.checked"
            custom-class-name="largeIcon radio-icon unchecked"
            alt
          />
          <SimpleSVG
            :src="type.icons.checked"
            custom-class-name="largeIcon radio-icon checked"
            alt
          />
          <span class="field-label decorated-radio-label w-form-label">
            {{ type.label }}
          </span>
        </label>
      </div>
    </div>
    <div
      class="input-wrapper"
      :class="{ hidden: stepped.current === 1 && !hasPassport }"
      v-bind:key="JSON.stringify(fileContainers)"
    >
      <FileUploadCards
        :fileContainers="fileContainers"
        @filechanged="onFileChanged"
      />
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin"
import FileUploadCards from "@/components/file/FileUploadCards"
import {
  addBlockDefaultDragEventBehaviourListeners,
  removeBlockDefaultDragEventBehaviourListeners,
  track,
} from "@/common/utils"
const { SimpleSVG } = require("vue-simple-svg")
import { fileLabelToDisplayNameConfig } from "../../file/utils"

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
    stepped: {
      type: Object,
    },
    hasPassport: {
      type: Boolean,
    },
  },
  data: function() {
    return {
      fileContainers: [],
      selected: [],
      selectedType: "",
    }
  },
  validations: {
    selected: {
      isSelected: (v, vm) => {
        return (
          vm.fileContainers.length !== 0 &&
          vm.selected.length === vm.fileContainers.length &&
          !vm.selected.some((s) => s === undefined)
        )
      },
    },
  },
  methods: {
    showFileContainers(json, label) {
      track(`Click - Select identity option ${label}`)
      this.fileContainers = this.fileContainersToDisplayNames(json)
      this.selectedType = label
      this.touch(this.$v.selected)
      this.$emit("enable-stepped-progression", true)
    },
    fileContainersToDisplayNames(json) {
      const parsedContainers = JSON.parse(json)
      if (parsedContainers && parsedContainers.length > 0) {
        return parsedContainers.map((fileContainer) => {
          fileContainer.label =
            fileLabelToDisplayNameConfig[fileContainer.label] !== undefined
              ? fileLabelToDisplayNameConfig[fileContainer.label]
              : fileContainer.label

          return fileContainer
        })
      } else {
        return parsedContainers
      }
    },
    onFileChanged(e) {
      this.selected = e.selected.filter((s) => s !== undefined)
      this.touch(this.$v.selected)
    },
  },
  watch: {
    fileContainers(value, next) {
      this.selected = []
      this.touch(this.$v.selected)
    },
  },
  unmounted() {
    removeBlockDefaultDragEventBehaviourListeners()
  },
  mounted() {
    addBlockDefaultDragEventBehaviourListeners()
    if (this.hasPassport) {
      track(
        "Displayed - Proof of identity modal with straight to passport flow",
        {}
      )
      if (this.model && this.model.types) {
        this.fileContainers = [
          {
            label: "your passport photo page",
            placeholder:
              "https://vouchchatbot.blob.core.windows.net/assets/passport_eu.svg",
          },
        ]
        this.$emit("enable-stepped-progression", true)
      }
    } else {
      track("Displayed - Proof of identity modal", {})
    }

    this.$emit("update-stepped", {
      current: 1,
      stages: this.hasPassport ? 1 : 2,
    })
  },
  components: {
    FileUploadCards,
    SimpleSVG,
  },
}
</script>

<style lang="less" scoped>
.horizontal-flex-box label {
  width: auto;
  padding-right: 12px;
}

.identity-options {
  grid-template-columns: 1fr;
  display: grid;
  grid-gap: 10px;
}
</style>

<style lang="less" scoped>
#document-option:hover {
  cursor: pointer;
}

.label-container {
  margin: 20px 0;
  p {
    margin: 10px 0;
    text-align: left;
  }
}

.field-label.decorated-radio-label {
  color: #808284;
  font-weight: 400;
}

h4 {
  margin-top: 10px;
  max-width: 450px;
}
</style>
