<template>
  <div>
    <h2>Please select your bank account 🏦</h2>
    <input type="hidden" :value="model.provider" name="provider" />
    <div
      v-for="(acc, index) in model.accounts"
      :key="index"
      class="form-check"
      @click="onAccountSelected(acc.account_id)"
    >
      <input
        class="form-check-input"
        type="radio"
        name="account"
        :id="acc.account_id"
        :value="acc.account_id"
        @click="onAccountSelected(acc.account_id)"
      />
      <label class="form-check-label" :for="acc.account_id">
        {{ acc.display_name }}
        <span v-if="acc.account_number.number"
          >({{ acc.account_number.number }},
          {{ acc.account_number.sort_code }})</span
        >
      </label>
      <div class="side-by-side-flex-end" v-if="account === acc.account_id">
        <img src="/assets/circle-tick.svg" style="margin-right: 5px" />
        <h5>Selected</h5>
      </div>
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      account: "",
    };
  },
  validations: {
    account: { required },
  },
  methods: {
    onAccountSelected(accountId) {
      this.account = accountId;
      if (accountId !== "") {
        this.emitTouched();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.form-check {
  margin: 10px;
  box-shadow: rgb(0 0 0 / 25%) 0px 0px 15px 1px;
  border-radius: 5px;
  background: #f2f2f2;
  padding: 20px;
  min-height: 103px;
}

.form-check-input:hover,
.form-check-label:hover {
  cursor: pointer;
}

.form-check:hover {
  cursor: pointer;
  background: #e7e6e6;
}

label {
  color: #082842;
  text-align: left;
}
</style>
