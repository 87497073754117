<template>
  <div>
    <h3>{{ this.model.text }}</h3>
    <button class="btn btn-primary" @click="authenticate">
      {{ this.model.title }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    close: {
      type: Function,
      required: true,
    },
    model: {
      type: Object,
      required: true,
    },
  },
  methods: {
    authenticate() {
      this.$auth.authenticate(this.model.provider, {
        from: "dl_testTenant",
        conversation: "random-id-a",
      });
      this.close();
    },
  },
};
</script>
