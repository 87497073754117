<template>
  <div>
    <div
      class="form-group"
      v-for="(v, index) in $v.transactions.$each.$iter"
      :key="index"
    >
      <h5 :for="`transaction_${transactions[index].id}`">
        {{ transactions[index].description }} (£{{
          transactions[index].amount
        }}) on
        <span id="date-highlight">{{
          niceDate(transactions[index].timestamp)
        }}</span>
      </h5>
      <select
        :name="`transaction_${transactions[index].id}`"
        class="form-control classification-drop-down"
        :id="transactions[index].vouchTransactionId"
        v-model="transactions[index].classification"
        @change="touch(v.classification)"
      >
        <option value="" selected disabled>Select an option...</option>
        <option value="salaryWage">Salary/Wage</option>
        <option value="salaryWage">Rental Income</option>
        <option value="salaryWage">Bonus</option>
        <option value="dividend">Dividend</option>
        <option value="dividend">Investment</option>
        <option value="rent">Rent</option>
        <option value="mortgage">Mortgage</option>
        <option value="loan">Loan Payment</option>
        <option value="health">Health</option>
        <option value="utilities">Utilities</option>
        <option value="shopping">Shopping</option>
        <option value="food">Food</option>
        <option value="travel">Travel</option>
        <option value="betting">Betting</option>
      </select>
      <div v-if="v.classification.$error">
        You must select a transaction type.
      </div>
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  methods: {
    niceDate(uglyDate) {
      return (
        new Intl.DateTimeFormat(new Date(uglyDate)).format() || "No Date Found"
      );
    },
  },
  data() {
    return {
      transactions: this.model.transactions,
    };
  },
  validations: {
    transactions: {
      $each: {
        classification: { required },
      },
    },
  },
};
</script>
<style lang="less" scoped>
.classification-drop-down {
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}

#date-highlight {
  color: var(--mainHighlight);
}
</style>
