import {
  faCheckCircle,
  faClock,
  faChevronRight,
  faFileText,
  faSpinner,
  faCircleCheck,
  faXmark,
  faInfoCircle,
  faTimesCircle,
  faChevronUp,
  faChevronDown,
  faAddressCard,
  faBuilding,
  faCreditCard,
  faListOl,
} from "@fortawesome/free-solid-svg-icons"

export default [
  faCheckCircle,
  faClock,
  faChevronRight,
  faFileText,
  faSpinner,
  faCircleCheck,
  faXmark,
  faInfoCircle,
  faTimesCircle,
  faChevronUp,
  faAddressCard,
  faBuilding,
  faCreditCard,
  faChevronDown,
  faListOl,
]
