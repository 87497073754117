
import { Vue, Component, Prop } from "vue-property-decorator";
const styled = require("vue-styled-components");

const RadioWrapper = styled.default.div`
  #checkbox-status::before, #checkbox-status:checked + label::before{
    border-color: ${(props: any) => props.theme.default.mainColor};
  }

  #checkbox-status:checked + label::after{
    background: ${(props: any) => props.theme.default.mainColor};
  }
`;
@Component({ components: { RadioWrapper } })
export default class CheckBoxState extends Vue {
  @Prop({ type: Boolean })
  public state!: boolean;
}
