import App from './App.vue'
import Vue from 'vue'
import Vuelidate from 'vuelidate'
import auth from './auth/auth'
import store from './store/store'
import router from './routes/router'
import { ValidationProvider } from 'vee-validate'
import { ValidationObserver } from 'vee-validate'
import Vue2TouchEvents from 'vue2-touch-events'
import * as rules from 'vee-validate/dist/rules'
import { extend } from 'vee-validate'
import { datadogLogs } from '@datadog/browser-logs'
import { UserObj } from './store/modules/webchat'
import { injectChat } from './common/chat'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import supportedIcons from './components/design-system/utils/supportedIcons'
import VTooltip from 'v-tooltip'
import VueTelInput from 'vue-tel-input'
//@ts-ignore
import VueSimpleSVG from 'vue-simple-svg'
//@ts-ignore
import Datepicker from 'vuejs-datepicker'

supportedIcons.map(icon => {
  library.add(icon)
})

if (process.env.VUE_APP_DATADOG_CLIENT_TOKEN !== undefined) {
  datadogLogs.init({
    clientToken: process.env.VUE_APP_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.com',
    service: `vouch-bot-fe-${process.env.VUE_APP_ENVIRONMENT}`,
    forwardErrorsToLogs: true,
    sampleRate: 100,
  })
}

for (let [rule, validation] of Object.entries(rules)) {
  extend(rule, {
    ...validation,
  })
}

Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

Vue.config.productionTip = false
Vue.use(VTooltip)
Vue.use(Vuelidate)
// @ts-ignore
Vue.use(VueTelInput, { defaultCountry: 'GB' })
Vue.use(Vue2TouchEvents)

Vue.use(VueSimpleSVG)

Vue.use(Datepicker)

const main = async () => {
  // Try to log the user in using the current authentication cookie, if any.
  await store.dispatch('user/tryAuthenticate')

  const userObj: UserObj = store.getters['webchat/userObj']
  if (userObj !== undefined) {
    injectChat(userObj)
  }

  new Vue({
    store,
    router,
    render: h => h(App),
  }).$mount('#app')
}

auth()
main()
