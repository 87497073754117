import { windowRef } from "../utils"
import { UserObj } from "../../store/modules/webchat"

interface EmbeddedChatService {
    isIframeReady: boolean
    settings: EmbeddedChatSettings
    init(
      url: string,
      helpUrl: string,
      gslbBaseUrl: string,
      orgId: string,
      chatId: string,
      settings: { [key: string]: string | boolean },
    ): void
    hideHelpButton(): void
    showHelpButton(): void
    addEventHandler(event: string, callback: () => void): void
  }
  
  interface EmbeddedChatSettings {
    displayHelpButton: boolean
    language: string
    defaultMinimizedText?: string
    disabledMinimizedText?: string
    loadingText: string
    enabledFeatures?: string[]
    entryFeature: string
    prepopulatedPrechatFields: { [key: string]: string | undefined | null }
    extraPrechatFormDetails: ExtraPreChatFormFields[]
  }
  
  interface ExtraPreChatFormFields {
    label: string
    value?: string | number | undefined | null
    displayToAgent: boolean
    transcriptFields: string[]
  }
  
  let loaded = false
  
  const ConfigureChat = (chatData: UserObj) => {
    const extraFormDetails: ExtraPreChatFormFields[] = [
      {
        label: 'First Name',
        displayToAgent: true,
        transcriptFields: ['Supplied_First_Name__c'],
      },
      {
        label: 'Last Name',
        displayToAgent: true,
        transcriptFields: ['Supplied_Last_Name__c'],
      },
      {
        label: 'Email',
        displayToAgent: true,
        transcriptFields: ['Supplied_Email__c'],
      },
      {
        label: 'SubjectId',
        value: chatData.id,
        displayToAgent: false,
        transcriptFields: ['Subject_Id__c'],
      },
      {
        label: 'ApplicationId',
        value: chatData.applicationId,
        displayToAgent: false,
        transcriptFields: ['Application_Id__c'],
      }
    ]
  
    const service = windowRef.embedded_svc as EmbeddedChatService
  
    service.settings.displayHelpButton = false
    
    service.settings.language = 'en'
  
    service.settings.defaultMinimizedText = 'Help'
    service.settings.disabledMinimizedText = 'Offline'
    service.settings.loadingText = 'Loading...'
  
    service.settings.enabledFeatures = ['LiveAgent']
    service.settings.entryFeature = 'LiveAgent'
  
    service.settings.extraPrechatFormDetails = extraFormDetails
  
    if (!loaded) {
      service.addEventHandler('onSettingsCallCompleted', () => loaded = true)
      service.init(
        'https://goodlord.my.salesforce.com',
        'https://tenanthelp.goodlord.co/',
        'https://service.force.com',
        '00D58000000YUgS',
        'Tenant_Chat_ESD',
        {
          baseLiveAgentContentURL: 'https://c.la1-c2-lo2.salesforceliveagent.com/content',
          deploymentId: '5724I000000PEbB',
          buttonId: '5734I000000PF7G',
          baseLiveAgentURL: 'https://d.la1-c2-lo2.salesforceliveagent.com/chat',
          eswLiveAgentDevName:
            'EmbeddedServiceLiveAgent_Parent04I4I000000GmjWUAS_1730fe8f971',
          isOfflineSupportEnabled: false,
        },
      )
      return
    }
  
    service.showHelpButton()
  }
  
  export const injectChat = (chatData: UserObj) => { 
    if (windowRef !== undefined && !windowRef.embedded_svc) {
      const s = document.createElement('script')
      s.setAttribute(
        'src',
        'https://goodlord.my.salesforce.com/embeddedservice/5.0/esw.min.js',
      )
      s.onload = () => ConfigureChat(chatData)
      document.body.appendChild(s)
    } else {
      // if chat already exists re initialize the variables
      ConfigureChat(chatData)
    }
  }