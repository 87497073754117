
// Default modal view
import ModalForm from "./ModalForm.vue";
import OverallProgress from "../../progress/OverallProgress.vue";

import { Vue, Component, Prop } from "vue-property-decorator";

@Component({ components: { ModalForm, OverallProgress } })
export default class Modal extends Vue {
  @Prop({ type: Object })
  public model: any;

  @Prop({ type: Object })
  public progress: any;

  @Prop({ type: Object })
  public themeOptions: any;

  public closed() {
    this.$emit("closed", true);
  }
}
