<template>
  <div class="input-wrapper">
    <label class="field-label" for="type">Benefit type</label>
    <select
      name="type"
      id="type"
      class="address-select"
      v-model="type"
      @change="validate"
    >
      <option value="" disabled>Select an option...</option>
      <option
        v-for="(type, index) in model.types"
        :key="index"
        :value="type.id"
      >
        {{ type.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      type: "",
    };
  },
  validations: {
    type: { required },
  },
};
</script>

<style lang="less" scoped>
#type {
  background: white;
}

.address-select {
  padding: 1.25rem 0.25rem;
  border-radius: 5px;
  width: -webkit-fill-available;
  margin: 20px 0;
}
.address-select:before {
  padding: 0 0.5rem;
}

p {
  text-align: left;
  margin: 0 20px;
}
</style>