<template>
  <styled-wrapper
    :type="type"
    @click="!loading && !disabled ? onClick() : () => undefined"
    :disabled="disabled"
    :isFullWidth="isMobile"
  >
    <icon-wrapper v-if="icon" :loading="loading"
      ><font-awesome-icon :icon="icon" color="white"
    /></icon-wrapper>
    <slot v-if="!loading"/>
    <spinner-wrapper v-if="loading">
      <font-awesome-icon
        icon="fa-solid fa-spinner"
        color="white"
        spin
      /> </spinner-wrapper
  ></styled-wrapper>
</template>

<script>
import styled from "vue-styled-components"
import {
  resetStyles,
  baseTextStyle,
  ellipsisStyle,
  baseButtonStyle,
} from "./utils"
import { fontSizes, fontWeights } from "@/common/fonts"
import { colors, greyscaleColorPalette } from "@/common/colors"
import { margins } from "@/common/spacing"

const props = {
  type: String,
  isFullWidth: { type: Boolean, default: false },
  onClick: Function,
  disabled: Boolean,
  loading: Boolean,
  icon: String,
}

const StyledWrapper = styled("div", props)`
  ${resetStyles()};
  ${baseButtonStyle()};
  color: ${colors.buttonSecondary};
  font-weight: ${fontWeights.bold};
  background-color: ${(props) =>
    !props.disabled ? colors.buttonPrimary : greyscaleColorPalette.greyscale6};
  width: ${(props) => (props.isFullWidth ? "100%" : "")};
`

const IconWrapper = styled("span", props)`
  ${resetStyles()};
  margin-right: ${margins.xs};
  visibility: ${props.loading ? "hidden" : "visible"};
`

const SpinnerWrapper = styled("span", props)`
  ${resetStyles()};
  visibility: ${!props.loading ? "hidden" : "visible"};
`

export default {
  components: {
    StyledWrapper,
    IconWrapper,
    SpinnerWrapper,
  },
  props,
  computed: {
    isMobile() {
      return window.innerWidth < 800
    },
  },
}
</script>
