const state = {
  uri: undefined,
  container: undefined,
  sas: undefined,
}

interface State {
  uri: any
  container: any
  sas: any
}

const mutations = {
  uri(state: State, payload: any) {
    state.uri = payload
  },
  container(state: State, payload: any) {
    state.container = payload
  },
  sas(state: State, payload: any) {
    state.sas = payload
  },
}

const actions = {
  registerStorage({ commit }: any, payload: any) {
    commit("uri", payload.uri)
    commit("container", payload.container)
    commit("sas", payload.sas)
  },
  unregisterStorage({ commit }: any) {
    commit("uri", undefined)
    commit("container", undefined)
    commit("sas", undefined)
  },
}

const getters = {
  uri(state: State) {
    return state.uri
  },
  container(state: State) {
    return state.container
  },
  sas(state: State) {
    return state.sas
  },
  isStorageRegistered(state: State) {
    return (
      state.uri !== undefined &&
      state.container !== undefined &&
      state.sas !== undefined
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
