<template>
  <div>
    <input type="hidden" name="nextAction" ref="nextAction" value="" />

    <h3>Journey Progress</h3>
    <div v-for="f in journey.flows" :key="f.id">
      <h4>{{ f.displayName }}</h4>
      <ul>
        <li v-for="a in f.actions" :key="a.id">
          <h5>{{ a.displayName }}</h5>
          <h6 v-if="a.completed">Completed</h6>
          <h6 v-else>Not complete</h6>
          <button @click="startAction(a.id)">Execute</button>
        </li>
      </ul>
    </div>
    <hr />
    <h3>Recommendations</h3>
    <div>
      <ul>
        <li v-for="r in recommendations" :key="r.id">
          <h5>{{ r.data.displayName }}</h5>
          <p v-if="r.data.description">{{ r.data.description }}</p>
          <button @click="startAction(r.id)">Execute</button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      default: undefined,
    },
    submit: {
      type: Function,
      required: true,
    },
  },
  data: function() {
    return {
      journey: this.model.journey,
      recommendations: this.model.recommendations,
    };
  },
  methods: {
    startAction(actionId) {
      this.$refs.nextAction.value = actionId;
      this.submit();
    },
  },
};
</script>
