import axios from '@/axios/chatbot'
import { CommitAndDispatch } from '../user'
import { logErrorToDatadog } from '@/common/utils'

// End goal is to have all user actions with types and tests in this file.
export interface GenerateTokenPayload {
  email: string
  redirect: string | (string | null)[]
}

export const generateToken = async (
  { commit, dispatch }: CommitAndDispatch,
  { email, redirect }: GenerateTokenPayload,
) => {
  dispatch('webchat/unregisterWebchat', undefined, { root: true })
  dispatch('storage/unregisterStorage', undefined, { root: true })
  // running the generation
  commit('tokenGenerationStatus', 10)
  try {
    const response = await axios().post('/tokens/generate', {
      email: email,
      redirect: redirect,
    })

    commit('tempToken', response.data.token)
    commit('tempRedirect', response.data.redirect)
    commit('email', email)
  } catch (err) {
    logErrorToDatadog('Generate token error', { errorBody: err as Error })
    commit('tokenGenerationStatus', 401)
    return
  }
  commit('tokenGenerationStatus', 200)
}
