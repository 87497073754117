<template>
  <div id="filesWrapper" class="input-wrapper">
    <div class="label-container">
      <ul v-if="fileContainers.length === 2">
        <p>For a successful photo, please make sure:</p>
        <li>Your document is a PNG, JPEG or PDF</li>
        <li>Upload both sides of your document</li>
        <li>Ensure there is no reflection or glare and content is visible</li>
        <li>Make sure your face is nice and clear</li>
      </ul>
      <ul v-else>
        <p>For a successful photo, please make sure:</p>
        <li>Your document is a PNG, JPEG or PDF</li>
        <li>Ensure the whole document is visible</li>
        <li>Make sure your face is nice and clear</li>
      </ul>
    </div>
    <div id="files" class="horizontal-flex-box">
      <div :key="`wrapper-${index}`">
        <template v-for="(file, index) in fileContainers" id="file-capture">
          <div
            @drop="ondrop($event, index)"
            @click="clickFileInput(index)"
            :key="`image-${index}`"
            class="image-upload-wrapper"
          >
            <!-- <div> -->
            <input
              @change="fileChanged($event, index)"
              :key="`input-${index}`"
              :ref="`file-${index}`"
              :id="`file-${index}`"
              :name="`file-${index}`"
              type="file"
              accept="image/x-png,image/png,image/jpeg,application/pdf"
              class="file-uploaders"
              style="position: absolute; height: 0px; margin-top: -1000px"
            />
            <StyledDropZoneIndicator>
              <div
                :id="`file-${index}-wrapper`"
                v-bind:key="`stringy-${index}`"
                class="image-preview-wrapper"
              >
                <transition
                  enter-active-class="animated fadeIn"
                  leave-active-class="animated fadeOut"
                  mode="out-in"
                >
                  <FileUploadSummary
                    v-if="selected[index] === undefined"
                    :key="`file-${index}-placeholder`"
                    :id="`file-${index}-placeholder`"
                    :fileTitle="file.label"
                  />
                  <img
                    v-else
                    :key="`file-${index}-preview`"
                    :id="`file-${index}-preview`"
                    :src="selected[index]"
                    class="image-preview"
                    alt=""
                  />
                </transition>
              </div>
            </StyledDropZoneIndicator>

            <!-- </div> -->
            <Loader
              v-if="fileNames[index] !== undefined"
              :key="file"
              :filename="fileNames[index]"
            ></Loader>
          </div>
          <div
            class="image-preview-controls"
            v-bind:key="`image-${index}-prev-controls-key`"
          >
            <button
              v-if="selected[index] !== undefined"
              @click="$refs[`file-${index}`][0].click()"
              class="btn btn-outline"
              id="retake"
            >
              Retake
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import FileUploadSummary from "../generalUI/FileUploadSummary.vue"
import Loader from "../progress/Loader"

import styled from "vue-styled-components"
import { flexColumn } from "@/common/flex"
import { paddings } from "@/common/spacing"
import { isSupportedFileType } from "./utils"

const StyledDropZoneIndicator = styled.div`
  ${flexColumn({ alignItems: "center", justifyContent: "center" })}
  border: 2px dashed #bdbdbd;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  padding-left: ${paddings.m};
  padding-right: ${paddings.m};
`

export default {
  props: {
    fileContainers: {
      type: Array,
      required: true,
    },
    selectedFileType: String,
  },
  components: {
    Loader,
    FileUploadSummary,
    StyledDropZoneIndicator,
  },
  data: function() {
    return {
      selected: this.fileContainers.map(() => undefined),
      screenWidth: 801,
      fileNames: [],
      index: 0,
    }
  },
  watch: {
    fileContainers() {
      this.selected = this.fileContainers.map((v, i) =>
        i < this.selected.length ? this.selected[i] : undefined
      )
      this.emitFileChanged()
    },
  },
  methods: {
    clickFileInput(index, event) {
      let fileInputRef = `file-${index}`
      this.$refs[fileInputRef][0].click()
      this.fileChanged(event, index)
    },
    clearFileInput(e) {
      let fileInputRef = `file-${index}`
      this.$refs[fileInputRef][0].value = ""
      this.$set(this.selected, index, undefined)
      this.emitFileChanged()
    },
    ondrop(e, index) {
      e.stopPropagation()
      e.preventDefault()
      if (e.dataTransfer.files.length > 0) {
        let reader = new FileReader()
        reader.onload = () => {
          this.$set(this.selected, index, reader.result)
          this.emitFileChanged()
        }

        if (isSupportedFileType(e.dataTransfer.files[0].type)) {
          reader.readAsDataURL(e.dataTransfer.files[0])
          this.fileNames[index] = e.dataTransfer.files[0].name

          const fileInputNode = document.getElementById(`file-${index}`)
          fileInputNode.files = e.dataTransfer.files
        }
      }
    },
    fileChanged(event, index) {
      if (event && event.target.files && event.target.files.length > 0) {
        let reader = new FileReader()
        reader.onload = () => {
          this.$set(this.selected, index, reader.result)
          this.emitFileChanged()
        }
        reader.readAsDataURL(event.target.files[0])
        this.fileNames[index] = event.target.files[0].name
      } else {
        this.fileNames = []
        this.$set(this.selected, index, undefined)
        this.emitFileChanged()
      }
    },
    emitFileChanged() {
      this.$emit("filechanged", { selected: this.selected })
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth
  },
}
</script>

<style lang="less">
.file-uploaders {
  opacity: 0;
}

.label-container {
  ul {
    list-style: none;
    padding: 10px 0;
    text-align: left;
    li {
      // font-size: 12px;
      margin: 10px 0;
      list-style-position: inside;
    }
  }

  ul li::before {
    content: "\2022";
    color: var(--mainHighlight);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: 1em;
  }
}
#retake {
  height: 40px;
  line-height: 0;
  margin-bottom: 50px;
}

.image-upload-wrapper {
  margin-bottom: 20px;
  border-radius: 15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 14px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: none;
  border-width: 1px;
  border-color: rgba(8, 40, 66, 0.35);
  border-radius: 15px;
  background-color: #f1f2f2;
}

.image-preview {
  border-radius: 5px;
}

.image-preview-wrapper {
  img {
    min-height: 200px;
    border-radius: 15px;
    border: 2px dashed var(--mainHighlight);
    object-fit: cover;
    max-width: 300px;
  }

  max-width: 500px;
}

.image-preview-label {
  margin-top: 10px;
  margin-bottom: 10px;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  font-family: Montserrat, sans-serif;
  /* color: #808284; */
  // font-size: 12px;
  font-weight: 500;
  text-align: center;
  /* border: 10px solid orange; */
}

.image-preview-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 208px;
  padding: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.image-preview-wrapper.dashed-border {
  border-style: dashed;
  border-width: 2px;
  border-color: #bcbec0;
  border-radius: 15px;
}
</style>
