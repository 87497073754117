<template>
  <div class="calendar-inputs-container" v-if="model.dateRange != true">
    <CalenderInput
      v-bind:key="index"
      v-for="(date, index) in dates"
      :index="index"
      :date="date"
      @dateInputted="selectDate"
      :disabledDates="disabledDates(date, index)"
    />
  </div>
  <div class="calendar-inputs-container" v-else>
    <div>
      <h5 v-if="model.dateRangeMinLabel">{{ model.dateRangeMinLabel }}</h5>
      <CalenderInput
        :index="0"
        :date="dates[0]"
        @dateInputted="selectDate"
        :disabledDates="disabledDates(dates[0], 0)"
      />
    </div>
    <div>
      <h5 v-if="model.dateRangeMaxLabel">{{ model.dateRangeMaxLabel }}</h5>
      <CalenderInput
        :index="1"
        :date="dates[1]"
        :disabled="dates[0].date === undefined"
        @dateInputted="selectDate"
        :disabledDates="disabledDates(dates[1], 1)"
      />
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import { required } from "vuelidate/lib/validators";

import CalenderInput from "@/components/generalUI/CalendarInput.vue";

const MILLISECONDS_IN_DAY = 86400000;

export default {
  mixins: [formMixin],
  components: {
    CalenderInput,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      dates: [],
    };
  },
  methods: {
    selectDate({ date, index }) {
      const copy = [...this.dates];
      if (
        this.model.dateRange == true &&
        index === 0 &&
        this.dates[0].date &&
        this.dates[1].date
      ) {
        const minDate = Math.floor(date.getTime() / 1000);
        const maxDate = Math.floor(copy[1].date.getTime() / 1000);
        if (minDate && maxDate && minDate > maxDate) {
          date = undefined;
        }
      }
      copy[index].date = date;
      this.dates = [...copy];
    },
    disabledDates(date, index = 0) {
      function convertDate(iso) {
        switch (iso.toLowerCase()) {
          case "today":
            return new Date(Date.now());
          case "yesterday":
            return new Date(Date.now() - MILLISECONDS_IN_DAY * 2);
          case "tomorrow":
            return new Date(Date.now() + MILLISECONDS_IN_DAY * 2);
          default:
            return new Date(iso);
        }
      }

      if (this.model.dateRange == true) {
        let min = this.model.dateRangeMin;
        let max = this.model.dateRangeMax;
        if (index == 0) {
          if (min) {
            min = convertDate(min);
          }

          if (max) {
            max = convertDate(max);
          }

          return {
            from: this.dates[1].date || max,
            to: min && min,
          };
        }
        if (this.dates[0].date) {
          let firstDate = new Date(this.dates[0].date);
          firstDate = new Date(firstDate.setDate(firstDate.getDate() + 1));
          let nextAvailbeDate;

          if (this.model.dateRangeMin) {
            nextAvailbeDate = convertDate(this.model.dateRangeMin);
          }
          return {
            to: nextAvailbeDate > firstDate ? nextAvailbeDate : firstDate,
          };
        }
      }

      if (date.minDate) {
        date.minDate = convertDate(date.minDate);
      }

      if (date.maxDate) {
        date.maxDate = convertDate(date.maxDate);
      }

      return {
        from: date.maxDate && date.maxDate,
        to: date.minDate && date.minDate,
      };
    },
  },
  watch: {
    dates: {
      handler: function() {
        this.touch(this.$v.dates);
      },
      deep: true,
    },
  },
  validations() {
    return {
      dates: {
        $each: {
          isValidDate: (value, obj) => {
            if (!value || !value.date) {
              return false;
            }
            return true;
          },
        },
      },
    };
  },

  mounted() {
    // only need to prefill 2 dates if prompt is a date range
    if (this.model.dateRange === true) {
      this.dates = [{ date: undefined }, { date: undefined }];
    } else {
      this.dates = this.model.dates.map((dateOptions) => {
        return { ...dateOptions, date: undefined };
      });
    }
  },
};
</script>

<style lang="less" scoped>
.calendar-inputs-container {
  min-height: 60vh;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}
</style>
