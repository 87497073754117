<template>
  <styled-wrapper
    :href="safeHref"
    :rel="rel"
    :target="target"
    @click="handleOnClick"
    ><slot
  /></styled-wrapper>
</template>

<script>
import styled from "vue-styled-components"
import { resetStyles, isUrlSafe, baseLinkStyle } from "./utils"
import { fontSizes } from "@/common/fonts"
import { colors } from "@/common/colors"

const props = {
  onClick: Function,
  href: String,
  target: String,
  rel: String,
}

const StyledWrapper = styled("a", props)`
  ${resetStyles()}
  ${baseLinkStyle()}
  color: ${colors.linkTertiary};
  &:hover,
  &:active,
  &:focus {
    color: ${colors.linkTertiaryDark};
  }
`

export default {
  components: {
    StyledWrapper,
  },
  props,
  computed: {
    safeHref() {
      return isUrlSafe(this.href) ? this.href : ""
    },
  },

  methods: {
    handleOnClick(e) {
      if (this.safeHref === "" && this.onClick) {
        e.preventDefault()
        this.onClick(e)
      } else if (this.onClick) {
        this.onClick()
      }
    },
  },
}
</script>
