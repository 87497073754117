<template>
  <styled-wrapper :isExpanded="isExpanded">
    <template v-if="isExpanded">
      <styled-flow-status-wrapper
        :key="flow.name"
        v-for="(flow, index) in flowData"
      >
        <styled-flow-status>
          <styled-flow-heading-wrapper
            :isNextStage="!flow.completed && activeFlow.id !== flow.id"
          >
            <styled-icon-wrapper>
              <StyledIcon
                v-if="flow.completed"
                icon="fa-solid fa-check-circle"
                color="#6ABD40"
              />
              <flow-number
                v-if="!flow.completed"
                :isNextStage="!flow.completed && activeFlow.id !== flow.id"
                >{{ index + 1 }}</flow-number
              >
            </styled-icon-wrapper>

            {{ flow.name }}
          </styled-flow-heading-wrapper>
          <styled-chevron-wrapper
            :isExpanded="
              expandedFlow === flow.id ||
                (expandedFlow === undefined &&
                  flow.id === activeFlow.id &&
                  !flow.completed)
            "
          >
            <font-awesome-icon
              icon="fa-solid fa-chevron-down"
              size="xl"
              color="#114F62"
              @click="expandFlowDetails(flow)"
            />
          </styled-chevron-wrapper>
        </styled-flow-status>
        <styled-flow-content
          :contentHeight="flow.actions.length * 45"
          :isExpanded="
            expandedFlow === flow.id ||
              (expandedFlow === undefined &&
                flow.id === activeFlow.id &&
                !flow.completed)
          "
        >
          <styled-flow-divider />
          <styled-flow-stages-wrapper v-if="expandedFlow === flow.id">
            <div v-for="action in flow.actions" :key="action.name">
              <styled-flow-status>
                <styled-flow-name-wrapper
                  :isNextStage="!flow.completed && activeFlow.id !== flow.id"
                >
                  {{ mapFlowStageToDisplayName(action.id) }}
                  <Pill
                    color="orange"
                    v-if="activeFlowStage && activeFlowStage.id === action.id"
                    >In progress</Pill
                  >
                  <Pill
                    color="grey"
                    v-if="
                      activeFlowStage &&
                        activeFlowStage.id !== action.id &&
                        !flow.completed &&
                        !action.completed
                    "
                    >Not started</Pill
                  >
                  <Pill color="green" v-if="action.completed">Completed</Pill>
                </styled-flow-name-wrapper>
              </styled-flow-status>
            </div>
          </styled-flow-stages-wrapper>
        </styled-flow-content>
      </styled-flow-status-wrapper>
      <styled-flow-status-wrapper>
        <styled-flow-status>
          <styled-flow-heading-wrapper :isNextStage="!hasCompletedApplication">
            <styled-icon-wrapper>
              <StyledIcon
                v-if="hasCompletedApplication"
                icon="fa-solid fa-check-circle"
                color="#6ABD40"
              />
              <flow-number
                v-if="!hasCompletedApplication"
                :isNextStage="!hasCompletedApplication"
                >{{ flowData.length + 1 }}</flow-number
              >
            </styled-icon-wrapper>
            Done
          </styled-flow-heading-wrapper>
        </styled-flow-status>
      </styled-flow-status-wrapper>
    </template>
  </styled-wrapper>
</template>

<script>
import { margins, paddings } from "@/common/spacing"
import styled from "vue-styled-components"
import { mapFlowIdToDisplayName, mapFlowStageToDisplayName } from "./utils"
import Pill from "@/components/design-system/Pill.vue"
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import { checkHasComplete } from "@/common/utils"
import { colors, greyscaleColorPalette } from "@/common/colors"

const StyledIconWrapper = styled("div", { isExpanded: Boolean })`
  display: flex;
  align-items: center;
  justify-content: center;
`
const StyledIcon = styled(FontAwesomeIcon)`
  height: 22px;
  width: 22px;
`

const StyledFlowContent = styled("div", {
  isExpanded: Boolean,
  contentHeight: Number,
})`
  display: flex;
  flex-direction: row;
  margin: ${margins.xxs} 0;
  align-items: center;
  gap: 22px;
  transition: 0.2s;
  height: ${(props) =>
    props.isExpanded ? `${props.contentHeight}px` : "25px"};
`

const StyledChevronWrapper = styled("div", { isExpanded: Boolean })`
  margin: 0 ${margins.xs};
  rotate: ${(props) => (props.isExpanded === true ? "180deg" : "0deg")};
  transition: 0.2s;
`

const StyledWrapper = styled("div", { isExpanded: Boolean })`
  transition: 0.2s;
  color: ${colors.botChatPrimary};
  opacity: ${(props) => (props.isExpanded ? "100" : "0")};
  width: 100%;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  background-color: #ffffff;
  padding: ${paddings.m} 0;
`
const StyledFlowHeadingWrapper = styled("div", { isNextStage: Boolean })`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 700;
  width: 100%;
  text-align: left;
  font-size: 14px;
  gap: 15px;
  color: ${(props) =>
    props.isNextStage ? greyscaleColorPalette.greyscale4 : "#114f62"};
`

const StyledFlowNameWrapper = styled("div", { isNextStage: Boolean })`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: left;
  font-size: 12px;
  gap: 15px;
  color: ${(props) =>
    props.isNextStage ? greyscaleColorPalette.greyscale4 : "#114f62"};
`

const StyledFlowStatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 15px;
  justify-content: center;
`
const StyledFlowStatus = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
`

const StyledFlowDivider = styled.div`
  border: 1px dashed #bdbdbd;
  transition: 0.2s;
  min-height: 23px;
  height: 100%;
  margin-left: 10px;
`

const StyledFlowStagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const FlowNumber = styled("div", { isNextStage: Boolean })`
  display: inline-block;
  width: 22px;
  height: 22px;
  border-radius: 90%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  line-height: 30px;
  font-size: 12px;
  border: 2px solid
    ${(props) =>
      props.isNextStage ? greyscaleColorPalette.greyscale4 : "#114f62"};
  color: ${(props) =>
    props.isNextStage ? greyscaleColorPalette.greyscale4 : "#114f62"};
`

export default {
  components: {
    StyledWrapper,
    StyledFlowStatus,
    StyledFlowDivider,
    StyledFlowStatusWrapper,
    StyledIconWrapper,
    StyledFlowNameWrapper,
    StyledChevronWrapper,
    StyledFlowContent,
    StyledFlowStagesWrapper,
    FlowNumber,
    StyledFlowHeadingWrapper,
    Pill,
    StyledIcon,
  },
  computed: {
    flowData() {
      return Object.keys(this.progress.flows).map((key) => ({
        ...this.progress.flows[key],
        name: mapFlowIdToDisplayName(key),
      }))
    },
    activeFlow() {
      const data = this.getFlowData()

      return !checkHasComplete(this.progress)
        ? data.find((flow) => flow.completed === false)
        : data[data.length - 1]
    },
    activeFlowStage() {
      const activeFlow = this.activeFlow

      if (!activeFlow) {
        return
      }

      return this.activeFlow.actions.find(
        (action) => action.completed === false
      )
    },
    hasCompletedApplication() {
      return checkHasComplete(this.progress)
    },
  },
  mounted() {
    if (!checkHasComplete(this.progress)) {
      this.expandFlowDetails(this.activeFlow)
    }
  },
  methods: {
    mapFlowStageToDisplayName: mapFlowStageToDisplayName,
    getFlowData() {
      return this.flowData
    },
    expandFlowDetails(flow) {
      if (!flow) {
        return
      }
      if (flow.id === this.expandedFlow) {
        this.expandedFlow = ""
        this.expandedFlowStages = []
        return
      } else {
        this.expandedFlow = flow.id
        this.expandedFlowStages = flow.actions
      }
    },
  },
  data() {
    return {
      expandedFlow: undefined,
      expandedFlowStages: [],
    }
  },
  props: {
    progress: {
      type: Object,
      default: () => ({}),
    },
    isExpanded: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    isExpanded: {
      handler() {
        if (!checkHasComplete(this.progress)) {
          this.expandedFlow = undefined
          this.expandedFlowStages = []
          this.expandFlowDetails(
            this.flowData.find((flow) => flow.completed === false)
          )
        }
      },
      deep: true,
    },
  },
}
</script>

<style></style>
