
import { margins } from "@/common/spacing"
import styled from "vue-styled-components"
import { formatIncomeValue } from '@/common/utils'

const StyledSummarySection = styled("div", { isMobile: Boolean })`
  display: flex;
  align-items: ${(props) => (props.isMobile ? "flex-start" : "")};
  flex-direction: column;
  line-height: 16px;
  margin-top: ${() => margins.l};
`

export default {
  components: {
    StyledSummarySection,
  },
  props: {
    income: {
      type: Object,
      required: true
    },
  },
  methods: {
    formatIncomeValue,
  },
  computed: {
    isMobile() {
      return window.innerWidth < 800
    },
  },
}
