<template>
  <styled-wrapper>
    <font-awesome-icon
      :icon="`fa-solid ${currentFlowIcon}`"
      size="xl"
      color="#FFFFFF"
    />
    <current-flow-details>
      <flow-title>
        {{ currentFlowName }}
      </flow-title>
      <flow-stage>
        {{ currentFlowStage }}
      </flow-stage>
    </current-flow-details>
  </styled-wrapper>
</template>

<script>
import StandardText from "@/components/design-system/StandardText.vue"
import styled from "vue-styled-components"
import { margins } from "@/common/spacing"
import Pill from "@/components/design-system/Pill.vue"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${margins.s};
`

const CurrentFlowDetails = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 18px;
  justify-content: center;
  min-width: 200px;
`

const FlowTitle = styled.strong`
  font-weight: 700;
  font-size: 14px;
`

const FlowStage = styled.span`
  font-weight: 400;
  font-size: 10px;
`

export default {
  components: {
    StyledWrapper,
    CurrentFlowDetails,
    FlowTitle,
    FlowStage,
  },
  props: {
    currentFlowName: String,
    currentFlowStage: String,
    currentFlowIcon: String,
  },
}
</script>

<style></style>
