import Url from "url-parse"
import { colors } from "@/common/colors"
import { flexRow } from "@/common/flex"
import { fontSizes } from "@/common/fonts"
import { paddings } from "@/common/spacing"
import { shadows } from "@/common/shadows"

export const resetStyles = () => `
margin: 0;
padding: 0;
border: 0;
font-size: 100%;
font: inherit;
vertical-align: baseline;
`

export const speeds = {
  medium: "300ms",
  fast: "200ms",
}

export const lineHeight = 1.4

export const baseAlertStyle = () => `
  width: 100%;
  font-size: ${fontSizes.s};
  padding: 12px ${paddings.s};
  border: 1px solid;
  border-radius: 4px;
`;


export const baseTextStyle = () => `
  transition: color ${speeds.medium} ease;
  line-height: ${lineHeight};
`

export const baseButtonStyle = (isFullWidth?: boolean) => `
  ${resetStyles()};
  ${flexRow({ alignItems: "center", justifyContent: "center" })};
  position: relative;
  cursor: pointer;
  font-size: ${fontSizes.m};
  white-space: nowrap;
  padding: ${paddings.xs} ${paddings.l};
  border-radius: 4px;
  line-height: ${lineHeight};

  ${isFullWidth && "width: 100%;"}

  transition: box-shadow ${speeds.medium};

  outline: 0;
  &:focus:not(:focus-visible) {
    outline: 0;
  }

  &:hover,
  &:focus {
    box-shadow: ${shadows.actionHover};
  }

  &:active {
    box-shadow: ${shadows.actionActive};

    &.loading {
      box-shadow: ${shadows.actionHover};
    }
  }

  &[disabled] {
    cursor: unset;
    box-shadow: unset;
    cursor: not-allowed;

    opacity: 0.4;
  }
`

export const ellipsisStyle = () => `
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

export const baseLinkStyle = () => `
  text-decoration: none;
  cursor: pointer;

  &:focus {
    text-decoration: underline;
  }
`

export const cardLikeStyle = () => ` 
  border-radius: 10px;
  background-color: ${colors.white};
  box-shadow: 0px 1px 3px rgba(0,0,0,0.15);
`

export const isUrlSafe = (checkUrl?: string) => {
  if (!checkUrl) {
    return false
  } else {
    const url = new Url(checkUrl, {})
    const hashExists = checkUrl.indexOf("#") !== -1
    const relativeUrl =
      checkUrl.indexOf("/") !== -1 && checkUrl.indexOf("/", 1) === -1
    return (
      relativeUrl || ["http:", "https:"].includes(url.protocol) || hashExists
    )
  }
}
