<template>
  <div>
    <styled-wrapper v-if="screenSize > 800">
      <template v-for="(step, index) in steps">
        <ProgressBarStep
          :key="step.name"
          :name="step.name"
          :stepState="step.stepState"
          :onClick="step.onClick"
        />
        <font-awesome-icon
          :key="step.name + 'icon'"
          v-if="index + 1 < steps.length"
          icon="fa-solid fa-chevron-right"
          :color="chevronColor"
        />
      </template>
    </styled-wrapper>
    <styled-mobile-wrapper v-else>
      <template>
        <ProgressBarStep
          :key="activeStep"
          :name="activeStep"
          stepState="inProgress"
        />
        <SupportingText id="mobile-step-summary">
          step {{ steps.findIndex((step) => step.name === activeStep) + 1 }} of
          {{ steps.length }}
        </SupportingText>
      </template>
    </styled-mobile-wrapper>
  </div>
</template>

<script>
import styled from "vue-styled-components"
import B from "./B"
import SupportingText from "./SupportingText"
import InternalLink from "./InternalLink"
import ProgressBarStep from "./ProgressBarStep"
import { flexColumn, flexRow } from "@/common/flex"
import { colors } from "@/common/colors"
import { paddings } from "@/common/spacing"

const props = {
  activeStep: String,
  steps: Array,
  isMobile: Boolean,
}

const StyledWrapper = styled("div", props)`
  ${flexRow({
    justifyContent: "space-between",
    alignItems: "center",
  })}
  padding: 0 ${paddings.l};
`

const StyledMobileWrapper = styled("div", props)`
  ${flexRow({
    justifyContent: "space-between",
    alignItems: "flex-end",
  })}
`

export default {
  components: {
    StyledWrapper,
    ProgressBarStep,
    StyledMobileWrapper,
    SupportingText,
  },
  props,
  computed: {
    screenSize() {
      return window.innerWidth
    },
    chevronColor() {
      return colors.textSecondary
    },
  },
}
</script>
