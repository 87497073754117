<template>
  <styled-wrapper :color="color"><slot /></styled-wrapper>
</template>

<script>
import { paddings } from "@/common/spacing"
import { colors } from "@/common/colors"
import styled from "vue-styled-components"
import { fontSizes } from "@/common/fonts"

const {
  greenLight,
  greenDark,
  orangeLight,
  orangeDark,
  blueLight,
  blueDark,
  redLight,
  redDark,
  greyLight,
  greyDark,
} = colors

export const pillTextColors = {
  green: greenDark,
  orange: orangeDark,
  blue: blueDark,
  red: redDark,
  grey: greyDark,
}

export const pillBackgroundColors = {
  green: greenLight,
  orange: orangeLight,
  blue: blueLight,
  red: redLight,
  grey: greyLight,
}

const props = { color: String, isFullWidth: Boolean }

const StyledWrapper = styled("div", props)`
  color: ${(props) => pillTextColors[props.color]};
  background-color: ${(props) => pillBackgroundColors[props.color]};
  width: ${(props) => (props.isFullWidth ? "100%" : "auto")};
  padding: 0 ${paddings.s}
  border: 1px solid ${(props) => pillBackgroundColors[props.color]};
  border-radius: 30px;
  height: auto;
  text-align: center;
  font-size: ${fontSizes.xs};
  vertical-align: middle;
  display: ${(props) => (props.isFullWidth ? "block" : "inline-block")};
  font-weight: normal;
`

export default {
  components: {
    StyledWrapper,
  },
  props,
}
</script>
