
import { mapActions, mapGetters } from "vuex";
import TextBoxElement from "@/components/generalUI/TextBoxElement.vue";

import { Vue, Component } from "vue-property-decorator";
import { PropType } from 'Vue'
import { GenerateTokenPayload } from '../../store/modules/user/actions'

const GenerateTokenInterface = Vue.extend({
  props: {
    generateUserToken: Function as PropType<(args: GenerateTokenPayload) => void>,
  },
});

@Component({
  components: { TextBoxElement },
  computed: {
    ...mapGetters({
      tempToken: "user/tempToken",
      tempRedirect: "user/tempRedirect",
      tokenGenerationStatus: "user/tokenGenerationStatus",
    }),
  },
  mounted() {
    document.ontouchmove = function (e) {
      e.preventDefault();
    };
  },
  methods: {
    ...mapActions({
      generateUserToken: "user/generateToken",
    }),
  },
})

export default class GenerateToken extends GenerateTokenInterface {
  public email: string = "";
  public baseUrl: string = process.env.VUE_APP_BASE_URL || "";

  public generateToken() {
    this.generateUserToken({
      email: this.email,
      redirect: this.$route.query.redirect,
    });
  }

  public handleiOSFocus() {
    // @ts-ignore
    this.$refs.emailAddress.onfocus = () => {
      window.scrollTo(0, 0);
      document.body.scrollTop = 0;
    };
  }
}
