<template>
  <div>
    <h1>Guarantor Confirm</h1>
    <p>Select the tenants you would like to be the guarantor for.</p>
    <div class="tenant-guarantor-selector">
      <div
        @click="handleSelect(index)"
        class="guarantor-select-item"
        v-bind:key="index"
        v-for="(tenant, index) in tenants"
      >
        <h5>{{ tenant.name }}</h5>
        <h5>{{ tenant.email }}</h5>
        <input :value="tenant.id" type="checkbox" :checked="tenant.selected" />
      </div>
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import { required } from "vuelidate/lib/validators";

export default {
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      tenants: this.model.tenants || [],
    };
  },
  computed: {
    selectedTenants() {
      return this.tenants.filter((tenant) => tenant.selected).length;
    },
  },
  validations: {},
  methods: {
    handleSelect(index) {
      this.tenants[index].selected = !this.tenants[index].selected;
    },
    validateInputs() {
      this.emitTouched();
    },
  },
};
</script>
<style lang="less" scoped>
.tenant-guarantor-selector {
  max-height: 50vh;
  overflow-y: auto;
}

.guarantor-select-item {
  background: #f2f2f2;
  border-radius: 10px;
  margin: 10px 0;
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  align-items: center;

  input {
    margin: 0 auto;
  }
}

.guarantor-select-item:hover {
  cursor: pointer;
  background: #f3f3f3;
}
</style>
