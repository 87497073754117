<template>
  <div>
    <h4>Right To Rent Assesment</h4>
    <p>
      A Home Office assessment is required for your application, please enter
      your share code below.
    </p>
    <p>
      You can obtain a share code by visiting
      <a href="www.gov.uk/prove-right-to-rent" target="_blank"
        >www.gov.uk/prove-right-to-rent</a
      >
      and following the on screen instructions.
    </p>
    <div class="share-code-container">
      <Sharecode @onChange="onChange" />
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import Sharecode from "../../generalUI/Sharecode.vue";

export default {
  components: {
    Sharecode,
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  mixins: [formMixin],
  data() {
    return {
      rightToRentCode: "",
    };
  },
  validations() {
    return {
      rightToRentCode: {
        isValidRightToRentShareCode: (value, obj) => {
          const rawCode = value.split(" ").join("");

          const COMMON_FAKE_CODES = ["123456789", "A1234567G"];

          if (
            rawCode.match(this.model.regexPattern) ||
            COMMON_FAKE_CODES.includes(rawCode) ||
            rawCode.length !== 9
          )
            return false;
          return true;
        },
      },
    };
  },
  methods: {
    onChange(code) {
      this.rightToRentCode = code;
    },
  },
  watch: {
    rightToRentCode: {
      handler() {
        this.touch(this.$v.rightToRentCode);
      },
    },
  },
};
</script>

<style lang="less" scoped>
.share-code-container {
  text-align: center;
}
</style>
