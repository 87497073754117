
import { mapGetters, mapActions } from "vuex";
import PulseLoader from "vue-spinner/src/PulseLoader.vue";
import { Vue, Component, Watch } from "vue-property-decorator";

@Component({
  components: { PulseLoader },
  computed: {
    ...mapGetters({
      isAuthenticated: "user/isAuthenticated",
      tokenVerificationStatus: "user/tokenVerificationStatus",
    }),
  },
  methods: {
    ...mapActions({
      verifyToken: "user/verifyToken",
    }),
  },
})
export default class VerifyTokenVue extends Vue {
  verifyToken!: (route: string, cId: string) => void;
  public isAuthenticated!: any;

  @Watch("isAuthenticated")
  redirectTrigger(data: any) {
    this.tryRedirect();
  }

  public tryRedirect() {
    let redirect: any = this.$route.query.redirect;
    if (!redirect) redirect = "/";
    if (this.$route.query.src) {
      redirect = `${redirect}?src=${this.$route.query.src}`;
    }
    if ((this as any).isAuthenticated) {
      this.$router.replace(redirect);
    }
  }

  async mounted() {
    if (this.isAuthenticated) {
      this.tryRedirect();
    }
    if (this.$route.query.cId) {
      (this as any).verifyToken(
        {
          token: `${this.$route.query.token}`,
          cId: `${this.$route.query.cId}`,
        },
        null
      );
    } else {
      (this as any).verifyToken(
        {
          token: `${this.$route.query.token}`,
        },
        null
      );
    }
  }
}
