<template>
  <ModalThemeing :isMobile="isMobile">
    <div class="error-div">
      <span v-if="errorText !== undefined">{{ errorText }}</span>
    </div>
    <StyledSecondaryAction
      type="button"
      id="btn-change"
      :onClick="cancel"
      :disabled="isLoading"
      :isMobile="isMobile"
    >
      I need to make a change
    </StyledSecondaryAction>

    <StyledPrimaryAction
      id="btn-primary"
      :onClick="handleSubmit"
      type="button"
      :disabled="v.error || v.invalid"
      :loading="isLoading"
      :isMobile="isMobile"
    >
      Confirm and proceed
    </StyledPrimaryAction>
  </ModalThemeing>
</template>

<script>
import { track } from "@/common/utils"
import { mapActions } from "vuex"
import PrimaryAction from "@/components/design-system/PrimaryAction.vue"
import styled from "vue-styled-components"
import SecondaryAction from "@/components/design-system/SecondaryAction.vue"
import { margins, paddings } from "@/common/spacing"

const StyledPrimaryAction = styled(PrimaryAction)`
  min-width: 125px;
`
const props = {
  isMobile: Boolean,
}

const StyledSecondaryAction = styled(SecondaryAction, props)`
  min-width: 125px;
  margin-right: ${(props) => (props.isMobile ? "" : margins.s)};
`

const ModalThemeing = styled("div", props)`
  display: flex;
  flex-direction: ${(props) => (props.mobile ? "column" : "row")};
  justify-content: ${(props) =>
    props.errorText !== undefined ? "space-between;" : "flex-end"}
  align-items: center;

gap: ${(props) => (props.isMobile ? margins.s : "")};
padding: 0 ${(props) => (props.isMobile ? paddings.s : "")};
  @media only screen and (max-width: 800px) {
    align-items: center;
    justify-content: center;

    flex-direction: column;

    .btn {
       width: 100%;
     }
  }

  #btn-cancel{
    display: ${(props) =>
      props.theme.config &&
      props.theme.config.dashboardSettings.disableBotFlowCancellability ===
        true &&
      "none"} !important;
  }

  div {
    text-align:right;
  }

  .error-div {
    font-family: @font-family-lato;
    text-align: left;
    letter-spacing: 0.15px;
    color: #ed1c24;
    align-items: center;
  }
`

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
    errorText: {
      type: String,
    },
    isLoading: Boolean,
  },
  components: {
    ModalThemeing,
    StyledPrimaryAction,
    StyledSecondaryAction,
  },
  methods: {
    ...mapActions({
      sendEvent: "webchat/sendEvent",
    }),
    handleSubmit() {
      const modalName = this.model.title ? this.model.title : this.model.modal
      track(`Click - ${modalName} - Modal submitted`, {})
      this.submit()
    },
    cancel() {
      const modalName = this.model.title ? this.model.title : this.model.modal
      track(`Click - ${modalName} - Modal cancelled`, {})
      this.$emit("display-summary-modal")
    },
  },
  computed: {
    isMobile() {
      return window.innerWidth < 800
    },
  },
  mounted() {
    const modalName = this.model.title ? this.model.title : this.model.modal
    track(`Click - ${modalName} - Modal displayed`, {})
  },
}
</script>
