var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"subject-details-container"},[_c('ModalThemeing',[_c('button',{staticClass:"btn btn-outline",class:{
        hide: _vm.stepped.current === 1,
      },attrs:{"name":"back","type":"button"},on:{"click":_vm.clicked}},[_vm._v(" Back ")]),_c('button',{staticClass:"btn",class:{
        'btn-disabled': (_vm.v.error && _vm.stepped.stages === _vm.stepped.current) ||
          (_vm.v.invalid && _vm.stepped.stages === _vm.stepped.current) ||
          _vm.disableSteppedProgression,
        'btn-primary':
          (!_vm.v.error && _vm.stepped.current === _vm.stepped.stages) ||
          (!_vm.v.invalid && _vm.stepped.current === _vm.stepped.stages) || !_vm.disableSteppedProgression,
      },attrs:{"name":"nextSubmit","type":"button","disabled":(_vm.v.error && _vm.stepped.stages === _vm.stepped.current) ||
          (_vm.v.invalid && _vm.stepped.stages === _vm.stepped.current) ||
          _vm.disableSteppedProgression},on:{"click":_vm.clicked}},[_vm._v(" "+_vm._s(_vm.stepped.current === _vm.stepped.stages ? "Done" : "Next Document")+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }