
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import ControlElement from "./ControlElement.vue";

@Component({ components: { ControlElement } })
export default class DateInput extends Vue {
  public value: { day: string; month: string; year: string } = {
    day: "",
    month: "",
    year: "",
  };

  public errors: String[] = [];

  public updateDobObj({
    target: { value, id },
  }: {
    target: { value: String; id: String };
  }) {
    // @ts-ignore
    let currVal = this.value[`${id}`];
    if (value === null) {
      // @ts-ignore
      this.value[`${id}`] = currVal
        .split("")
        .pop()
        .join();
    } else {
      switch (id) {
        case "day":
          if (+value < 1 || +value > 31) {
            this.errors.push("Invalid Day");
            return;
          }
          // @ts-ignore
          this.value[`${id}`] = value;
          if (value.split("").length >= 2) {
            // @ts-ignore
            this.$refs.month.focus();
          }
          break;
        case "month":
          if (+value < 1 || +value > 12) {
            this.errors.push("Invalid Month");
            return;
          }
          // @ts-ignore
          this.value[`${id}`] = value;
          if (value.split("").length >= 2) {
            // @ts-ignore
            this.$refs.year.focus();
          }
          break;
        case "year":
          // @ts-ignore
          this.value[`${id}`] = value;
          break;
      }
      // @ts-ignore
      this.value[`${id}`] = value;
    }
  }

  @Watch("value", { deep: true })
  dateChanged() {
    if (this.isValidNumber(this.value.day) &&
      this.isValidNumber(this.value.month) &&
      this.isValidNumber(this.value.year)) {

      const date = new Date(
        Date.UTC(
          parseInt(this.value.year),
          parseInt(this.value.month) - 1,
          parseInt(this.value.day),
          0,
          0,
          0,
          0
        )
      ).toISOString();
      this.$emit("date-formatted", date);
    }
    else {
      this.$emit("date-invalid", this.value);
    }
  }

  isValidNumber(input:string) : boolean {
    return input.trim() !== "" && +input > 0 
  }

  mounted() {
    if (this.currDOB.trim() !== "") {
      let formattedMonth = "";
      let date = new Date(this.currDOB);
      let year = date.getFullYear();
      let month = date.getMonth() + 1;
      let dt = "" + date.getDate();

      if (date.getDate() < 10) {
        dt = "0" + dt;
      }
      if (date.getMonth() + 1 < 10) {
        formattedMonth = `0${month}`;
      } else {
        formattedMonth = `${month}`;
      }
      this.value = { day: dt, month: formattedMonth, year: "" + year };
    }
  }

  @Prop({ type: String, default: "" })
  public id!: string;

  @Prop({ type: String, default: "" })
  public label!: string;

  @Prop({ type: Object, default: undefined })
  public validateRules!: any;

  @Prop({ type: Boolean })
  public required!: boolean;

  @Prop({ type: Boolean })
  public email!: boolean;

  @Prop({ type: Boolean })
  public phone!: boolean;

  @Prop({ type: Boolean })
  public url!: boolean;

  @Prop({ type: Boolean })
  public numeric!: boolean;

  @Prop({ type: String })
  public regex!: string;

  @Prop({ type: Boolean })
  public multiline!: boolean;

  @Prop({ type: Boolean })
  public search!: boolean;

  @Prop({ type: Function })
  public searchCallback!: Array<any>;

  @Prop({ type: Array })
  public addressesFound?: Array<any>;

  @Prop({ type: String })
  public errorMessage!: string;

  @Prop({ type: Boolean })
  public centre!: boolean;

  @Prop({ type: Function })
  public triggerModal!: void;

  @Prop({ type: Boolean, default: false })
  public focusItem!: boolean;

  @Prop({ type: Function })
  public findAddress!: void;

  @Prop({ type: Boolean, default: false })
  public autocomplete!: boolean;

  @Prop({ type: Boolean })
  public addressFilled!: boolean;

  @Prop({ type: String })
  public currDOB!: string;
}
