export const fontSizes = {
  xs: "12px",
  s: "14px",
  m: "16px",
  l: "21px",
  xl: "24px",
}

export type FontSize = keyof typeof fontSizes

export const fontWeights = {
  bold: "700",
  normal: "400",
}

export type FontWeight = keyof typeof fontWeights
