<template>
  <div class="container">
    <div v-if="model.isCancellable === false" id="terms-and-conditions">
      <h1>Declaration</h1>
      <p>By clicking accept you agree to the declaration below.</p>
    </div>
    <h1 id="terms-and-conditions" v-else>
      <span class="text-highlight">Vouch</span> Terms and Conditions
    </h1>
    <iframe
      class="terms"
      :src="model.url"
      frameborder="0"
      v-if="model.url"
    ></iframe>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style lang="less">
#terms-and-conditions {
  color: rgb(15, 69, 97);
  margin: 0.75rem 0;
  padding: 0 0 0 0.75rem;
}

.container {
  min-height: 100%;
  overflow-y: hidden !important;
  overflow: hidden;
  margin-bottom: 300px;
}

p {
  text-align: left;
}
.text-highlight {
  color: rgb(52, 190, 189);
}

.terms {
  min-height: 40vh;
  width: 100%;
  height:63vh;
}
</style>
