<template>
  <div class="income-proof-container">
    <div
      class="drop-zone"
      @click="handleProofClick($event, index)"
      v-bind:key="index"
      v-for="(file, index) in proofs"
    >
      <input
        :ref="`proof-${index}`"
        type="file"
        :name="`proof-${index}`"
        :id="`proof-${index}`"
        class="input text-input w-input"
        accept="image/x-png,image/png,image/jpeg,application/pdf"
        @change="setProof($event, index)"
      />

      <div class="drop-info">
        <p>
          Click to upload a file
        </p>
        <div class="uploadedImagesContainer">
          <div v-if="proofs[index].name">
            <img :src="getImgPreview(index)" class="imgItem" />
            <Loader
              v-if="proofs[index].name"
              :filename="proofs[index].name"
              disablePercentage
            >
            </Loader>
          </div>
        </div>
        <SimpleSVG
          v-if="!proofs[index].name"
          height="40"
          custom-class-name="icon"
          :src="`${botIconsUrl}/click.svg`"
        />
      </div>
    </div>
    <button class="btn btn-primary" @click="addProof">Add a Proof</button>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
const { SimpleSVG } = require("vue-simple-svg");
import Loader from "../../progress/Loader";

export default {
  components: {
    SimpleSVG,
    Loader,
  },
  mixins: [formMixin],
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      proofs: [{}],
      proofsRequired: this.model.proofsRequired,
      screenWidth: 801,
      botIconsUrl: "",
      uploadedImages: [],
    };
  },
  validations: {
    proofs: {
      isSelected: (v, vm) => {
        return [...vm.proofs].length >= vm.proofsRequired;
      },
    },
  },
  methods: {
    setProof(e, index) {
      const [uploadedProof] = e.target.files;

      const proofCopy = [...this.proofs];
      proofCopy[index] = uploadedProof;
      this.proofs = [...proofCopy];
      this.emitTouched();
    },
    getImgPreview(index) {
      const img = URL.createObjectURL(this.proofs[index]);
      return `${img}`;
    },

    handleProofClick(e, index) {
      const foundRef = this.$refs[`proof-${index}`];
      if (foundRef) {
        foundRef[0].click();
      }
    },
    addProof() {
      const proofCopy = [...this.proofs];
      proofCopy.push({});
      this.proofs = [...proofCopy];
    },
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    this.botIconsUrl = process.env.VUE_APP_ASSETS || "";
  },
};
</script>

<style lang="less" scoped>
.drop-zone {
  background-color: #f2f2f2;
  border-radius: 10px;
  padding: 0;
  margin: 20px auto;
  min-height: 200px;
  height: 200px;
  height: fit-content;
  width: 100%;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 800px) {
    margin: 10px auto;
    width: 80%;
  }
  & input {
    opacity: 0; /* invisible */
    display: none;
    width: 1px;
    height: 1px;
    cursor: pointer;
  }

  .imgItem {
    max-height: 200px;
    border-radius: 10px;
    border: 1px dashed var(--mainHighlight);
    animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275)
      both;
  }

  p {
    margin: 10px 0;
  }

  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
      transform: rotateX(-100deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
      transform: rotateX(0deg);
      -webkit-transform-origin: top;
      transform-origin: top;
      opacity: 1;
    }
  }

  & .drop-info {
    pointer-events: none;
    padding: 10px;

    & p {
      text-align: center;
      letter-spacing: 0.15px;
      color: #55616b;
    }

    & img {
      max-width: 100%;
      min-height: 40px;
    }
  }
}

.retake-container {
  text-align: center;
}

.selfieTips {
  ul {
    list-style: none;
    padding: 10px 0;
    text-align: left;
    li {
      margin: 10px 0;
      list-style-position: inside;
    }
  }

  ul li::before {
    content: "\2022";
    color: var(--mainHighlight);
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: 1em;
  }
}

.uploadedImagesContainer {
  display: flex;
  flex-flow: row wrap;
  grid-gap: 40px;
  justify-content: left;
  img {
    width: 200px;
    height: 200px;
    object-fit: cover;
  }
}
</style>
