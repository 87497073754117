var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.stepped.current === 1)?_c('div',[_c('H2',[_vm._v(" Document Selection ")]),_c('SupportingText',[_vm._v("Choose a document type to upload ")]),_c('styled-horizontal-divider'),_vm._l((_vm.fileGroups),function(group){return [_c('div',{key:group,on:{"click":function($event){return _vm.handleDocumentSelection(group)}}},[_c('StyledDocumentOption',[_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-file-text","size":"xl","color":"black"}}),_c('SupportingText',[_vm._v(_vm._s(group))])],1)],1)]})],2):_vm._e(),(_vm.stepped.current > 1)?_c('div',[_c('H2',[_vm._v(_vm._s(_vm.stepped.current !== _vm.stepped.stages ? 'Upload documents' : 'Confirm documents'))]),_c('styled-horizontal-divider'),_c('ChildCard',[_c('ProgressBar',{attrs:{"steps":_vm.progressBarSteps,"activeStep":_vm.activeFileGroup}})],1),_c('div',{staticClass:"title-wrapper"},[_c('h5',{staticClass:"uploader-title"},[_vm._v(" "+_vm._s(_vm.stepped.current !== _vm.stepped.stages ? _vm.activeFileGroup : 'Your uploaded documents')+" ")]),(_vm.requiredDocuments > 0 && _vm.stepped.current !== _vm.stepped.stages)?_c('Pill',{attrs:{"color":"blue"}},[_vm._v(_vm._s(_vm.requiredDocuments)+" "+_vm._s(_vm.requiredDocuments === 1 ? _vm.individualFileName : _vm.activeFileGroup)+" Required")]):_vm._e()],1),_c('file-description-wrapper',[(_vm.stepped.current !== _vm.stepped.stages)?_c('SupportingText',[_vm._v(" "+_vm._s(_vm.fileDescription)+" ")]):_vm._e()],1),_vm._l((_vm.files),function(file,index){return _c('div',{key:index,attrs:{"data-test-id":"dropzone"},on:{"click":function($event){_vm.$refs[`files-${index}`][0].click()}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          (_vm.stepped.current !== _vm.stepped.stages && index === _vm.currentFileIndex) ||
          (_vm.stepped.current !== _vm.stepped.stages && _vm.currentFileIndex === -1 && index === 0)
        ),expression:"\n          (stepped.current !== stepped.stages && index === currentFileIndex) ||\n          (stepped.current !== stepped.stages && currentFileIndex === -1 && index === 0)\n        "}],staticClass:"drop-zone",on:{"drop":e => _vm.ondrop(e, index)}},[_c('styled-drop-zone-indicator',[_c('div',{staticClass:"drop-info"},[(!file.url || _vm.currentFileIndex === -1)?_c('div',[_c('FileUploadSummary',{attrs:{"fileTitle":_vm.activeFileGroup}})],1):_vm._e(),_c('input',{ref:`files-${index}`,refInFor:true,staticClass:"input text-input w-input",attrs:{"type":"file","multiple":"","name":`files-${index}`,"id":`files-${index}`,"accept":file.accept || '*/*',"data-index":index},on:{"click":e => (e.target.value = ''),"change":_vm.setFile}})])])],1)])}),_c('styled-uploaded-file-summary-wrapper',[(_vm.uploadedFiles.length > 0 || _vm.stepped.current === _vm.stepped.stages)?_c('div',[_vm._l((_vm.files),function(file,index){return [(
              (file.group === _vm.activeFileGroup && file.file !== undefined) ||
              (_vm.stepped.current === _vm.stepped.stages && file.file !== undefined)
            )?_c('styled-uploaded-file-summary-card',{key:file.group + index},[_c('styled-file-details-wrapper',[_c('styled-file-icon-wrapper',[_c('font-awesome-icon',{attrs:{"size":"2xl","color":"#2F80ED","icon":"fa-solid fa-file-text"}}),_c('styled-tick-icon',[_c('font-awesome-icon',{attrs:{"color":"#6ABD40","icon":"fa-solid fa-circle-check"}})],1)],1),_c('styled-file-details',[_c('StandardText',[_vm._v(_vm._s(file.name)+" ")]),_c('Loader',{attrs:{"disablePercentage":""}})],1)],1),(_vm.stepped.current !== _vm.stepped.stages)?_c('styled-remove-icon-wrapper',{on:{"click":() => _vm.handleRemoveFile(index, _vm.$refs[`files-${index}`][0])}},[_c('font-awesome-icon',{attrs:{"size":"2xl","color":"#BDBDBD","icon":"fa-solid fa-xmark"}})],1):_vm._e()],1):_vm._e()]})],2):_c('styled-no-files-wrapper',[_c('img',{attrs:{"height":"40","src":`${_vm.botIconsUrl}/empty-file.png`}}),_c('SupportingText',[_vm._v("No documents have been uploaded yet")])],1)],1)],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }