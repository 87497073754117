import { computed, ComputedRef, ref } from 'vue'

interface UseDevice {
  isMobile: ComputedRef<boolean>
}

export default function (): UseDevice {
  const screenWidth = ref(window.innerWidth)
  const isMobile = computed(() => screenWidth.value < 800)

  return {
    isMobile,
  }
}
