<template>
  <div class="calender-input">
    <div>
      <h5 v-if="date.title">{{ date.title }}</h5>
      <p v-if="date.description">{{ date.description }}</p>
    </div>
    <DatePicker
      @input="selectDate"
      :name="`date-${index}`"
      placeholder="Select a date 📅"
      calendar-class="vouch-calendar"
      input-class="vouch-calendar-input"
      :disabled="disabled"
      :disabledDates="disabledDates"
    />
  </div>
</template>

<script>
import { track } from '@/common/utils';
import DatePicker from "vuejs-datepicker";

export default {
  components: {
    DatePicker,
  },
  props: {
    date: {
      type: Object,
      default: undefined,
    },
    index: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disabledDates: {
      type: Object,
      default: undefined,
    },
  },
  methods: {
    selectDate(d) {
      track("Click - User selected date", {})
      this.$emit("dateInputted", { date: d, index: this.index });
    },
  },
};
</script>

<style lang="less" scoped></style>
