<template>
  <ModalThemeing>
    <div class="side-by-side-flex">
      <p v-if="selectedTenants === 1">1 Tenant Selected</p>
      <p v-else>{{ selectedTenants }} tenants are selected</p>
      <button
        id="btn"
        class="btn btn-alternate"
        @click="clicked"
        style="color:white;"
        type="button"
        v-if="selectedTenants === 0"
      >
        No Tenants
      </button>
      <button
        v-else
        id="btn-primary"
        class="btn btn-primary"
        style="color:white;"
        @click="clicked"
        type="button"
        :class="{
          'btn-disabled': v.error || v.invalid,
          'btn-primary': !v.error && !v.invalid,
        }"
        :disabled="v.error || v.invalid"
      >
        {{ closeButtonText }}
      </button>
    </div>
  </ModalThemeing>
</template>

<script>
import { mapActions } from "vuex";

const styled = require("vue-styled-components");

const ModalThemeing = styled.default.div`
  #btn-cancel{
    display: ${(props) =>
      props.theme.config &&
      props.theme.config.dashboardSettings.disableBotFlowCancellability ===
        true &&
      "none"} !important;
  }

.btn-primary {
    background: ${(props) =>
      props && props.theme.default.mainColor
        ? props.theme.default.mainColor
        : "#34bebd"};
        border:none;  

  } 

  div {
    text-align:right;
  }
`;

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    close: {
      type: Function,
      required: true,
    },
    submit: {
      type: Function,
      required: true,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  components: {
    ModalThemeing,
  },
  data: function() {
    return {
      closeButtonText: "",
    };
  },
  computed: {
    selectedTenants() {
      return this.model.tenants.filter((tenant) => tenant.selected).length;
    },
  },
  methods: {
    ...mapActions({
      sendMessage: "webchat/sendMessage",
    }),
    clicked() {
      if (this.model.submitForm || this.model.submitForm === undefined)
        this.submit();
      else this.close();
    },
    cancel() {
      this.sendMessage({ message: "cancel" });
      this.close();
    },
  },
  mounted() {
    const isCloseButtonTextSet =
      this.model.closeButtonText !== "" &&
      this.model.closeButtonText !== undefined;

    this.closeButtonText = isCloseButtonTextSet
      ? this.model.closeButtonText
      : "Done";
  },
};
</script>
