<template>
  <div>
    <!-- Show if UK Address, select from dropdown-->
    <div v-if="model.addresses && model.addresses.length > 0">
      <ControlElement
        id="addressType"
        name="addressType"
        label="Select Address"
        controlType="select"
        :required="true"
        errorMessage="Please select an address."
        :focusItem="true"
        :alwaysActive="true"
      >
        <template slot="control" slot-scope="{ setFocus }">
          <styled-select-input
            id="addresses"
            name="type"
            @change="selectAddress($event)"
            v-on:focus="setFocus(true)"
            v-on:blur="setFocus(false)"
          >
            <option value="-1" selected>Please select address</option>
            <option value="ADDRESS_NOT_LISTED">My Address Isn't Listed</option>
            <option
              v-for="(address, index) in model.addresses"
              :key="index"
              :value="index"
            >
              {{ getCommaSeparatedAddress(address) }}
            </option>
          </styled-select-input>
        </template>
      </ControlElement>
      <div id="manual-input" v-if="addressIsNotOnList == true">
        <br />
        <br />
        <!-- <hr /> -->
        <p>Manual Input</p>
        <input type="hidden" name="id" :value="id" />

        <TextBoxElement
          :focusItem="true"
          @input="validateInputs"
          @change="validateInputs"
          v-model="bedroomIdentifier"
          name="bedroomIdentifier"
          maxLength="30"
          id="bedroomIdentifier"
          label="Room Number (optional)"
          errorMessage="Please enter your room number"
        ></TextBoxElement>

        <TextBoxElement
          :focusItem="true"
          @input="validateInputs"
          @change="validateInputs"
          v-model="nameOrNumber"
          name="nameOrNumber"
          maxLength="30"
          id="nameOrNumber"
          :label="nameLabel"
          required
          errorMessage="Please enter your house name or number"
        ></TextBoxElement>

        <TextBoxElement
          :focusItem="true"
          @input="validateInputs"
          @change="validateInputs"
          v-model="line1"
          maxLength="30"
          name="line1"
          id="line1"
          label="Line 1"
          required
          errorMessage="Please enter the first line of your address"
        ></TextBoxElement>

        <TextBoxElement
          :focusItem="true"
          @input="validateInputs"
          v-model="line2"
          maxLength="30"
          name="line2"
          id="line2"
          label="Line 2"
          errorMessage="Please enter the second line of your address"
        ></TextBoxElement>

        <TextBoxElement
          :focusItem="true"
          @input="validateInputs"
          v-model="county"
          name="county"
          id="county"
          maxLength="30"
          label="County"
          hidden
          errorMessage="Please enter your county."
        ></TextBoxElement>

        <TextBoxElement
          :focusItem="true"
          @input="validateInputs"
          v-model="city"
          maxLength="30"
          name="city"
          id="city"
          required
          label="City"
          errorMessage="Please enter your city."
        ></TextBoxElement>

        <TextBoxElement
          :focusItem="true"
          @input="validateInputs"
          v-model="country"
          name="country"
          id="country"
          required
          disabled
          label="Country"
          errorMessage="Please enter your country."
        ></TextBoxElement>

        <TextBoxElement
          :focusItem="true"
          @input="validateInputs"
          v-model="postcode"
          name="postcode"
          id="postcode"
          label="Postcode"
          required
          errorMessage="Please enter your postcode."
        ></TextBoxElement>
      </div>
      <TextBoxElement
        :focusItem="true"
        @input="validateInputs"
        @change="validateInputs"
        v-model="bedroomIdentifier"
        name="bedroomIdentifier"
        maxLength="30"
        id="bedroomIdentifier"
        label="Room Number (optional)"
        errorMessage="Please enter your room number"
        v-if="addressIsNotOnList === false"
      ></TextBoxElement>
      <div id="address-preview" v-if="!addressIsNotOnList && postcode">
        <h2>
          {{ nameOrNumber }}
        </h2>
        <h3>
          {{ line1 }}
        </h3>
        <h3>
          {{ line2 }}
        </h3>
        <h3>{{ city }}, {{ postcode }}</h3>
      </div>
    </div>
    <!-- Show if non uk address, manual input with country dropdown-->
    <div v-else>
      <TextBoxElement
        :focusItem="true"
        @input="validateInputs"
        @change="validateInputs"
        v-model="bedroomIdentifier"
        name="bedroomIdentifier"
        maxLength="30"
        id="bedroomIdentifier"
        label="Room Number (optional)"
        errorMessage="Please enter your room number"
      ></TextBoxElement>

      <TextBoxElement
        :focusItem="true"
        @input="validateInputs"
        v-model="nameOrNumber"
        maxLength="30"
        name="nameOrNumber"
        id="nameOrNumber"
        :label="nameLabel"
        required
        errorMessage="Please enter your house name or number"
      ></TextBoxElement>

      <TextBoxElement
        :focusItem="true"
        @input="validateInputs"
        v-model="line1"
        maxLength="30"
        name="line1"
        id="line1"
        label="Line 1"
        required
        errorMessage="Please enter the first line of your address"
      ></TextBoxElement>

      <TextBoxElement
        :focusItem="true"
        @input="validateInputs"
        maxLength="30"
        v-model="line2"
        name="line2"
        id="line2"
        label="Line 2"
        errorMessage="Please enter the second line of your address"
      ></TextBoxElement>

      <TextBoxElement
        :focusItem="true"
        @input="validateInputs"
        maxLength="30"
        v-model="city"
        name="city"
        id="city"
        required
        label="City"
        errorMessage="Please enter your city."
      ></TextBoxElement>

      <ControlElement
        id="country"
        name="country"
        label="Country"
        controlType="select"
        :required="true"
        errorMessage="Please select a country."
        :focusItem="true"
        :alwaysActive="true"
        v-if="countries"
      >
        <template slot="control" slot-scope="{ setFocus }">
          <styled-select-input
            v-model="country"
            class="country-picker"
            @change="validateInputs"
            v-on:focus="setFocus(true)"
            v-on:blur="setFocus(false)"
          >
            <option value="" disabled>Please select</option>
            <option
              :value="country.name"
              v-bind:key="country.id"
              v-for="country in countries"
              >{{ country.name }}</option
            >
          </styled-select-input>
        </template>
      </ControlElement>

      <TextBoxElement
        v-else
        :focusItem="true"
        @input="validateInputs"
        v-model="country"
        name="country"
        id="country"
        label="Country"
        required
        disabled
        errorMessage="Please enter your country."
      ></TextBoxElement>

      <TextBoxElement
        :focusItem="true"
        @input="validateInputs"
        @change="validateInputs"
        v-model="postcode"
        name="postcode"
        id="postcode"
        label="Postcode"
        required
        errorMessage="Please enter your postcode."
      ></TextBoxElement>
    </div>
  </div>
</template>

<script>
import { formMixin } from "@/mixins/formMixin";
import { required } from "vuelidate/lib/validators";
import TextBoxElement from "@/components/generalUI/TextBoxElement.vue";
import ControlElement from "@/components/generalUI/ControlElement.vue";
import { track } from "@/common/utils";
import styled from "vue-styled-components";

const StyledSelectInput = styled.select`
  width: 100%;
  height: 50px;
  border: none;
  outline: none;
`;

export default {
  mixins: [formMixin],
  components: {
    TextBoxElement,
    ControlElement,
    "styled-select-input": StyledSelectInput
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  computed: {
    nameLabel() {
      return this.model.nameLabel ? this.model.nameLabel : "House Name/Number";
    }
  },
  data() {
    return {
      addressIsNotOnList: false,
      id: this.model.id || "",
      bedroomIdentifier: this.model.bedroomIdentifier
        ? this.model.bedroomIdentifier
        : "",
      nameOrNumber:
        this.model.nameOrNumber !== ""
          ? this.model.nameOrNumber
          : this.model.name,
      name: this.model.name,
      countries: this.model.countries,
      line1: this.model.line1,
      line2: this.model.line2 || "",
      city: this.model.city,
      county: this.model.county,
      country:
        this.model.addresses.length > 0
          ? this.model.addresses[0].country
          : this.model.country,
      postcode: this.model.postcode
    };
  },
  validations: {
    nameOrNumber: { required, isValid: (v, vm) => v !== "" },
    line1: { required, isValid: (v, vm) => v !== "" },
    city: { required, isValid: (v, vm) => v !== "" },
    country: { required, isValid: (v, vm) => v !== "" },
    postcode: { required, isValid: (v, vm) => v !== "" }
  },

  methods: {
    countryUpdated(country) {
      track("Click - Country selected", {});
      this.country = country.name;
    },
    validateInputs() {
      this.emitTouched();
    },
    getCommaSeparatedAddress(address) {
      return [
        address.nameOrNumber || address.name,
        this.getAddressPart(address.line1, " "),
        this.getAddressPart(address.line2),
        this.getAddressPart(address.city),
        this.getAddressPart(address.postcode)
      ].join("");
    },
    getAddressPart(part, prefix = ", ") {
      return part !== undefined && part !== "" ? `${prefix}${part}` : "";
    },
    selectAddress(event) {
      track("Click - Select address", {
        isAddressListed: event.target.value !== "ADDRESS_NOT_LISTED"
      });
      if (event.target.value !== "ADDRESS_NOT_LISTED") {
        ({
          id: this.id,
          name: this.name,
          nameOrNumber: this.nameOrNumber,
          line1: this.line1,
          line2: this.line2,
          city: this.city,
          county: this.county,
          country: this.country,
          postcode: this.postcode
        } = this.model.addresses[event.target.value]);
        if (!this.nameOrNumber || this.nameOrNumber.trim() === "") {
          this.nameOrNumber = this.model.addresses[event.target.value].name;
        }
        this.addressIsNotOnList = false;
      } else {
        this.addressIsNotOnList = true;
      }
      this.emitTouched();
    }
  }
};
</script>
<style lang="less" scoped>
#addresses {
  background: white;
}

.address-select {
  padding: 1.25rem 0.25rem;
  border-radius: 5px;
  width: -webkit-fill-available;
  margin: 0 20px;
}
.address-select:before {
  padding: 0 0.5rem;
}

p {
  text-align: left;
  margin: 0 20px;
}

#address-preview {
  padding: 0px 20px;
}
</style>
