import { logErrorToDatadog } from "@/common/utils"

export interface UserObj {
  id: string
  applicationId: string
  customerId: string
}

const state = {
  botConnection: undefined,
  conversation: undefined,
  store: undefined,
  token: undefined,
  userId: undefined,
  userObj: undefined,
}

const mutations = {
  botConnection(state: any, payload: any) {
    state.botConnection = payload
  },
  conversation(state: any, payload: any) {
    state.conversation = payload
  },
  store(state: any, payload: any) {
    state.store = payload
  },
  token(state: any, payload: any) {
    state.token = payload
  },
  userId(state: any, payload: any) {
    state.userId = payload
  },
  userObj(state: any, payload: any) {
    state.userObj = payload
  },
}

export const actions = {
  registerWebchat({ commit }: { commit: any }, payload: any) {
    const dl = payload.directLine
    const user = payload.user
    const token = dl.token
    commit("conversation", dl.conversationId)
    commit("token", token)
    commit("userId", dl.user)
    commit("userObj", {
      id: user.id,
      applicationId: user.applicationId,
      customerId: user.customerId,
    })
    //register with Vouch security agent
    // @ts-ignore
    if (window.vsa) {
      // @ts-ignore
      window.vsa.registerAgent(JSON.stringify(user))
    }
    // @ts-ignore
    const directLine = window.WebChat.createDirectLine({ token: token })
    const botConnection = Object.assign({}, directLine, {
      postActivity: (activity: any) => {
        const channelData = {
          ...activity.channelData,
          jwt: payload.api.token,
          user,
        }
        const authenticatedActivity = Object.assign({}, activity, {
          channelData,
        })
        return directLine.postActivity(authenticatedActivity)
      },
    })

    commit("botConnection", botConnection)

    // @ts-ignore
    const store = window.WebChat.createStore()
    commit("store", store)
  },
  registerActivityCallback({ getters }: { getters: any }, cb: Function) {
    const botConnection = getters.botConnection
    // @ts-ignore
    botConnection.activity$.subscribe((activity) => {
      if (activity.text === "Oops. Something went wrong!") {
        logErrorToDatadog("Generic activity error", {})
      }
      cb(activity)
    })
  },
  sendEvent({ getters }: { getters: any }, payload: any) {
    getters.store.dispatch({
      type: "WEB_CHAT/SEND_EVENT",
      payload,
    })
  },
  sendMessage({ getters }: { getters: any }, payload: any) {
    getters.store.dispatch({
      type: "WEB_CHAT/SEND_MESSAGE",
      payload: { text: payload.message, channelData: { data: payload } },
    })
  },
  postActivity({ getters }: { getters: any }, activity: any) {
    getters.store.dispatch({
      type: "DIRECT_LINE/POST_ACTIVITY",
      meta: { method: "keyboard" },
      payload: { activity },
    })
  },
  unregisterWebchat({ commit }: { commit: any }) {
    commit("conversation", undefined)
    commit("token", undefined)
    commit("userId", undefined)
    commit("botConnection", undefined)
    commit("store", undefined)
  },
}

const getters = {
  botConnection(state: any) {
    return state.botConnection
  },
  conversation(state: any) {
    return state.conversation
  },
  store(state: any) {
    return state.store
  },
  token(state: any) {
    return state.token
  },
  userObj(state: any) {
    return state.userObj
  },
  userId(state: any) {
    return state.userId
  },
  isWebchatRegistered(state: any) {
    return (
      state.botConnection !== undefined &&
      state.conversation !== undefined &&
      state.store !== undefined &&
      state.token !== undefined &&
      state.userId !== undefined
    )
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
