<template>
  <styled-wrapper><slot /></styled-wrapper>
</template>

<script>
import styled from "vue-styled-components"
import { resetStyles } from "./utils"

const StyledWrapper = styled.strong`
  ${resetStyles()}
  font-weight: bold;
`

export default {
  components: {
    StyledWrapper,
  },
}
</script>
